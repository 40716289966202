import * as THREE from "three";

class Node extends THREE.Group{

    // isNode
    constructor(){
      super()
      // this.isNode = true
    
    }

  }


  export default Node