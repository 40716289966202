import * as THREE from 'three'
import { Euler, Group, Vector3 } from 'three';
import { Text } from "troika-three-text";
import { abs, xyz } from './common';

/**
 * 模型标尺,默认Y轴朝上
 * @export
 * @class MeshRuler
 */
export class MeshRuler extends Group {

    offsetDistance
    arrowLength
    lineMaterail
    arrowMaterail
    group
    textMesh
    bound
    isCabRuler

  /**
   * Creates an instance of MeshRuler.
   * @param {object} options
   * @param {number} options.offsetDistance 标尺线和模型的距离,默认值 0.01
   * @memberof MeshRuler
   */
  constructor(options) {
    super()
    options = options || {};
    this.offsetDistance = options.offsetDistance || 0.05
    this.arrowLength = 40;
    this.isCabRuler = false


    this.lineMaterail = new THREE.MeshBasicMaterial({
      color: 0x00,transparent:true,opacity:0.8
    });

    this.arrowMaterail = new THREE.MeshBasicMaterial({
      color: 0x00,transparent:true,opacity:0.8
    });

    this.group = new THREE.Group();
    this.textMesh = []
  }

  update(cameraPosition) {
    this.textMesh.forEach(item => {
      item.lookAt(cameraPosition);
    });
  }

  setBound(bound) {
    this.bound = bound;
  }

  addRuler0() {
    const { max, min } = this.bound;
    const basePoint = [min.x, min.y, max.z];
    let rot = new Vector3()
    this.addRulerOne(basePoint, [max.x, min.y, max.z], "X-rouler", max.x - min.x,rot);
    this.addRulerOne(basePoint, [min.x, max.y, max.z], "Y-rouler", max.y - min.y,rot);
    this.addRulerOne(basePoint, [min.x, min.y, min.z], "Z-rouler", max.z - min.z,rot);
  }

  /**
   * 
   2----1
   |    |
        3
   */
  addRuler(cab,bound) {
    let {min,max} = bound
    let wpos = max.clone().add(min).multiplyScalar(0.5) //cab.getWorldPosition(new Vector3())
    let offpos = wpos.clone().sub(cab.position)
    let rot = new Vector3() //cab.rotation
    let {x:sx,y:sy,z:sz} =  abs(max.clone().sub(min).round()) //cab.size
    let offPos1 = new Vector3(sx*0.5,sy*0.5,sz*0.5).add(offpos)
    let offPos2 = new Vector3(-sx*0.5,sy*0.5,sz*0.5).add(offpos)
    let offPos3 = new Vector3(sx*0.5,-sy*0.5,sz*0.5).add(offpos)
    let offPos4 = new Vector3(-sx*0.5,sy*0.5,-sz*0.5).add(offpos)
    let p1 = wpos.clone().add(offPos1.applyEuler(new Euler(...xyz(rot), 'ZYX')))
    let p2 = wpos.clone().add(offPos2.applyEuler(new Euler(...xyz(rot), 'ZYX')))
    let p3 = wpos.clone().add(offPos3.applyEuler(new Euler(...xyz(rot), 'ZYX')))
    let p4 = wpos.clone().add(offPos4.applyEuler(new Euler(...xyz(rot), 'ZYX')))
  
    this.addRulerOne(offPos2, offPos1, "X-rouler", sx,rot);
    // this.addRulerOne(p1, p3, "Y-rouler", sy,rot);
    this.addRulerOne(offPos4, offPos2, "Z-rouler", sz,rot);
    // this.setRotationFromEuler(new Euler(...xyz(rot), 'ZYX'))
  }

  async addRulerPlank(start, end, axisName, distance) {
    // const points = [
    //   new THREE.Vector3(...start),
    //   new THREE.Vector3(...end),
    // ]
    const points = [start,end]

    let translate;
    let rotation1;
    let rotation2;
    let lineRotation;

    switch (axisName) {
      case "X-rouler":
        translate = { x: 0, y: 0, z: this.offsetDistance };
        if(start.x - end.x>0){
          rotation1 = { x: 0, y: 0, z: -Math.PI / 2 }
          rotation2 = { x: 0, y: 0, z: Math.PI / 2 }
        }
        else{
          rotation1 = { x: 0, y: 0, z: Math.PI / 2 }
          rotation2 = { x: 0, y: 0, z: -Math.PI / 2 }
        }
        lineRotation = { x: 0, y: 0, z: -Math.PI / 2 }
        break;

      case "Y-rouler":
        translate = { x: -this.offsetDistance, y: 0, z: this.offsetDistance };
        if(start.y - end.y>0){
          rotation1 = { x: 0, y: 0, z: 0 }
          rotation2 = { x: -Math.PI, y: 0, z: 0 }
        }
        else{
          rotation1 = { x: -Math.PI, y: 0, z: 0 }
          rotation2 = { x: 0, y: 0, z: 0 }
        }
        lineRotation = { x: 0, y: 0, z: 0 }
        break;

      case "Z-rouler":
        translate = { x: -this.offsetDistance, y: 0, z: 0 };
        if(start.z - end.z<0){
          rotation1 = { x: -Math.PI / 2, y: 0, z: 0 }
          rotation2 = { x: Math.PI / 2, y: 0, z: 0 }
        }
        else{
          rotation1 = { x: Math.PI / 2, y: 0, z: 0 }
          rotation2 = { x: -Math.PI / 2, y: 0, z: 0 }
        }
       
        lineRotation = { x: Math.PI / 2, y: 0, z: 0 }
        break;
    }

    // 计算位置
    const translateMatrix = new THREE.Matrix4().makeTranslation(translate.x, translate.y, translate.z)
    points.forEach(item => {
      item.applyMatrix4(translateMatrix);
    })

    const startVector = points[0];
    const endVector = points[1];

    const xWidth = 100;
    const yWidth = 100;

    let textWidth;
    switch (axisName) {
      case "X-rouler":
      case "Z-rouler":
        textWidth = xWidth;
        break;
      case "Y-rouler":
        textWidth = yWidth;
        break;
    }

    // 添加线
    const { lineLeft, lineRight } = this.addLine(startVector, endVector, lineRotation, 1.2 * textWidth);
    this.add(lineLeft);
    this.add(lineRight);

    const arrow1 = this.addArrow();
    this.add(arrow1);
    arrow1.position.set(startVector.x, startVector.y, startVector.z);
    arrow1.rotation.set(rotation1.x, rotation1.y, rotation1.z);

    const arrow2 = this.addArrow();
    this.add(arrow2);
    arrow2.position.set(endVector.x, endVector.y, endVector.z);
    arrow2.rotation.set(rotation2.x, rotation2.y, rotation2.z);
  }

  async addRulerOne(start, end, axisName, distance,rot) {
    const points = [
      start,
      end,
    ]

    let translate;
    let rotation1;
    let rotation2;
    let lineRotation;

    switch (axisName) {
      case "X-rouler":
        translate = new Vector3(0,0,this.offsetDistance).applyEuler(new Euler(...xyz(rot), 'ZYX'))
        rotation1 = { x: rot.x, y: rot.y, z: Math.PI / 2+rot.z }
        rotation2 = { x: rot.x, y: rot.y, z: -Math.PI / 2 +rot.z}
        lineRotation = { x: rot.x, y: rot.y, z: -Math.PI / 2+rot.z }
        break;

      case "Y-rouler":
        translate =  new Vector3(this.offsetDistance,0,this.offsetDistance).applyEuler(new Euler(...xyz(rot), 'ZYX'))
        rotation1 = { x: rot.x, y: rot.y, z: rot.z }
        rotation2 = { x:-Math.PI+rot.x, y: rot.y, z: rot.z }
        lineRotation = {  x: rot.x, y: rot.y, z: rot.z }
        break;

      case "Z-rouler":
        translate = new Vector3(-this.offsetDistance,0,0).applyEuler(new Euler(...xyz(rot), 'ZYX'))
        rotation1 = { x: -Math.PI / 2, y: 0, z: 0 }
        rotation2 = { x: Math.PI / 2, y: 0, z: 0 }
        lineRotation = { x: Math.PI / 2, y: 0, z: 0 }
        break;
    }

    // 计算位置
    // const translateMatrix = new THREE.Matrix4().makeTranslation(translate.x, translate.y, translate.z)
    // points.forEach(item => {
    //   item.applyMatrix4(translateMatrix);
    // })

    const startVector = points[0].clone().add(translate);
    const endVector = points[1].clone().add(translate);

    // 添加文字
    const text = await this.addText(`${distance.toFixed(0)}`, startVector.clone().add(endVector).multiplyScalar(0.5));
    //@ts-ignore
    this.add(text);
    this.textMesh.push(text);
    //@ts-ignore
    const bound = text.geometry.boundingBox;
    const xWidth = bound.max.x - bound.min.x;
    const yWidth = bound.max.y - bound.min.y;

    let textWidth;
    switch (axisName) {
      case "X-rouler":
      case "Z-rouler":
        textWidth = xWidth;
        break;
      case "Y-rouler":
        textWidth = yWidth;
        break;
    }

    // 添加线
    const { lineLeft, lineRight } = this.addLine(startVector, endVector, lineRotation, 1.2 * textWidth);
    this.add(lineLeft);
    this.add(lineRight);

    const arrow1 = this.addArrow();
    this.add(arrow1);
    arrow1.position.set(startVector.x, startVector.y, startVector.z);
    arrow1.rotation.set(rotation1.x, rotation1.y, rotation1.z);

    const arrow2 = this.addArrow();
    this.add(arrow2);
    arrow2.position.set(endVector.x, endVector.y, endVector.z);
    arrow2.rotation.set(rotation2.x, rotation2.y, rotation2.z);

  }

  /**
   * 添加箭头
   * @return {*}
   */
  addArrow() {
    const coneGeom = new THREE.ConeGeometry(this.arrowLength / 4, this.arrowLength, 64);
    coneGeom.translate(0, -this.arrowLength / 2, 0);
    const cone = new THREE.Mesh(coneGeom, this.arrowMaterail);
    return cone;
  }

  addLine(start, end, lineRotation, textWidth) {
    // 线宽
    const radius = 5;
    // 线的方向
    const dir = start.clone().sub(end).normalize();
    // 线总长度 = 起终点距离 - 2*箭头长度 - 文字宽度
    const length = start.distanceTo(end) - 2 * this.arrowLength - textWidth;

    const geometry = new THREE.CylinderGeometry(radius, radius, length / 2, 32);

    // 左侧线
    const lineLeft = new THREE.Mesh(geometry, this.lineMaterail);
    lineLeft.rotation.set(lineRotation.x, lineRotation.y, lineRotation.z);
    const subStart1 = dir.clone().multiplyScalar(-this.arrowLength).add(start);
    const subEnd1 = dir.clone().multiplyScalar(-length / 2).add(subStart1);
    const positionLeft = subStart1.add(subEnd1).multiplyScalar(0.5)
    lineLeft.position.copy(positionLeft);
    lineLeft.renderOrder = 100;

    // 右侧线
    const lineRight = new THREE.Mesh(geometry, this.lineMaterail);
    lineRight.rotation.set(lineRotation.x, lineRotation.y, lineRotation.z);
    const subStart2 = dir.clone().multiplyScalar(-textWidth).add(subEnd1);
    const subEnd2 = dir.clone().multiplyScalar(-(length / 2)).add(subStart2);;
    const positionRight = subStart2.add(subEnd2).multiplyScalar(0.5)
    lineRight.position.copy(positionRight);
    lineRight.renderOrder = 100;

    return { lineLeft, lineRight };
  }

  addText(text, position) {
    return new Promise((resolve, reject) => {
      const myText = new Text();
      myText.text = text;
      myText.fontSize = 50;
      myText.position.x = position.x;
      myText.position.y = position.y;
      myText.position.z = position.z;
      myText.color = "white";
      myText.lineHeight = 1;
      myText.letterSpacing = 0.01;
      myText.textAlign = "left";
      myText.fillOpacity = 1;
      myText.outlineWidth = "4.5%";
      myText.outlineColor = "#000000";
      myText.outlineOpacity = 1;
      myText.strokeColor = "white";
      myText.strokeWidth = "4%";
      myText.rotation.set(Math.PI*0.5,0,0)
    //   myText.font = "./res/1Ptrg8zYS_SKggPNwK4vaqI.woff";
      myText.anchorX = "center";
      myText.anchorY = "middle";
      myText.maxWidth = 200;
      myText.sync(() => {
        myText.renderOrder = 20;
        resolve(myText)
      });
    })
  }
}
