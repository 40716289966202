// @ts-ignore
import { DXFLoader } from 'three-dxf-loader'
import { Viewer } from 'three-dxf'
import { OBB } from 'three/examples/jsm/math/OBB';
import Node from './zNode.js'
import CabNode from './zCabNode.js'
import PlankNode from './zPlankNode.js'
import { MyExtrudeGeometry } from './public/myExtrudegeometry'
import { getOnlyId } from './common.js'
import axios from "axios";
import { pointToVector2,getBox3Size } from './common.js'
import { importGoods } from '@/api'
import { TextureLoader,Vector2, Line3,RepeatWrapping, MeshLambertMaterial, Shape, Mesh, BoxHelper, Box3, Vector3, Group, BoxBufferGeometry,Box3Helper } from 'three'
import { zPlankType,zDir } from './zDefine.js'



// 上下左右最值
let T_B = {  min:0, max:0,exist:false,minId:0,maxId:0 } // 上下最值
let L_R = {  min:0, max:0,exist:false,minId:0,maxId:0 } // 左右最值


//
function sortBy(sizeArr,index){
   const sortArr = [...sizeArr]
   // 降序
   sortArr.sort(function(a,b){return b-a})
   return sizeArr[index]==sortArr[2]?sizeArr:sortArr
 

}


function toImportGoods(z3d) {
    const scene = z3d.scene
    const cabs = scene.children.filter(item => item instanceof CabNode)
    const goodsList = []
    cabs.map(cab => {
        cab.traverse(node => {
            if (node instanceof PlankNode) {
                const { size, cid, name,plankType,isDxf } = node
               
                
                let {0:sx,1:sy,2:sz} = [size.x,size.y,size.z]
                let width = 0
                let length = 0
                let thickness = 0
                if([zPlankType.VERTICAL_PLANK,zPlankType.SEALING,zPlankType.SIDE_L,zPlankType.SIDE_R].includes(plankType)){
                    // 立板类
                    ({0:sx,1:sy,2:sz} = sortBy([size.x,size.y,size.z],0))
                    width = sz
                    length = sy
                    thickness = sx
                }else if([zPlankType.LEVEL_PLANK,zPlankType.LEVEL_T,zPlankType.LEVEL_B].includes(plankType)){
                    //层板类
                    ({0:sx,1:sy,2:sz} = sortBy([size.x,size.y,size.z],2))
                    width = sx
                    length = sy
                    thickness = sz
                }else{
                    // 门板类
                    ({0:sx,1:sy,2:sz} = sortBy([size.x,size.y,size.z],1))
                    width = sx
                    length = sz
                    thickness = sy
                }

                goodsList.push({
                    sn: cid,
                    board_name: name,
                    width:  width,
                    length: length,
                    thickness:thickness,

                })
            }
        })
    })

    const data = {
        list: JSON.stringify(goodsList)
    }
    // axios.post('http://47.93.255.131:9501/api/v1/order.import_goods?app_id=287eab5a28&identity=33f01880fa858b6364b0da178e14044f',
    // {data}).then(response => {
    //     console.log('/a1', response.data)
    // }, error => {
    //     console.log('错误', error.message)
    // })
    importGoods(data).then(response => {
        console.log('12314243')
    })

}
// 加载DXF文件

function dxfloader(url) {
    return new Promise(resolve => {
        const loader = new DXFLoader();
        loader.load(url, (dxf) => {
            if (dxf) {
                resolve(dxf)

            }
        }, (xhr) => {

            // console.log(`${(xhr.loaded / xhr.total * 100)}  % loaded`);
        }, (error) => {
            console.log(error);
        });
    });
}

// 解析dxf板件类型（依据常用规格解析）
function parsePlankType(vector2Arr,onlyId,defaultDepth){

    const box2d = new Box3()
    box2d.setFromPoints(vector2Arr)
    const size2d = getBox3Size(box2d)
    const center2d = box2d.getCenter(new Vector3())
    const {x:sx,y:sy} = size2d
    const {x:px,y:py} = center2d
    let extrudeDep = 18

    let plankType = 0
    let name= ''

    if(sx>sy){
        if(sy<=30){
             // 横板
            plankType = zPlankType.LEVEL_PLANK
            name = `层板`
            extrudeDep = defaultDepth
            // 解析顶板底板
            if(T_B.exist){
                if(T_B.min>py){
                    T_B.min = py
                    T_B.minId = onlyId
                }
                if(T_B.max<py){
                    T_B.max = py
                    T_B.maxId = onlyId
                }
            }else{
                T_B.min = py
                T_B.max = py
                T_B.maxId = onlyId
                T_B.minId = onlyId
                T_B.exist = true
            }
        }else if(sy>=80&&sy<=150){
            // 踢脚板
            plankType = zPlankType.SKIRT
            name = `踢脚板`
          
        }else if(sy>150){
            // 抽屉门板
            plankType = zPlankType.DRAWER_DOOR
            name = `抽屉门板`
        }else{
            // 顶挡板
            plankType = zPlankType.BAFFLE
            name = `挡板`
        }
       
    }else{
        // 竖板
        if(sx<=30){
            name = `竖板`
            extrudeDep = defaultDepth
            plankType = zPlankType.VERTICAL_PLANK

             // 解析左右侧板
            if(L_R.exist){
                if(L_R.min>px){
                    L_R.min = px
                    L_R.minId = onlyId
                }
                if(L_R.max<px){
                    L_R.max = px
                    L_R.maxId = onlyId
                }
            }else{
                L_R.min = px
                L_R.max = px
                L_R.maxId = onlyId
                L_R.minId = onlyId
                L_R.exist = true
            }
        }else{
            name = `侧封板`
            extrudeDep =18
            plankType = zPlankType.SEALING
        }
      
  
    }

    return {plankType,name,extrudeDep}
}

function toVector2(point){
    const x = Math.round(point.x)
    const y = Math.round(point.y)
    return new Vector2(x, y)
 
}

function quickSort(arr) {
    if (arr.length <= 1) { return arr; }
    var pivotIndex = Math.floor(arr.length / 2);
    var pivot = arr.splice(pivotIndex, 1)[0];
    var left = [];
    var right = [];
    for (var i = 0; i < arr.length; i++) {
      let obj = arr[i]
      if (obj.referAxis < pivot.referAxis) {
        left.push(obj);
      } else {
        right.push(obj);
      }
    }
    return quickSort(left).concat([pivot], quickSort(right));
  };

  // 解析dxf 的LINE 和 LWPOLYLINE 为矩形坐标数组
function parseTypeLine(entities){
    let horData = []
    let verData = []
    const vecRects = []
    let existLine = false
    entities.map((entity, index) => {

        switch(entity.type){
            case "LWPOLYLINE":
                // 顶点数组转vector2数组
            const vector2Arr = pointToVector2(entity.vertices)
            vecRects.push(vector2Arr)
                break
            case "LINE":
                existLine = true
                const {0:sp, 1:ep} = entity.vertices

                const spV2 = toVector2(sp)
                const epV2 = toVector2(ep)
            
                if(Math.abs(spV2.x-epV2.x)<1){
                    // 竖线
                    const insertObj = {sp:spV2, ep:epV2,line:new Line3(spV2,epV2),referAxis:spV2.y}
                    verData.push(insertObj)
                }else{
                     // 横线 按x递增排序插入
                     // horData.push({dir:'H',sp:spV2, ep:epV2,refer:spV2.x})
                     const insertObj = {sp:spV2, ep:epV2,line:new Line3(spV2,epV2),referAxis:spV2.x}
                     horData.push(insertObj)
                }
                break
        }


    })

    if(existLine){
        const hData = quickSort(horData) 
        const vData = quickSort(verData) 



    for(let h1=0;h1<hData.length-1;h1++){
        let cur = hData[h1]
        let curLine = cur.line
        let curCenter= curLine.getCenter(new Vector3())
        curCenter.z = 0
        for(let i=h1+1;i<hData.length;i++){

            let next =hData[i]
            let nextLine = next.line
            let nextCenter= nextLine.getCenter(new Vector3())
            nextCenter.z = 0
            let dis = Math.abs(curLine.start.y-nextLine.start.y)
            if(curCenter.x==nextCenter.x&&Math.abs(curLine.distance()-nextLine.distance())<30&&dis<100){
                vecRects.push([cur.sp,cur.ep,next.ep,next.sp])
            }
        }

    }

    for(let v1=0;v1<vData.length-1;v1++){
        let cur = vData[v1]
        let curLine = cur.line
        let curCenter= curLine.getCenter(new Vector3())
        curCenter.z = 0
        for(let i=v1+1;i<vData.length;i++){
            let next =vData[i]
            let nextLine = next.line
            let nextCenter= nextLine.getCenter(new Vector3())
            nextCenter.z = 0
            let dis = Math.abs(curLine.start.x-nextLine.start.x)

            if(curCenter.y==nextCenter.y&&Math.abs(curLine.distance()-nextLine.distance())<30&&dis<100){
                vecRects.push([cur.sp,cur.ep,next.ep,next.sp])
            }
        }
    }
    }

    return vecRects

}
// 解析DXF文件
async function parseDXFData1(z3d, dxfData, cabSet, hexColor) {

    const { depth, mat } = cabSet
    const { scene,editor } = z3d
    const { entities } = dxfData;
    if (!entities.length) return
    const defaultDep = Number(depth) ?? 600
    const extrudeSettings = {
        steps: 2,
        depth: defaultDep,
        bevelEnabled: true,
        bevelThickness: 0,
        bevelSize: 0,
        bevelOffset: 0,
        bevelSegments: 1
    };

    const planks = []
    const cabGroup = new CabNode()
    z3d.cab3d = cabGroup
   
    const tempGroup = new Group()
    let texture = new TextureLoader().load(mat);
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    // texture.rotation = rotPlankTypes.includes(typeId)?0:Math.PI/2;
    const material = new MeshLambertMaterial({ map: texture ,visible:false})

    // 重置
     T_B = {  min:0, max:0,exist:false,minId:0,maxId:0 } // 上下最值
     L_R = {  min:0, max:0,exist:false,minId:0,maxId:0 } // 左右最值

    const vecArr = parseTypeLine(entities)
    vecArr.map((entity, index) => {
       
        // 顶点数组转vector2数组
        const vector2Arr =entity

        const onlyId =  getOnlyId()
        const {plankType,name,extrudeDep} = parsePlankType(vector2Arr,onlyId,defaultDep)
        const shape = new Shape(vector2Arr);
        // MyExtrudeGeometry
        extrudeSettings.depth = 18 // extrudeDep
        const geometry = new MyExtrudeGeometry(shape, extrudeSettings);
        geometry.rotateX(Math.PI / 2)
        //    geometry.center()
        const mesh = new Mesh(geometry, material);


        var box = new Box3().setFromObject(mesh);
        var size =getBox3Size(box)


        const center = box.getCenter(new Vector3());

        const plank = new PlankNode()
        plank.size = size
        plank.center = center
    
        plank.cid =onlyId
        plank.bodyMesh = mesh
        plank.plankType = plankType
        // plank.edgeMesh = edge
        plank.add(mesh)
        plank.isDxf = true

        if([zPlankType.BACK_PLANK,zPlankType.DOOR,zPlankType.SIDE_L,zPlankType.SIDE_R].includes(plankType)){
            addObb(plank)
        }
    

        plank.name = name
        planks.push(plank)

        tempGroup.add(plank)

        return entity
    })



    // 用于获取模型坐标
    const box3 = new Box3().setFromObject(tempGroup)
    const center = box3.getCenter(new Vector3());
    const groupSize = box3.getSize(new Vector3());

    // 纠正相对坐标
    planks.map(plank => {
        const {cid} = plank
       if(L_R.minId==cid){
        plank.plankType = zPlankType.SIDE_L
        plank.name = `左侧板`
       }else if(L_R.maxId==cid){
        plank.plankType = zPlankType.SIDE_R
        plank.name = `右侧板`
       }else if(T_B.minId==cid ){
        plank.name = `底板`
       }else if(T_B.maxId==cid ){
        plank.name = `顶板`
       }


        const realtivePos = plank.center.clone().sub(center)
        if([zPlankType.SKIRT,zPlankType.DRAWER_DOOR, zPlankType.BAFFLE,zPlankType.SEALING].includes(plank.plankType))realtivePos.y = -realtivePos.y
        plank.bodyMesh.geometry.center()
        const edge = new BoxHelper(plank.bodyMesh, 0x808080)
        plank.add(edge)
        plank.edgeMesh =  edge
        plank.position.set(realtivePos.x, realtivePos.y, realtivePos.z)


        cabGroup.add(plank)
       
    })
    cabGroup.outBox =  new Box3().setFromObject(cabGroup)
    cabGroup.size = groupSize

    cabGroup.position.set(0, 0, 0)
    z3d.ctrl3d.curCab = cabGroup
    cabGroup.editor = editor
    scene.add(cabGroup)
    setPlanePos(z3d,cabGroup)
}

async function parseDXFData(z3d, dxfData, cabSet, hexColor) {


    const { scene,editor } = z3d
    const { entities } = dxfData;
    if (!entities.length) return
    const defaultDep = 18
    const extrudeSettings = {
        steps: 2,
        depth: defaultDep,
        bevelEnabled: true,
        bevelThickness: 0,
        bevelSize: 0,
        bevelOffset: 0,
        bevelSegments: 1
    };

    const planks = []
    const cabGroup = new CabNode()
    z3d.cab3d = cabGroup
   
    const tempGroup = new Group()

    const material = new MeshLambertMaterial({ color:0xddd  ,visible:false})



    const vecArr = parseTypeLine(entities)
    vecArr.map((entity, index) => {
       
        // 顶点数组转vector2数组
        const vector2Arr =entity

        const onlyId =  getOnlyId()

        const shape = new Shape(vector2Arr);
        // MyExtrudeGeometry
        extrudeSettings.depth = 18 
        const geometry = new MyExtrudeGeometry(shape, extrudeSettings);
        geometry.rotateX(Math.PI / 2)
        const mesh = new Mesh(geometry, material);


        var box = new Box3().setFromObject(mesh);
        var size =getBox3Size(box)
        if(size.x>2000&&size.z>2000)return

        const center = box.getCenter(new Vector3());

        const plank = new PlankNode()
        plank.vec2Arr = vector2Arr
        plank.size = size
        plank.center = center
    
        plank.cid =onlyId
        plank.bodyMesh = mesh

        plank.add(mesh)
    

        plank.name = ''
        planks.push(plank)

        tempGroup.add(plank)

        return entity
    })



    // 用于获取模型坐标
    const box3 = new Box3().setFromObject(tempGroup)
    const center = box3.getCenter(new Vector3());
    const groupSize = box3.getSize(new Vector3());

    // 纠正相对坐标
    planks.map(plank => {

        const realtivePos = plank.center.clone().sub(center)

        plank.bodyMesh.geometry.center()
        const edge = new BoxHelper(plank.bodyMesh, 0x808080)
        plank.add(edge)
        plank.edgeMesh =  edge
        plank.position.set(realtivePos.x, realtivePos.y, realtivePos.z)


        cabGroup.add(plank)
       
    })
    cabGroup.outBox =  new Box3().setFromObject(cabGroup)
    cabGroup.size = groupSize

    cabGroup.position.set(0, 0, 0)
    z3d.ctrl3d.curCab = cabGroup
    cabGroup.editor = editor
    scene.add(cabGroup)
    setPlanePos(z3d,cabGroup)
    z3d.moveCamera(zDir.FRONT)
}
async function dxfHelper(z3d, dxfData, cabSet, hexColor) {


    const { scene,editor } = z3d
    const { entities } = dxfData;
    if (!entities.length) return

    const planks = []
    const cabGroup = new CabNode()
    z3d.cab3d = cabGroup
   
    const tempGroup = new Group()


    const vecArr = parseTypeLine(entities)
    const box3 = new Box3()
    vecArr.map((entity, index) => {
       
        // 顶点数组转vector2数组
        const vector2Arr =entity




       
        const box = new Box3()
        for(let i = 0;i<vector2Arr.length;i++){
            box.expandByPoint(new Vector3(vector2Arr[i].x,vector2Arr[i].y,0) )
            box3.expandByPoint(new Vector3(vector2Arr[i].x,vector2Arr[i].y,0) )
        }
        const bhelper = new Box3Helper( box,0x000 )
        // scene.add(bhelper)
        // return



        const onlyId =  getOnlyId()

        var size =getBox3Size(box)
        if(size.x>2000&&size.z>2000)return

        const center = box.getCenter(new Vector3());

        const plank = new PlankNode()
        plank.vec2Arr = vector2Arr
        plank.size = size
        plank.center = center
    
        plank.cid =onlyId
        plank.bodyMesh = bhelper // mesh

        // plank.edgeMesh = edge
        plank.add(bhelper)
    

      
    

        plank.name = ''
        planks.push(plank)

        tempGroup.add(plank)
        cabGroup.add(plank)

        return entity
    })



    // 用于获取模型坐标
    // const box3 = new Box3().setFromObject(tempGroup)
    const center = box3.getCenter(new Vector3());
    const groupSize = box3.getSize(new Vector3());

    // 纠正相对坐标
    
    cabGroup.outBox =  new Box3().setFromObject(cabGroup)
    cabGroup.size = groupSize

    cabGroup.position.set(0, 0, 0)
    z3d.ctrl3d.curCab = cabGroup
    cabGroup.editor = editor
    scene.add(cabGroup)
    setPlanePos(z3d,cabGroup)
    z3d.moveCamera(zDir.FRONT)
}
async function parseDXFData2(z3d, vecsArr,cabSet, hexColor) {

    const { depth, mat } = cabSet
    const { scene,editor } = z3d

    const defaultDep = Number(depth) ?? 600
    const extrudeSettings = {
        steps: 2,
        depth: defaultDep,
        bevelEnabled: true,
        bevelThickness: 0,
        bevelSize: 0,
        bevelOffset: 0,
        bevelSegments: 1
    };

    const planks = []
    const cabGroup = new CabNode()
    z3d.cab3d = cabGroup
   
    const tempGroup = new Group()
    let texture = new TextureLoader().load(mat);
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    // texture.rotation = rotPlankTypes.includes(typeId)?0:Math.PI/2;
    const material = new MeshLambertMaterial({ map: texture ,visible:true})

    // 重置
     T_B = {  min:0, max:0,exist:false,minId:0,maxId:0 } // 上下最值
     L_R = {  min:0, max:0,exist:false,minId:0,maxId:0 } // 左右最值

  
     vecsArr.map((vector2Arr, index) => {
       


        const onlyId =  getOnlyId()
        const {plankType,name,extrudeDep} = parsePlankType(vector2Arr,onlyId,defaultDep)
        const shape = new Shape(vector2Arr);
        // MyExtrudeGeometry
        extrudeSettings.depth =  extrudeDep
        const geometry = new MyExtrudeGeometry(shape, extrudeSettings);
        geometry.rotateX(Math.PI / 2)
        //    geometry.center()
        const mesh = new Mesh(geometry, material);


        var box = new Box3().setFromObject(mesh);
        var size =getBox3Size(box)


        const center = box.getCenter(new Vector3());

        const plank = new PlankNode()
        plank.size = size
        plank.center = center
    
        plank.cid =onlyId
        plank.bodyMesh = mesh
        plank.plankType = plankType
        // plank.edgeMesh = edge
        plank.add(mesh)
        plank.isDxf = true

        if([zPlankType.BACK_PLANK,zPlankType.DOOR,zPlankType.SIDE_L,zPlankType.SIDE_R].includes(plankType)){
            addObb(plank)
        }
    

        plank.name = name
        planks.push(plank)

        tempGroup.add(plank)

        return vector2Arr
    })



    // 用于获取模型坐标
    const box3 = new Box3().setFromObject(tempGroup)
    const center = box3.getCenter(new Vector3());
    const groupSize = box3.getSize(new Vector3());

    // 纠正相对坐标
    planks.map(plank => {
        const {cid} = plank
       if(L_R.minId==cid){
        plank.plankType = zPlankType.SIDE_L
        plank.name = `左侧板`
       }else if(L_R.maxId==cid){
        plank.plankType = zPlankType.SIDE_R
        plank.name = `右侧板`
       }else if(T_B.minId==cid ){
        plank.name = `底板`
       }else if(T_B.maxId==cid ){
        plank.name = `顶板`
       }


        const realtivePos = plank.center.clone().sub(center)
        if([zPlankType.SKIRT,zPlankType.DRAWER_DOOR, zPlankType.BAFFLE,zPlankType.SEALING].includes(plank.plankType))realtivePos.y = -realtivePos.y
        plank.bodyMesh.geometry.center()
        const edge = new BoxHelper(plank.bodyMesh, 0x808080)
        plank.add(edge)
        plank.edgeMesh =  edge
        plank.position.set(realtivePos.x, realtivePos.y, realtivePos.z)


        cabGroup.add(plank)
       
    })
    cabGroup.outBox =  new Box3().setFromObject(cabGroup)
    cabGroup.size = groupSize

    cabGroup.position.set(0, 0, 0)
    z3d.ctrl3d.curCab = cabGroup
    cabGroup.editor = editor
    scene.add(cabGroup)
    setPlanePos(z3d,cabGroup)
}


function setPlanePos(z3d,cab){
    const {position,size}  = cab
    const {x:sx,y:sy,z:sz} = size
    const {x:px,y:py,z:pz} = position
    z3d.backGroundPlane.position.y = sy*0.5-2
    z3d.groundPlane.position.z = sz*0.5-2
    z3d.sidePlane.position.x = sx*0.5-2
}

async function toLoadDxf(z3d, url, cabSet, color) {
    const { dxf } = await dxfloader(url)
    parseDXFData(z3d, dxf, cabSet, color)
}


function createPlank(size,pos,plankType,name,url){
    url = '/mat5.jpg'
    const {x:width,y:depth,z:height} = size.round()
    let geometry = new BoxBufferGeometry(width, depth, height)
    let texture = new TextureLoader().load(url);
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    // texture.rotation = rotPlankTypes.includes(typeId)?0:Math.PI/2;
    let material = new MeshLambertMaterial({ map: texture, alphaTest: 0.5 })

    const mesh = new Mesh(geometry, material);
    const edge = new BoxHelper(mesh, 0x808080)
    const plank = new PlankNode()
    plank.size = size
    const onlyId =  getOnlyId()
    plank.cid =onlyId
    plank.bodyMesh = mesh
    plank.plankType = plankType
    plank.name = name
    plank.edgeMesh = edge
    plank.position.set(pos.x, pos.y, pos.z)
    if([zPlankType.BACK_PLANK,zPlankType.DOOR,zPlankType.SIDE_L,zPlankType.SIDE_R].includes(plankType)){
        addObb(plank)
    }

    plank.add(mesh,edge)

    return plank
}

function addObb(node) {
        let size = node.size.clone().multiplyScalar(0.5)
        node.bodyMesh.geometry.userData.obb = new OBB(node.position,size);
        // node.bodyMesh.geometry.userData.obb.halfSize.copy(size).multiplyScalar(0.5);
        node.bodyMesh.userData.obb = new OBB();
    
}

export {
    toLoadDxf,
    parseDXFData,
    toImportGoods,
    createPlank,
    addObb,
    setPlanePos,
    parseDXFData2
}