/* eslint-disable class-methods-use-this */
import * as THREE from "three";
import { Color, Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import  {TransformControls}  from "three/examples/jsm/controls/TransformControls";
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';

import { TransformControls } from './public/myTransformControls'

import TWEEN from '@tweenjs/tween.js'


import PlankNode from './zPlankNode.js'
import CabNode from './zCabNode.js'
import {angleToRadian,xyz }  from './common'

import {ViewHelper} from './zViewHelper'
import { Editor } from '@/utils/Editor'
import { zDir } from './zDefine'


export default class baseManager {

    canvas

    sizes

    camera

    perCamera

    renderer

    clock

    previousTime

    controls

    raycaster

    light

    scene

    selectedNode

    domElement

    oriColor

    mouseUpEvent

    mouse

    spaceRuler

    cab3d
    is3D
    markPoint
    ctrl3d
    backGroundPlane
    groundPlane
    sidePlane

    viewHelper
    container
    thickness
    mesh
    labelRenderer

    transControl

    vecsArr
    constructor(container) {
        this.sizes = {}
        this.camera = null
        this.perCamera = null
        this.renderer = null
        // Scene
        this.scene = new THREE.Scene();

        this.clock = new THREE.Clock();
        this.previousTime = 0;
        this.raycaster = new THREE.Raycaster()
        this.mouse = new THREE.Vector2()
        this.is3D = true
        this.container = container
        this.thickness = 18


        this.initWindowSizes()
        this.initPerCamera()
        this.initCamera()
        this.intLights()
    
        this.initRenderer()
        this.initLabelRenderer()
        this.initControls()
        // this.initMesh1()
        this.initHelper()
        this.viewHelper = new ViewHelper(this.camera, container);
        this.initAnimateTick()
        this.domElement = this.renderer.domElement

        // this.mouseUpEvent = this.handleRaycasterNode.bind(this)
        // this.domElement.addEventListener('pointerup', this.mouseUpEvent, false)
       
        this.editor = new Editor(this)


      
        
    }

    initLabelRenderer() {
        this.labelRenderer = new CSS3DRenderer();
        this.labelRenderer.setSize(this.sizes.width, this.sizes.height);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        // //设置.pointerEvents=none，以免模型标签HTML元素遮挡鼠标选择场景模型
        this.labelRenderer.domElement.style.pointerEvents = 'none';
        this.labelRenderer.sortObjects = false//解决内空透视问题

        this.container.appendChild(this.labelRenderer.domElement);
    }

    /**
     * Sizes
     */
    initWindowSizes() {
        /**
         * Sizes
         */
     
        const sizes = {
            width: this.container.clientWidth??300,
            height: this.container.clientHeight??300,
        };

        window.addEventListener("resize", () => {
            // Update sizes
            if (!this.container) return
            sizes.width = this.container.clientWidth;
            sizes.height = this.container.clientHeight;
            // Update camera
            this.updateCamera(sizes)

            // Update renderer
            this.renderer.setSize(sizes.width, sizes.height);
            this.labelRenderer.setSize(sizes.width, sizes.height);
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        });

        this.sizes = sizes;
    }

    updateCamera(sizes) {
        const newAspect = sizes.width / sizes.height;
        this.perCamera.aspect = newAspect;
        this.perCamera.updateProjectionMatrix();
    }

    /**
     * PerCamera
     */
    initPerCamera() {
        // Base camera
        const perCamera = new THREE.PerspectiveCamera(
            // @ts-ignore
            undefined, this.sizes.width / this.sizes.height, 1, 18000
        );

        perCamera.position.set(0, -4000, 800);
        perCamera.up.set(0, 0, 1)
        perCamera.lookAt(new Vector3(0, 0, 0))

 

        this.perCamera = perCamera;
    }

    initCamera() {
        this.camera = this.perCamera
        this.scene.add(this.camera)
    }


    /**
     * intLights
     */
    intLights() {
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
        this.scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0x444444, 1.5);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(1024, 1024);
        directionalLight.shadow.camera.far = 6000;
        directionalLight.shadow.camera.near = 200;
        directionalLight.shadow.camera.left = -3000;
        directionalLight.shadow.camera.top = 3000;
        directionalLight.shadow.camera.right = 3000;
        directionalLight.shadow.camera.bottom = -3000;
        directionalLight.position.set(1000, -4000, 2000);
        this.scene.add(directionalLight);
        this.light = directionalLight

        const obj = new THREE.Object3D()
        obj.position.set(2000, 200, 1000)
        directionalLight.target = obj

    }



    /**
     * Controls
     */
    initControls() {
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.enableDamping = true;
        controls.enableRotate = true
        // controls.minPolarAngle = Math.PI/2.5;
        // controls.maxPolarAngle = Math.PI/2.5; // 控制上下360度的旋转范围
        // .minAzimuthAngle和.maxAzimuthAngle属性控制左右360度的旋转范围
        controls.enableZoom = true;
        controls.enablePan = true;
        controls.minDistance = 0 // 300
        controls.maxDistance = 9000
        this.controls = controls;


        // this.controls.enabled = false


        const transformControl = new TransformControls(this.camera, this.renderer.domElement)
        // transformControl.addEventListener('change', () => {
        // console.log('模型拖动')
        // })
        // transformControl.attach(this.mesh)
        transformControl.setMode('scale')
        this.transControl = transformControl
        this.scene.add(transformControl)

    }



    /**
     * Renderer
     */
    initRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            // canvas: this.canvas,
            antialias: true,
        });
        this.renderer.shadowMap.enabled = true;
        this.renderer.sortObjects = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        // @ts-ignore
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setClearColor("#fff");

        this.container.appendChild(this.renderer.domElement)
        this.canvas = this.renderer.domElement
    }


    /**
     * AnimateTick
     */
    initAnimateTick() {

        // Update controls
        this.controls.update();
        // Render
        this.renderer.clear()
        this.renderer.autoClear = false
     
        TWEEN.update()
        this.renderer.render(this.scene, this.camera);
        this.labelRenderer.render(this.scene, this.camera)
        this.viewHelper.render(this.renderer)
      
        // Call tick again on the next frame
        requestAnimationFrame(() => this.initAnimateTick())

    }



    initMesh() {

        const geometry = new THREE.BoxGeometry(100, 100, 100);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00,opacity:0.5 });
        const mesh = new THREE.Mesh(geometry, material);
        // this.scene.add(mesh);



        const length = 200
        const width = 18;
        const dep = 60

        const shape = new THREE.Shape();
        shape.moveTo( 0,0 );
        shape.lineTo( 0, width );
        shape.lineTo( length, width );
        shape.lineTo( length, 0 );
        shape.lineTo( 0, 0 );

        const shape1 = new THREE.Shape();
        shape1.moveTo( 0,0 );
        shape1.lineTo( 0, length );
        shape1.lineTo( width, length );
        shape1.lineTo( width, 0 );
        shape1.lineTo( 0, 0 );

        const extrudeSettings = {
            steps: 1,
            depth: dep,
            bevelEnabled: false,
            bevelThickness: 1,
            bevelSize: 1,
            bevelOffset: 0,
            bevelSegments: 1
        };

        const geometry1 = new THREE.ExtrudeGeometry( shape, extrudeSettings );
        geometry1.rotateX(Math.PI / 2)
        // geometry1.center()
        const geometry2 = new THREE.ExtrudeGeometry( shape1, extrudeSettings );
        geometry2.rotateX(Math.PI / 2)
        // geometry2.center()
        const material1 = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
        const mesh1 = new THREE.Mesh( geometry1, material1 ) ;
        const mesh2 = new THREE.Mesh( geometry2, new THREE.MeshBasicMaterial( { color: 0xccc } ) ) ;
        mesh2.position.set(0,100,0)

        this.mesh = mesh1

        let box3 = new THREE.Box3()
        box3.expandByObject(mesh1)
        console.log('box3',box3.getSize(new THREE.Vector3()))
        this.scene.add(mesh1,mesh2);
    }

    initMesh1(){

        const vecArr = [new Vector3(0,0,0),new Vector3(1000,0,0),new Vector3(1000,500,0),new Vector3(0,500,0)]
        const box3 = new THREE.Box3()
        for(let i = 0;i<vecArr.length;i++){
            box3.expandByPoint(vecArr[i])
        }
        const bhelper = new THREE.Box3Helper( box3,0x000 )
        this.scene.add(bhelper)
    }



    clearScene() {
        const that = this
        const cabNodes = this.scene.children.filter(item=>item instanceof CabNode)
   
        for(let s = cabNodes.length - 1; s >= 0; s--){
            const cab = cabNodes[s]
            for (let i = cab.children.length - 1; i >= 0; i--) {
                let node = cab.children[i]
                if (node instanceof PlankNode) {
                    node.traverse((item) => {
                        that.disposeNode(item)
                    })
                    cab.remove(node)
                } 

            }
            that.scene.remove(cab)
        }

    }



    disposeNode(item) {
        if (item.type === 'Mesh' || item.type === 'Line' || item.type === 'LineSegments') {
            item.geometry.dispose()
            item.material.dispose()
        }
    }

    delCurNode() {
        const that = this
        const node = this.selectedNode
        const cabNodes = this.scene.children.filter(item=>item instanceof CabNode)
        if (node) {
            node.traverse((item) => {
                that.disposeNode(item)
            })
           if(cabNodes.length) cabNodes[0].remove(node)
        }
    }

    findModelById(id){
 
    }

    /** 获取鼠标坐标 */
    getMousePointer(event) {
        const canvas = this.domElement
        const getBoundingClientRect = canvas.getBoundingClientRect()
        // 屏幕坐标转标准设备坐标
        this.mouse.x = ((event.clientX - getBoundingClientRect.left) / canvas.offsetWidth) * 2 - 1;// 标准设备横坐标
        this.mouse.y = -((event.clientY - getBoundingClientRect.top) / canvas.offsetHeight) * 2 + 1;// 标准设备纵坐标
        return this.mouse

    }

    setSelectNode(selectNode){
        if (this.selectedNode) {
            // this.selectedNode.bodyMesh.material.color.setHex(this.oriColor)
            this.selectedNode.edgeMesh.material.color.setHex(0x808080)
        }
        if (selectNode) {

            this.selectedNode = selectNode
            // this.oriColor = this.selectedNode.bodyMesh.material.color.getHex()
            // this.selectedNode.bodyMesh.material.color.setHex(0x00ff00)
            this.selectedNode.edgeMesh.material.color.setHex(0xff0000)
        }
    }

    handleRaycasterNode(event) {
        

        // 转换鼠标位置
        this.getMousePointer(event)
        // 计算raycaster
        this.raycaster.setFromCamera(this.mouse, this.camera)


        const cab = this.cab3d
        if(cab){
          this.spaceRuler.deleteSpaceBox()
        const  nodeData = this.getRaycasterNode(cab)
        if(!nodeData)return
        const { insectNode, faceNormal, clickPoint } = nodeData
       this.spaceRuler.getSpaceBox(insectNode, faceNormal, clickPoint)
        this.setSelectNode(insectNode)
        }
    }

    /* 射线检测选中mesh */
    getRaycasterNode(node) {
        const intersects = this.raycaster.intersectObjects([node], true)
        let curNode = null
        for (let i = 0; i < intersects.length; i += 1) {
            const intersect = intersects[i]
            let insectNode = intersect.object.parent
            let faceNormal = intersect.face?.normal
            let clickPoint = intersect.point.round()
            if(insectNode instanceof PlankNode )
            return { insectNode, faceNormal, clickPoint }


        }
        return curNode
    }


       /**
     * Helper
     */
       initHelper() {
        // const axes = new THREE.AxesHelper(2000);
        // this.scene.add(axes);

        
        // const gridHelper = new THREE.GridHelper(30000, 100, new Color(0xDDDDDD), new Color(0xDDDDDD));
        // gridHelper.rotateX(Math.PI);

        // 创建平面
        const planeGeometry = new THREE.PlaneGeometry(6000, 6000)
        const planeMaterial = new THREE.MeshLambertMaterial({ color: 0xcccc,side: THREE.DoubleSide })
        // 若使用基础网格材质(MeshBasicMaterial)则会发现光照没有阴影，因为这种材质不受光照影响
        const plane = new THREE.Mesh(planeGeometry, planeMaterial)
        let rot = angleToRadian(new Vector3(90, 0, 0))
        plane.setRotationFromEuler(new THREE.Euler(...xyz(rot), 'ZYX'))
        plane.visible = false


        const gplane = new THREE.Mesh(planeGeometry, planeMaterial)
        gplane.visible = false
        gplane.position.set(0, 0, -1200)

        const splane = new THREE.Mesh(planeGeometry, planeMaterial)
        let rot1 = angleToRadian(new Vector3(0, 90, 0))
        splane.setRotationFromEuler(new THREE.Euler(...xyz(rot1), 'ZYX'))
        splane.visible = false
        this.sidePlane = splane
        splane.position.set(1200, 0, 0)

        plane.position.set(0, 300, 0)
        this.scene.add(plane,gplane,splane)
        this.backGroundPlane = plane

        this.groundPlane = gplane

        // this.scene.add(gridHelper);
    }

    moveCamera(viewType){
        let newCamPos ,newCtlPos 
        const camPos = this.camera.position
        const ctlPos = this.controls.target
        let node = this.cab3d
        if(!node)return
        this.ctrl3d.viewDir = viewType
      
  
        newCamPos = node.getPosByDir(viewType)
        let {x:px,y:py,z:pz}=node.position
        newCtlPos =new THREE.Vector3(px,py,pz)
 
        let posN = 0
        switch(viewType){
            case zDir.TOP:
            case zDir.BOTTOM:
                posN = this.groundPlane.position.z
                this.groundPlane.position.z =  viewType==zDir.TOP?-Math.abs(posN): Math.abs(posN)
                 break
            case zDir.LEFT:
            case zDir.RIGHT:
                posN = this.sidePlane.position.x
                this.sidePlane.position.x =  viewType==zDir.LEFT?Math.abs(posN): -Math.abs(posN)
                break
            default:
                posN =this.backGroundPlane.position.y
                this.backGroundPlane.position.y =  viewType==zDir.BACK?-Math.abs(posN): Math.abs(posN)
                break
        }

       
       this.animateCamera(camPos,newCamPos,ctlPos,newCtlPos)
      
      }

      /**
 current1 相机当前的位置
 target1 相机的目标位置
 current2 当前的controls的target
 target2 新的controls的target
*/ 
 animateCamera(curCamPos, newCamPos, curCtlPos, newCtlPos){
    var obj = {
        camPos: curCamPos, // 相机当前位置x
        ctlPos:curCtlPos
    }
    var tween = new TWEEN.Tween(obj);
    tween.to({
      camPos:newCamPos,
      ctlPos:newCtlPos
    },1000);
    const that = this
    tween.onUpdate(function(){
  
        that.controls.target.set(obj.ctlPos.x,obj.ctlPos.y,obj.ctlPos.z);
        that.camera.position.set(obj.camPos.x,obj.camPos.y,obj.camPos.z) ;
     
        that.controls.update();
    })
    tween.onComplete(function(){
        that.controls.enabled = true;
    })
    tween.easing(TWEEN.Easing.Cubic.InOut);
    tween.start();
  }

}