<template>
  <div class="home">
    <el-container>
      <el-header>
        <Head @importCAD="handleCAD" @finishOrder="handleFinishOrder"  @handleEvent="handleEvent"/>
         <!-- <el-button type="primary" @click="handleCAD('/include/rect.dxf')">导入CAD</el-button>
          <el-button type="primary" @click="toDrawPlank(2)">开始</el-button> 
          <el-button type="primary" @click="toDrawPlank(13)">背板</el-button> 
          <el-button type="primary" @click="toDrawPlank(3)">门板</el-button> 
        <el-button type="primary" @click="handleEvent('del')">删除板件</el-button>  -->
      </el-header>
      <el-container class="home-main">
        <!-- <el-aside class="home-left">
          <el-scrollbar height="100%">
            <left />
          </el-scrollbar>
        </el-aside> -->
        <el-main>
          <div class="container"  >
          <Three3d ref="Three3dRef" />
      
          </div>

        <div class="cad-box" v-if="cadVisible" >
          <Cad3d  ref="Cad3dRef"  @handleDxf = 'handleDxf3d' :dxfUrl="dxfUrl"/>
        </div>
       
        </el-main>
        <!-- <el-aside class="home-right">
          <el-scrollbar height="100%">
            <Right />
          </el-scrollbar>
        </el-aside> -->
      </el-container>
    </el-container>
  </div>

  <el-dialog v-model="dialogVisible" title="基础配置" width="500" draggable>
   
    <el-form :model="form" label-width="auto" style="max-width: 500px">
    <el-form-item label="柜体深度">
      <el-input v-model="form.depth" />
    </el-form-item>

    <!-- <el-form-item label="颜色">
      <el-color-picker v-model="color"  @change="changeColor" />
    </el-form-item> -->

    <el-form-item label="材质">
      <div class="demo-image">
        <div class="img-box " :class="{'activeSelect':index==selectIndex}" v-for="(mat,index) in matArr" :key="index" >
          <el-image style="width: 100%; height: 100%" :src="mat" fit="contain" @click="changeMat(mat,index)" />
        </div>
      </div>
    </el-form-item>
   
 
  </el-form>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>


</template>

<script setup lang="ts">
import { ref, onMounted, watch,reactive } from 'vue'
import Head from '@/views/head/index.vue'
import Left from '@/views/left/index.vue'
import Right from '@/views/right/index.vue'
import Three3d from "./three3d/index.vue";
import Cad3d from "./cad3d/index.vue";
import {toImportGoods} from '@/util/zParse'

const cadVisible = ref(false)
// let initWidth = ref(0) // 父元素的宽-自适应值
// let initHeight = ref(0) // 父元素的高-自适应值
// let startclientX = ref(0) // 元素拖拽前距离浏览器的X轴位置
// let startclientY = ref(0) //元素拖拽前距离浏览器的Y轴位置
// let elLeft = ref(0) // 元素的左偏移量
// let elTop = ref(0) // 元素的右偏移量
// let back_box = ref()
// // 页面初始化
// function initBodySize() {
//     initWidth.value = back_box.value.clientWidth; // 拿到父元素宽
//     initHeight.value = initWidth.value * ((1080 * 0.88) / (1920 - 1080 * 0.02)); // 根据宽计算高实现自适应
// }
//   // 拖拽开始事件
//   function dragstart(e) {
//     console.log(e);
//     startclientX.value = e.clientX; // 记录拖拽元素初始位置
//     startclientY.value = e.clientY;
//   }
//   // 拖拽完成事件
//   function dragend(e) {
//     console.log(e);
//     let x = e.clientX - startclientX.value; // 计算偏移量
//     let y = e.clientY - startclientY.value;
//     elLeft.value += x; // 实现拖拽元素随偏移量移动
//     elTop.value += y;
//   }
//   onMounted(()=>{
//     initBodySize()

//   })

const dxfUrl = ref('')
const selectIndex = ref(0)
const matArr = ref([
  '/mat2.jpg','/mat3.jpg','/mat5.jpg'
])

const color = ref('#808080')

const form = ref({
  depth:600,
  mat:matArr.value[0]
})
const dialogVisible = ref(false)
const Three3dRef = ref()

const Cad3dRef = ref()

const changeColor = (color)=>{
  console.log('color',color)

}
const changeMat = (mat,index)=>{
  form.value.mat = mat
  selectIndex.value = index
}

const handleCAD = (url)=>{
  cadVisible.value = true
  if(url){
    dxfUrl.value = url
    Three3dRef.value.toParseDXF(url,form.value,color.value)
  }
}



const handleEvent = (type)=>{
  const z3d = Three3dRef.value.getz3d()

  switch(type){
    case 'del':
    case 'clear':
    Three3dRef.value.toDelCurModel(type)
      break;
    case 'back':

    z3d.controls.enableRotate = false
      z3d.ctrl3d.state = 13
      break;
    case 'door':

    z3d.controls.enableRotate = false
    z3d.ctrl3d.state = 3
      break;
    case 'line':

       z3d.controls.enableRotate = false
    z3d.ctrl3d.state = 2
      break;
    case 'undo':
    Three3dRef.value.toUndo()
      break
    case 'redo':
    Three3dRef.value.toRedo()
      break

  }


  
}

const handleFinishOrder = ()=>{
  const z3d = Three3dRef.value.getz3d()
  toImportGoods(z3d)
}

const toDrawPlank = (type)=>{
  const z3d = Three3dRef.value.getz3d()
  z3d.ctrl3d.state = type
  z3d.controls.enableRotate = false
  console.log(type)
}

const handleDxf3d = (val)=>{
  cadVisible.value = false
  if(val){
    Three3dRef.value.parseDxf3d(val,form.value,color.value)
  }
}

onMounted(()=>{
    const paramValue = window.location.search.substring(7)
    // 在控制台中输出参数值
   if(paramValue) localStorage.setItem('3dtoken',paramValue)

  
})
</script>

<style lang="less" scoped>
.activeSelect{
  border: 2px solid #409EFF;
}

.cad-box{



  width: 80%;
  height:  80%; // calc(100vh - 200px);
  // padding: 10px 0;
  z-index: 10;
  position: absolute;
  top:100px;
  left:100px;
  border: 1px solid #ccc;



}

.demo-image  {
  display: flex;
  flex-wrap: wrap;
  .img-box{
    width: 100px;
    height: 100px;
    margin: 5px
  }
}

.container {
  width: 100%;
  height:  calc(100vh - 50px);
  padding: 10px 0;
}
.home {
  width: 100%;
  height: 100vh;
}
.el-header {
  height: 50px;
  background: #F3F4F6;
  padding: 0;
}
.el-aside {
  background: palegoldenrod;
}
.el-main {
  // background: pink;
  padding: 0;
}
.home-main {
  height: calc(100vh - 50px);
  // background:skyblue;
}

.home-left {
  width: 60px;
}
.home-right {
  width: 260px;
}

</style>