<template>
  <div id="drag">
    <div class="back_box" ref="back_box">

      <div class="drag_box" draggable="true" @dragstart="dragstart($event)" @dragend="dragend($event)"
        :style="`left:${elLeft}px;top:${elTop}px`">
        <div class="drag-title">
          自由建柜
        </div> 
        <el-scrollbar style="height: calc(90vh - 80px);overflow-y: auto;">
          <div class="drag-item">
            <div class="drag-item-title">开始绘制</div>
            <el-button class="plus-but" type="primary" plain :icon="Plus" @click="createCab">新建空柜体</el-button>
          
            <div class="shuangji">或者右键取消绘制</div>
          </div>

          <div class="drag-item">

            <div class="drag-item-title">绘制视角</div>

            <div class="box-butts">
              <div class="boxbut" v-for="item in arr1" :key="item.value"
                :class="activeitem===item.value?'boxbutactive':''" @click="changeVision(item)">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="drag-item">
            <div class="drag-item-title">选择组件</div>
            <div class="box-opt">



              <div class="boxbox" v-for="item in arr2" :key="item.value"
                :class="activeopt===item.value?'boxboxactive':''" @click="changeOpt(item)">
                <el-tooltip placement="top" effect="light">
                  <template #content> {{ item.desc }}</template>
                  <div>
                    <img :src="item.icon" alt="">
                    <div class="t1">{{ item.title }}</div>
                    <div class="t2">{{ item.value }}</div>
                  </div>
                </el-tooltip>
              </div>


            </div>
          </div>
        </el-scrollbar>


        <!-- <div class="drag-bottom">
          <el-checkbox>隐藏其他模型</el-checkbox>
        </div> -->
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, defineEmits, onMounted} from 'vue'
import {Plus} from '@element-plus/icons-vue'
import QIcon from '@/assets/choice/1.png'
import WIcon from '@/assets/choice/2.png'
import EIcon from '@/assets/choice/3.png'
import RIcon from '@/assets/choice/4.png'
import TIcon from '@/assets/choice/5.png'
import YIcon from '@/assets/choice/6.png'
import UIcon from '@/assets/choice/7.png'
import { z3dCtrlState, zPlankType ,zDir} from "@/util/zDefine"


const emits = defineEmits(['drawPlank','moveCamera'])

const activeitem = ref(1);
const arr1 = [
  {value:1,title:'前视图',dir:zDir.FRONT},
  {value:2,title:'左视图',dir:zDir.LEFT},
  {value:3,title:'顶视图',dir:zDir.TOP},
  {value:4,title:'后视图',dir:zDir.BACK},
  {value:5,title:'右视图',dir:zDir.RIGHT},
  {value:6,title:'底视图',dir:zDir.BOTTOM},
]

const changeVision = (item) => {
  activeitem.value = item.value;
  emits('moveCamera',item.dir)
}

const activeopt = ref('');
const arr2 = [
  {value:'B',title:'柜体板件',icon: QIcon,type:z3dCtrlState.DRAW_LINE,desc:'柜体外横画添加顶板或底板，竖画添加左右侧板。柜体内横画添加层板，竖画添加立板，斜画添加背板'},
  // {value:'T',title:'踢脚底板',icon: WIcon,type:z3dCtrlState.DRAW_LINE},
  // {value:'C',title:'抽屉',icon: EIcon,type:z3dCtrlState.DRAW_LINE},
  // {value:'Y',title:'衣通',icon: RIcon,type:z3dCtrlState.DRAW_LINE},
  {value:'S',title:'收口板',icon: TIcon,type:z3dCtrlState.DRAW_SEAL,desc:'横画添加顶收口板或底收口板，竖画添加左收口板或右收口板'},
  {value:'L',title:'拉条',icon: YIcon,type:z3dCtrlState.DRAW_STRIP,desc:'柜体内横画添加拉条'},
  {value:'M',title:'门板',icon: UIcon,type:z3dCtrlState.DRAW_RECT,desc:'柜体内斜画添加门板'},
  // {value:'BB',title:'背板',icon: UIcon,type:z3dCtrlState.DRAW_BACK},
]

const changeOpt = (item) => {

  activeopt.value =item.value;
  emits('drawPlank',item.type)
}

const createCab = ()=>{
  const boxSize = {x:600,y:600,z:2400}
  emits('drawPlank','cab')
}

let initWidth = ref(0) // 父元素的宽-自适应值
let initHeight = ref(0) // 父元素的高-自适应值
let startclientX = ref(0) // 元素拖拽前距离浏览器的X轴位置
let startclientY = ref(0) //元素拖拽前距离浏览器的Y轴位置
let elLeft = ref(0) // 元素的左偏移量
let elTop = ref(0) // 元素的右偏移量
let back_box = ref()
// 页面初始化
function initBodySize() {
    initWidth.value = back_box.value.clientWidth; // 拿到父元素宽
    initHeight.value = initWidth.value * ((1080 * 0.88) / (1920 - 1080 * 0.02)); // 根据宽计算高实现自适应
}
  // 拖拽开始事件
  function dragstart(e) {
    console.log(e);
    startclientX.value = e.clientX; // 记录拖拽元素初始位置
    startclientY.value = e.clientY;
  }
  // 拖拽完成事件
  function dragend(e) {
    console.log(e);
    let x = e.clientX - startclientX.value; // 计算偏移量
    let y = e.clientY - startclientY.value;
    elLeft.value += x; // 实现拖拽元素随偏移量移动
    elTop.value += y;
  }
  onMounted(()=>{
    initBodySize()
  })
</script>

<style scoped lang="less">
.back_box {
  background: #ccc;
  /* width: 200px;
  height: 50vh;
  position: fixed; */
  position: absolute;
  top: 50px;
  left: 10px;
}


.drag_box {
  width: 250px;
  height: 90vh;
  background: #ffff;
  position: absolute;
  z-index: 10;
  user-select: none; 
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}
.drag-bottom {
  position: absolute;
  width: 250px;
  height: 50px;
  bottom: 0;
  left: 0;
  padding: 10px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  background: white;
}
.drag-title {
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  background: #F7F7F8;
  font-size: 14px;
}
.drag-item {
  padding: 10px;
  &-title {
    margin-bottom: 10px;
  }
  .plus-but {
    width: 100%;
    margin-bottom: 10px;
  }
  .shuangji {
    width: 100%;
    text-align: center;
  }
  .box-butts {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
    .boxbut {
      padding: 5px 10px;
      // background: #E3EFFE;
      background: #F5F5F6;
      border-radius: 2px;
      text-align: center;
    }
    .boxbutactive {
      background: #E3EFFE;
      color: #409EFF;
    }
  }
  .box-opt {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
    .boxbox {
      padding: 10px;
      height: 120px;
      // background: #E3EFFE;
      background: #F5F5F6;
      border-radius: 2px;
      text-align: center;
      img {
        width: 80%;
        margin-bottom: 10px;
      }
      .t1 {
        margin-bottom: 10px;
        text-align: center;
      }
      .t2 {
        width: 16px;
        background: #EDEDEF;
        padding: 2px 0;
        margin: 0 auto;
        border-radius: 2px;
      }
    }
    .boxboxactive {
      background: #E3EFFE;
      // color: #409EFF;
    }
  }
}
</style>