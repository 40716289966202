import { Vector3 } from "three"


//元件类型
const zPlankType = {
  VERTICAL_PLANK: 1,//立板
  LEVEL_PLANK: 2,//横板
  BACK_PLANK: 3,//背板
  DOOR: 4,//门板
  SKIRT: 5,//踢脚板
  SIDE_L: 6,//左侧板
  SIDE_R: 7, // 右侧板
  CORNER_LF: 8,//左转角柜前
  CORNER_RF: 9,//右转角柜前
  DRAWER_DOOR: 10,//抽屉门板
  BAFFLE: 11,//挡板
  SEALING: 12,//封板

  LEVEL_T:13,//顶板
  LEVEL_B:14,//底板
  STRIP:15, // 拉条

  SEAL_T:16,// 顶封板
  SEAL_B:17,// 底封板
  SEAL_L:18,// 左封板
  SEAL_R:19,// 右封板
  SEAL_H:20,// 横封板
  SEAL_V:21,// 竖封板
 
}

const zNodeType = {
  CAB: 1,
  PLANK: 2,
  DRAWER: 3,
}
const zDir = {
    TOP: 0,
    BOTTOM: 1,
    LEFT: 2,
    RIGHT: 3,
    FRONT: 4,
    BACK: 5
  }
  
  
//九点定位
const zNineDir = {
    L_T: 1,
    T_C: 2,
    R_T: 3,
    L_C: 4,
    C_C: 5,
    R_C: 6,
    L_B: 7,
    B_C: 8,
    R_B: 9
  }

  /**鼠标操作事件 */
const z3dCtrlState = {
    NONE: 0,
    SELECT: 1,
    DRAW_LINE: 2,
    DRAW_RECT: 3,
    DRAG_NODE: 4,
    SELECT_SPACE: 5,
    SELECT_CAB: 6,
    MUTIL_SELECT: 7,
    MUTIL_DOOR: 8,
    DRAW_DRAWER: 9,
    DRAW_BAFFLE: 10,
    DOUBLE: 11,
    DRAW_BOX: 12,
    DRAW_BACK: 13,
    DIVIDE: 14,
    DRAW_STRIP:15,
    DRAW_SEAL:16

  
  }

  const defaultSetting = {
    doorWidth: 500,
    moveDoorWidth: 500,
    drawerWidth: 500,
    drawerHeight: 200,
    average: 0,
    boxWidth: 0,
    boxHeight: 0,
    glassWidth: 50,
    glassColor: '#000',
    handleColor: '#000'
  
  }
  
  const rotPlankTypes = [zPlankType.BACK_PLANK, zPlankType.DOOR, zPlankType.CORNER_LF, zPlankType.CORNER_RF, zPlankType.BAFFLE]

  const ableCircleTypes = []
  const defaultSize = new Vector3()
  const zDoorType = {}

  export {
    zPlankType,zNodeType,zDir,zNineDir,z3dCtrlState,rotPlankTypes,defaultSetting,ableCircleTypes,defaultSize,zDoorType
  }