
import { Euler, Vector2, Vector3 } from "three";
import { zDir, zNineDir,zPlankType } from "@/util/zDefine";
import PlankNode from "./zPlankNode";

const referPlankTypes = [zPlankType.SIDE_L, zPlankType.SIDE_R, zPlankType.VERTICAL_PLANK, zPlankType.LEVEL_PLANK,zPlankType.LEVEL_T,zPlankType.LEVEL_B]

function isProxy(obj) {
  return Object.prototype.toString.call(obj) === "[object Proxy]" || Object.prototype.toString.call(obj) == '[object Object]';
}
function similar(v1, v2, e = 0.01) {
  if (!v1 || !v2) return false
  return Math.abs(v1.x - v2.x) < e && Math.abs(v1.y - v2.y) < e && Math.abs(v1.z - v2.z) < e
}
function antiSimilar(v1, v2, e = 0.01) {
  if (!v1 || !v2) return false
  return Math.abs(-v1.x - v2.x) < e && Math.abs(-v1.y - v2.y) < e && Math.abs(-v1.z - v2.z) < e
}

function similarXZ(v1, v2, e = 0.01) {
  if (!v1 || !v2) return false
  return Math.abs(v1.x - v2.x) < e && Math.abs(v1.z - v2.z) < e
}


function isNaN(v) {
  return v && !!(Number.isNaN(v.x) || Number.isNaN(v.y) || Number.isNaN(v.z))
}

function isNaN2d(v) {
  return v && !!(Number.isNaN(v.x) || Number.isNaN(v.y))
}

function abs(v) {
  v.x = Math.abs(v.x)
  v.y = Math.abs(v.y)
  v.z = Math.abs(v.z)
  return v
}

/** 获取[x, y, z]格式的数据 */
function xyz(o) {
  const { x, y, z } = o
  return [x, y, z] 
}

function computeAngle2d(sp, ep) {
  let x = Math.abs(ep.x - sp.x)
  let y = Math.abs(ep.y - sp.y)
  let len = Math.sqrt(x * x + y * y);
  let angle = Math.round((Math.asin(y / len) / Math.PI * 180));//最终角度
  return angle
}

function computeDir(angle, sp, ep) {
  let normal = ep.clone().sub(sp)
  let dir = -1
  if (angle > 45) {
    //上下
    if (normal.z > 0) dir = zDir.TOP
    else dir = zDir.BOTTOM

  } else {
    if (normal.x > 0) dir = zDir.RIGHT
    else dir = zDir.LEFT
  }
  return dir
}

function radianToAngle(v) {
  if (!v) return
  let { x, y, z } = v
  let ax = x * (180 / Math.PI);
  let ay = y * (180 / Math.PI);
  let az = z * (180 / Math.PI);
  return new Vector3(ax, ay, az)
}

function angleToRadian(v) {
  if (!v) return
  let { x, y, z } = v
  let rx = x * (Math.PI / 180);
  let ry = y * (Math.PI / 180);
  let rz = z * (Math.PI / 180);
  return new Vector3(rx, ry, rz)
}

function getRotVector(v, radian) {
  if (!v) return
  let newVector = v.clone().applyEuler(new Euler(...xyz(radian), 'ZYX'))
  return newVector
}

//获取中心位置的世界坐标
function getWorldPos(node) {
  let wpos = node.getWorldPosition(new Vector3())
  return wpos
}

function getBoxCenter(box) {
  if (box) {
    let center = box.max
      .clone()
      .add(box.min)
      .multiplyScalar(0.5)
    center = (!center.z ? isNaN2d(center) : isNaN(center)) ? new Vector3() : center
    box.center = center
    return center
  } else {
    return new Vector3()
  }
}

function getBoxSize(box) {
  if (box) {
    let size = box.max.clone().sub(box.min)
    size = (!size.z ? isNaN2d(size) : isNaN(size)) ? new Vector3() : size
    box.size = size
    return size
  } else {
    return new Vector3()
  }
}

/**判断坐标是否在box内 */
function ptInBox(box, pt) {

  if (!pt || !box) return false
  let { max, min } = getMinMax(box)

  let inx = pt.x >= min.x && pt.x <= max.x
  let iny = pt.y >= min.y && pt.y <= max.y
  let inz = pt.z >= min.z && pt.z <= max.z
  if (inx && iny && inz) {
    return true
  } else {
    return false
  }

}

function getMinMax(node) {
  // let cab = node.getCabNode()
  let rotSize = abs(node.size.clone().applyEuler(new Euler(...xyz(node.rotation), 'ZYX')).round())
  // if(node?.isCabNode)rotSize = abs(node.size.clone().applyEuler(new Euler(...xyz(cab.rotation!), 'ZYX')).round())
  let { x: sx, y: sy, z: sz } = rotSize
  let max, min
  let newPositions = []
  let positions = [
    new Vector3(-sx * 0.5, -sy * 0.5, sz * 0.5),
    new Vector3(-sx * 0.5, -sy * 0.5, -sz * 0.5),
    new Vector3(-sx * 0.5, sy * 0.5, sz * 0.5),
    new Vector3(-sx * 0.5, sy * 0.5, sz * 0.5),

    new Vector3(sx * 0.5, -sy * 0.5, sz * 0.5),
    new Vector3(sx * 0.5, -sy * 0.5, -sz * 0.5),
    new Vector3(sx * 0.5, sy * 0.5, sz * 0.5),
    new Vector3(sx * 0.5, sy * 0.5, sz * 0.5),
  ]
  positions.map(pos => {
    // let oripos = node.position.clone()
    let oripos = node.getWorldPosition(new Vector3()).clone()
    oripos.add(pos)
    newPositions.push(oripos)
  })

  for (let point of newPositions) {
    max = max ? max.max(point) : point.clone()
    min = min ? min.min(point) : point.clone()
  }

  return { min: min, max: max }
}

function generateId(nodes) {  //获取随机数id

  let id = getOnlyId()
  nodes.map(node => {
    node.cid = id
  })
  // return id;
}

function getOnlyId() {
  let date = Date.now();
  let rund = Math.ceil(Math.random() * 1000)
  let id = date + '' + rund;
  return id
}

function toFixed(v) {
  let { x, y } = v
  let x1 = x.toFixed(2);
  let y1 = y.toFixed(2);
  return new Vector3(x1, y1, 0)

}


function isPointOnLine(p1, p2, q) {
  let ori = p2.clone().sub(p1)
  let new1 = q.clone().sub(p1)
  let dot = new1.dot(ori)

  return dot > 0 ? true : false
}

//去重

function unique(nodes, arg1, arg2) {
  let data = [];
  nodes.forEach(item => {

    if (data.length == 0) {
      data.push(item);
    } else {

      let isDiff = true;//是否不同
      for (let i = 0; i < data.length; i++) {
        let dataItem = data[i];
        if (similar(dataItem[arg1], item[arg1]) && similar(dataItem[arg2], item[arg2])) {
          /*集合中已经存在相同数据*/
          isDiff = false;
          break;
        }
      }

      if (isDiff) {
        data.push(item);
      }
    }

  });


  return data


}

function unique1(nodes, arg1, arg2) {
  let data = [];
  nodes.forEach(item => {

    if (data.length == 0) {
      data.push(item);
    } else {

      let isDiff = true;//是否不同
      for (let i = 0; i < data.length; i++) {
        let dataItem = data[i];
        if (Math.abs(dataItem[arg1] - item[arg1]) < 2 && similar(dataItem[arg2], item[arg2])) {
          /*集合中已经存在相同数据*/
          isDiff = false;
          break;
        }
      }

      if (isDiff) {
        data.push(item);
      }
    }

  });


  return data


}

function unique0(nodes, arg1, arg2) {
  let obj = {};
  var unData = nodes.reduce((curr, next) => {
    /*判断对象中是否已经有该属性  没有的话 push 到 curr数组*/
    obj[next[arg1] + next[arg2]] ? '' : obj[next[arg1] + next[arg2]] = curr.push(next);
    return curr;
  }, []);
  return unData
}

//数字快排序
function quickSort(arr) {
  if (arr.length <= 1) { return arr; }
  var pivotIndex = Math.floor(arr.length / 2);
  var pivot = arr.splice(pivotIndex, 1)[0];
  var left = [];
  var right = [];
  for (var i = 0; i < arr.length; i++) {
    let obj = arr[i]
    if (obj < pivot) {
      left.push(obj);
    } else {
      right.push(obj);
    }
  }
  return quickSort(left).concat([pivot], quickSort(right));
};




/** 向量 */
const v = Vector2
/** 批量获取向量 */
/** 获取向量 */
function getV(n) {
  return new v(n.x, n.y)
}
function getVs(n2) {
  const vecs = n2.map(n => new v(n.x, n.y))
  return vecs
}
/** 获取指定向量的垂直向量 */
function getPerpVector(vec) {
  const { x, y } = vec
  const perp = new v(-y, x)
  perp.normalize();
  return perp
}

/** 获取圆弧圆心 */
function getArcCenter(p1, p2, radius, isMinorArc, isCCW) {
  const { pow, sqrt } = Math
  const [p1v, p2v] = getVs([p1, p2])
  const dist = p1v.distanceTo(p2v)
  if (radius < dist / 2) radius = Math.abs(p2v.y - p1v.y)
  const len = sqrt(pow(radius, 2) - pow(dist / 2, 2))
  const midv = p1v.clone().add(p2v).divideScalar(2)
  const perpv = getPerpVector(p2v.clone().sub(p1v))
  if (isMinorArc && !isCCW || !isMinorArc && isCCW) {
    perpv.negate();
  }
  const center = midv.clone().add(perpv.multiplyScalar(len))
  const { x, y } = center.round()
  return { x, y }
}

/** 获取圆弧的开始和结束角（弧度） */
function getArc2Angle(center, p1, p2) {
  const [p1v, p2v] = getVs([p1, p2])
  const centerV = getV(center)
  const startAngle = p1v.clone().sub(centerV).angle()
  const endAngle = p2v.clone().sub(centerV).angle()
  return [startAngle, endAngle]
}

function toVector3(v) {
  return v ? new Vector3(v.x, v.y, v.z) : new Vector3()
}

function getReverseDir(locationDir) {
  switch (locationDir) {
    case zNineDir.L_T: return zNineDir.R_T
    case zNineDir.R_T: return zNineDir.L_T
    case zNineDir.L_B: return zNineDir.R_B
    case zNineDir.R_B: return zNineDir.L_B
    case zNineDir.L_C: return zNineDir.R_C
    case zNineDir.R_C: return zNineDir.L_C
    case zNineDir.T_C: return zNineDir.B_C
    case zNineDir.B_C: return zNineDir.T_C

    default: return zNineDir.C_C
  }

}

// 四舍五入
function  pointToVector2(points) {
  const vector2Arr = []
  points.forEach((point) => {
      const x = Math.round(point.x)
      const y = Math.round(point.y)
      vector2Arr.push(new Vector2(x, y))
  })

  return vector2Arr
}


/**获取node上下左右坐标 */
function getPos(pos, orisize, rotate) {
  if (!rotate) rotate = new Vector3()
  // let size = orisize.clone().applyEuler(new Euler(...xyz(rotate!), 'ZYX'))
  let { x: sx, y: sy, z: sz } = orisize

  let top, bottom, left, right, front, back
  let topOff = getRotVector(new Vector3(0, 0, sz / 2), rotate)
  top = pos.clone().add(topOff).round()

  let bottomOff = getRotVector(new Vector3(0, 0, -sz / 2), rotate)
  bottom = pos.clone().add(bottomOff).round()

  let leftOff = getRotVector(new Vector3(-sx / 2, 0, 0), rotate)
  left = pos.clone().add(leftOff).round()

  let rightOff = getRotVector(new Vector3(sx / 2, 0, 0), rotate)
  right = pos.clone().add(rightOff).round()

  let frontOff = getRotVector(new Vector3(0, -sy / 2, 0), rotate)
  front = pos.clone().add(frontOff).round()

  let backOff = getRotVector(new Vector3(0, sy / 2, 0), rotate)
  back = pos.clone().add(backOff).round()
  return { top: top, bottom: bottom, left: left, right: right, front: front, back: back }
}

/**获取前面上下左右坐标 */
function getFrontPos(pos, orisize, rotate) {
  if (!rotate) rotate = new Vector3()
  // let size = orisize.clone().applyEuler(new Euler(...xyz(rotate!), 'ZYX'))
  let { x: sx, y: sy, z: sz } = orisize

  //左上
  let off1 = getRotVector(new Vector3(-sx / 2, -sy / 2, sz / 2), rotate)
  let FL = pos.clone().add(off1).round()

  //左下
  let off2 = getRotVector(new Vector3(-sx / 2, -sy / 2, -sz / 2), rotate)
  let FB = pos.clone().add(off2).round()

  //右上
  let off3 = getRotVector(new Vector3(sx / 2, -sy / 2, sz / 2), rotate)
  let RL = pos.clone().add(off3).round()

  //右下
  let off4 = getRotVector(new Vector3(sx / 2, -sy / 2, -sz / 2), rotate)
  let RB = pos.clone().add(off4).round()
  let frontArr = []
  frontArr.push(FL, FB, RL, RB)

  return frontArr
}

/**获取后面上下左右坐标 */
function getBackPos(pos, orisize, rotate) {
  if (!rotate) rotate = new Vector3()
  // let size = orisize.clone().applyEuler(new Euler(...xyz(rotate!), 'ZYX'))
  let { x: sx, y: sy, z: sz } = orisize

  //左上
  let off1 = getRotVector(new Vector3(sx / 2, sy / 2, sz / 2), rotate)
  let FL = pos.clone().add(off1).round()

  //左下
  let off2 = getRotVector(new Vector3(sx / 2, sy / 2, -sz / 2), rotate)
  let FB = pos.clone().add(off2).round()

  //右上
  let off3 = getRotVector(new Vector3(-sx / 2, sy / 2, sz / 2), rotate)
  let RL = pos.clone().add(off3).round()

  //右下
  let off4 = getRotVector(new Vector3(-sx / 2, sy / 2, -sz / 2), rotate)
  let RB = pos.clone().add(off4).round()
  let frontArr = []
  frontArr.push(FL, FB, RL, RB)

  return frontArr
}

function addPlankPoints(cab3d) {
  cab3d.children.map(n => {
      if (n instanceof PlankNode && referPlankTypes.includes(n.plankType))
      {
          let wpos = getWorldPos(n)
          let orisize = n.size
          let rotate = cab3d.rotation
          let frontPoints = getFrontPos(wpos, orisize, rotate)
          cab3d.markPoints.push(...frontPoints)
      }
  })


}

function getBox3Size(box){
  const size = box.getSize(new Vector3())
  return size.round()
}
export {
  isProxy,
  similar,
  isNaN,
  abs,
  xyz,
  computeAngle2d,
  radianToAngle,
  angleToRadian,
  getRotVector,
  getWorldPos,
  getBoxCenter,
  getBoxSize,
  getMinMax,
  generateId,
  getOnlyId,
  ptInBox,
  computeDir,
  isPointOnLine,
  unique,
  unique1,
  similarXZ,
  antiSimilar,
  quickSort,
  getArcCenter,
  getArc2Angle,
  toVector3,
  getReverseDir,
  pointToVector2,
  getPos,
  getFrontPos,
  getBackPos,
  addPlankPoints,
  getBox3Size

}




