import { BufferAttribute, BufferGeometry, Line, LineBasicMaterial } from "three";
import { CPoint } from "./public/zPoint";

class MarkPoint {
    curPoint
    z3d
    constructor(z3d) {
        this.z3d = z3d
    }

    createMarkPoint(pointName, v3) {
        if (!v3) return
        v3.y = -300
        let point = new CPoint({
            color: 0xb22222,
            size: 10,
            position: v3
        })
        point.name = pointName;
        this.curPoint = point
        return point
    }


    createLine = (lineId, p1, p2) => {
        if (!p1 || !p2) return

        const lineGeometry = new BufferGeometry();

        //初始化存放顶点信息的序列化数组
        const positions = new Float32Array([
            p1.x, p1.y, p1.z, //point0
            p2.x, p2.y, p2.z, //point1
        ]);

        //设置顶点信息
        lineGeometry.setAttribute('position', new BufferAttribute(positions, 3));

        let lineMaterial = new LineBasicMaterial({ color: 0xb22222, linewidth: 15 });
        let line = new Line(lineGeometry, lineMaterial);
        line.name = lineId;
        return line;
    };


    deleteMark() {
        let scene = this.z3d?.scene
        if (!scene) return
        let markPoints = scene.children.filter(n => n.type == 'Line' || n instanceof CPoint)
        for (let i = markPoints.length - 1; i >= 0; i--) {
            let mPoint = markPoints[i]
            this.disposMesh(mPoint)
            scene.remove(mPoint)
        }
    }

    updateLine(lineId, sp, ep) {
        if (!sp || !ep) return
        let activeLine = this.z3d.scene.getObjectByName(lineId);
        let { wpy } = this.z3d.cab3d
        if (activeLine && sp && ep) {
            // sp.y = -300
            // ep.y = -300
            let geometry = activeLine.geometry
            geometry.setFromPoints([sp, ep])
        }
    }

    updatePoint(activePoint, v3) {
        if (!v3) return
        this.curPoint = activePoint
        activePoint.geometry.setFromPoints([v3])
    }
    disposMesh(mesh) {
        mesh.geometry.dispose()
        if (mesh.material.dispose) mesh.material.dispose()
    }
}

export { MarkPoint }