import { Command } from '../Command.js';
import { Vector3 } from 'three';

/**
 * @param editor Editor
 * @param object THREE.Object3D
 * @param newPosition THREE.Vector3
 * @param optionalOldPosition THREE.Vector3
 * @constructor
 */
class SetMatrixCommand extends Command {

	constructor( editor, object, newMatrix, oldMatrix ) {

		super( editor );

		this.type = 'SetMatrix4Command';
		this.name = 'Set Matrix4';
		this.updatable = true;

		this.object = object.bodyMesh;

		if ( object !== undefined && newMatrix !== undefined ) {

			this.oldMatrix = object.matrix.clone();
			this.newMatrix = newMatrix.clone();

		}

		if ( oldMatrix !== undefined ) {

			this.oldMatrix = oldMatrix.clone();

		}

	}

	execute() {

		this.object.matrix.copy( this.newMatrix );
		this.object.updateMatrix()
		this.editor.signals.objectChanged.dispatch( this.object );

	}

	undo() {

		this.object.matrix.copy( this.oldMatrix );
		this.object.updateMatrix()
		this.editor.signals.objectChanged.dispatch( this.object );

	}

	update( command ) {

		this.newMatrix.copy( command.newMatrix );

	}

	toJSON() {

		const output = super.toJSON( this );

		output.objectUuid = this.object.uuid;
		output.oldMatrix = this.oldMatrix.toArray();
		output.newMatrix = this.newMatrix.toArray();

		return output;

	}

	fromJSON( json ) {

		super.fromJSON( json );

		this.object = this.editor.objectByUuid( json.objectUuid );
		this.oldMatrix = new Vector3().fromArray( json.oldMatrix );
		this.newMatrix = new Vector3().fromArray( json.newMatrix );

	}

}

export { SetMatrixCommand };
