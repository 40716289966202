<template>


  <!-- <el-dialog
    v-model="dialogVisible"
    title="Tips"
    width="1000"
    height="600"
    
  >
  <div  class='cad-container' ref="cad-container"></div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog> -->


    <div  class='cad-container' ref="cad-container">
  </div>
  <div class="btn">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleOk">
          确定
        </el-button>
      </div>



</template>

<script setup>
import { defineExpose, onMounted, defineProps,ref,onBeforeUnmount,watchEffect,defineEmits } from "vue";
import z3dManager from "@/util/z3dManager";
import { Vector3 } from 'three'
import {toLoadDxf} from '@/util/zParse'
import {Space} from '@/util/zSpace'
import Ctrl3D from '@/util/zCtrl3D'
import {MarkPoint} from '@/util/zMarkPoint'
import {z3dCtrlState } from '@/util/zDefine'

import { ElMessage, ElMessageBox } from 'element-plus'


const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  dxfUrl:{
    type:String,
    default:''
  }
})

let z3d = null;
let renderer = null
let elementDom = null
let ctrl3d = null;
 

const emits = defineEmits(['handleDxf'])
const handleOk = ()=>{
  const vecs = ctrl3d.vecsArr?.length?ctrl3d.vecsArr:null
  const title = '是否确认退出'
  let content = '还未框选有效区域，是否确认退出'
  const oktit = '确定'
  const canceltit = '我知道了'
  const typetit = 'warning'
  if(vecs){
    emits('handleDxf',vecs)
  }else{
    ElMessageBox.confirm(
    content,
    title,
    {
      confirmButtonText: oktit,
      cancelButtonText: canceltit,
      type: typetit,
    }
  ).then(() => {
    emits('handleDxf',vecs)
  })
  .catch(() => {
    // 取消
  })
  }

 
}
const handleCancel = ()=>{
  emits('handleDxf',null)
}

const toUndo = () => {
  //@ts-ignore
  const { editor, spaceRuler } = z3d

  editor.undo()
  spaceRuler.deleteRuler()

}
const toRedo = () => {
  //@ts-ignore
  const { editor } = z3d
  editor.redo()


}

watchEffect(()=>{
  const url = props.dxfUrl
 
 
})

const getz3d = () => {
  return z3d
}

const toParseDXF =(url,cabSet,color)=>{
 
toLoadDxf(z3d,url,cabSet,color)

ElMessage({
    showClose: true,
    message: '在画布中框选正面视图有效区域 ',
    type: 'info',
    offset:'15',
    duration:'3000'
  })
}






onBeforeUnmount(() => {

  });

  onMounted(()=>{
 const dom =  document.querySelector("div.cad-container")


  z3d = new z3dManager(dom);
  z3d.controls.enableRotate = false
  renderer = z3d.renderer
  elementDom = renderer.domElement
  ctrl3d = new Ctrl3D(z3d)
  ctrl3d.index = 11
  ctrl3d.state = z3dCtrlState.MUTIL_SELECT
  z3d.ctrl3d = ctrl3d

  let space = new Space(z3d)
  z3d.spaceRuler = space
  z3d.markPoint = new MarkPoint(z3d)

  if( props.dxfUrl)toParseDXF(props.dxfUrl)
})

defineExpose({
 getz3d,toParseDXF,toUndo,toRedo
});
</script>

<style scoped lang="less">

.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255);
  position: fixed;
  display: block;


}
.cad-container{
  width: 100%;
  height: 100%;
  outline: none;
  display: block;
}

.btn{
  width: 100%;
  background-color: #ccc;
  margin-top: 10px;
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: 0;
    right:0;

    
  }
.webgl {
  width: 100%;
  height: 100%;
  outline: none;
  display: block;
}

.webgl-box{
  width: 100%;
  height: 100%;
  outline: none;
  display: block;
}

a {
  color: #08e;
}

</style>
