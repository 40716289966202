
import Node  from "./zNode.js";
import { zNodeType,zDir } from "./zDefine.js";

import {
  Euler,
  MeshLambertMaterial,
  RepeatWrapping,
  Vector3,
} from "three";
import { SetMaterialCommand } from '@/utils/commands/SetMaterialCommand.js'
import { SetPositionCommand } from '@/utils/commands/SetPositionCommand.js'
import { MultiCmdsCommand } from '@/utils/commands/MultiCmdsCommand.js'
import { SetValueCommand } from '@/utils/commands/SetValueCommand.js'
import { SetScaleGeoCommand } from '@/utils/commands/SetScaleGeoCommand.js'


class PlankNode extends Node {
  nodeType
  size
  bodyMesh
  edgeMesh
  cid
  center
  plankType
  matRot
  isDxf
  vec2Arr
  constructor() {
    super()
    this.nodeType = zNodeType.PLANK
    this.isDxf = false

  }

  setMatRot(matRot) {
    let node = this
    node.matRot = matRot
    if (node.bodyMesh.material.map) node.bodyMesh.material.map.rotation = matRot
  }

    /**按标尺方向移动 */
    moveNodeByLine(dir, disStr) {
      let dis = eval(disStr)
      let node = this
      if (!node) return
      let cab = node.parent
      const { position } = node
      let posOffset = new Vector3()
  
      switch (dir) {
        case zDir.TOP:
          posOffset.set(0, 0, dis)
          break
        case zDir.BOTTOM:
          posOffset.set(0, 0, -dis)
          break
  
        case zDir.LEFT:
          posOffset.set(-dis, 0, 0)
          break
        case zDir.RIGHT:
          posOffset.set(dis, 0, 0)
          break
        case zDir.FRONT:
          posOffset.set(0, -dis, 0)
          break
        case zDir.BACK:
          posOffset.set(0, dis, 0)
          break
      }
  
      posOffset.applyEuler(new Euler(cab.rotation.x, cab.rotation.y, -cab.rotation.z, 'ZYX'))
      let oldPos = position.clone()
      position.add(posOffset.round())
  

  
      let moveDatas = [{ node: node, newPos: position, oldPos: oldPos }]
  
      //@ts-ignore
      let editor = node.parent.editor
      this.addMoveCommand(editor, moveDatas)
  
    }

    
  addMoveCommand(editor, moveDatas) {
    let commandArr = []
    moveDatas.map(moveData => {
      let { node, newPos, oldPos } = moveData
      commandArr.push(new SetPositionCommand(editor, node, newPos, oldPos))
      if (node.oldPosition) {
        commandArr.push(new SetValueCommand(editor, node, 'oldPosition', newPos, oldPos))
      }
    })
    editor.execute(new MultiCmdsCommand(editor, commandArr), 'Flatten Geometry');
  }

}
  export default PlankNode