/**封装圆点 */
import * as THREE from 'three';
 

export class CPoint extends THREE.Points {
    constructor(params) {
        super();
 
        // create geometry
        const geo = new THREE.BufferGeometry();

        const position = params?.position || new THREE.Vector3()
        let {x,y,z} = position
        const positionAttr = new THREE.BufferAttribute(
            new Float32Array([x,y,z]),
            3
        );

        geo.setAttribute('position', positionAttr);
 
        const color = params?.color || 0xffcc00;
        const size = params?.size || 20;
 
        //material
        const material = new THREE.PointsMaterial({
            color: color,
            size: size,
            transparent: true,
            depthTest: false,
            sizeAttenuation: false,
        });
 
        material.onBeforeCompile = (shader) => {
            shader.fragmentShader = shader.fragmentShader.replace(
                'vec4 diffuseColor = vec4( diffuse, opacity );',
                `
                if (distance(gl_PointCoord, vec2(0.5, 0.5)) > 0.5) discard;
                vec4 diffuseColor = vec4( diffuse, opacity );
            `
            );
        };
 
        this.geometry = geo;
        this.material = material;
        this.renderOrder = 1;
        this.matrixAutoUpdate = false;
    }
 
    get pointMaterial(){
        return this.material ;
    }
}
