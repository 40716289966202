<template>
  <div class="design-head">
    <div class="logo"></div>
    <div class="center">
      <ul class="ullist">
        <li class="cadli" @click="uploadshow = !uploadshow">
          <div class="icon">
            <img src="@/assets/tools/file.png" alt="导入CAD">
          </div>
          <div class="text">导入CAD</div>


            <!-- 导入CAD -->
        <div class="importCAD" v-show="uploadshow">
          <div class="importCAD-title">导入板件轮廓</div>
          <div class="importCAD-upload">
            <el-upload 
            ref="upload" 
            :v-model="fileList" 
            class="upload-demo" 
            :action="uploadaction" 
            :limit="1"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :on-change="changeDemoFile" 
            :on-progress="handleProgress"
            @success="success"
            >
            <template #trigger>
              <div class="upload-box">
                <div class="upload-box-icon">
                  <div class="icon-icon">
                    <img src="@/assets/tools/imcad.png" alt="">
                  </div>
                  <div class="icon-txt">点击导入CAD文件</div>
                </div>
                <div class="upload-box-text">导入CAD文件重新绘制板件轮廓</div>
              </div>
            </template>
          </el-upload>
          </div>
          <div class="importCAD-description">
            <span>CAD 文件要求:</span> 
            <span>1.文件大小不超过5M</span>
            <span>2.仅支持 dxf 格式文件</span> 
            <span>3.必须是单个闭合图形(支持孔，洞)</span>
            <span>4.不支持含椭园、槽或标注的文件</span>
          </div>
        </div>

        <div class="importProgre" v-show="uploadload">
          <el-icon class="rotateicon" :size="20" color="skyblue">
            <RefreshRight style="width: 50px;height: 50px;" />
          </el-icon>
          <span>CAD文件处理中</span>
        </div>
        </li>
      

       
        <!-- <li>
          <el-upload ref="upload" :v-model="fileList" class="upload-demo" :action="uploadaction" :limit="1"
            :on-change="changeDemoFile" @success="success">
            <template #trigger>
              <el-button type="primary">导入CAD</el-button>
            </template>

          </el-upload>
        </li> -->

        <li @click="handleClick('del')">
          <div class="icon">
            <img src="@/assets/tools/del.png" alt="删除">
          </div>
          <div class="text">删除</div>
        </li>

        <li @click="handleClick('clear')">
          <div class="icon">
            <img src="@/assets/tools/clear.png" alt="">
          </div>
          <div class="text">清空</div>
        </li>

        <li @click="handleClick('undo')">
          <div class="icon">
            <img src="@/assets/tools/undo.png" alt="">
          </div>
          <div class="text">撤销</div>
        </li>

        <li @click="handleClick('redo')">
          <div class="icon">
            <img src="@/assets/tools/redo.png" alt="">
          </div>
          <div class="text">恢复</div>
        </li>

        <!-- <li @click="handleClick('back')">
          <div class="icon"></div>
          <div class="text">清空</div>
          <el-button type="primary">背板</el-button>
        </li> -->

        <!-- <li @click="handleClick('door')">
          <div class="icon"></div>
          <div class="text">清空</div>
          <el-button type="primary">门板</el-button>
        </li> -->

        <!-- <li @click="handleClick('line')">
          <div class="icon"></div>
          <div class="text">清空</div>
          <el-button type="primary">板件</el-button>
        </li> -->

        <!-- <li>
          <div class="icon"></div>
          <div class="text">保存</div>
        </li>
        <li class="right-bord">
          <div class="icon"></div>
          <div class="text">文件</div>
        </li>
        <li>
          <div class="icon"></div>
          <div class="text">保存</div>
        </li>
        <li>
          <div class="icon"></div>
          <div class="text">文件</div>
        </li>
        <li>
          <div class="icon"></div>
          <div class="text">保存</div>
        </li> -->
      </ul>

    </div>
    <div class="end">
      <!-- <el-button  >取消</el-button> -->
      <el-button type="primary"  @click="onFinish" :loading="loading">完成</el-button>


      <!-- <ul class="ullist">
        <li>
          <div class="icon"></div>
          <div class="text">文件</div>
        </li>
        <li>
          <div class="icon"></div>
          <div class="text">保存</div>
        </li>
        <li>
          <div class="icon"></div>
          <div class="text">文件</div>
        </li>
        
      </ul> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref,defineEmits} from 'vue'
import type { TabsInstance  } from 'element-plus'
// @ts-ignore
import { DXFLoader } from 'three-dxf-loader'
import { genFileId,ElMessage, ElMessageBox } from 'element-plus'
import {RefreshRight} from '@element-plus/icons-vue'

// 工具栏
// import ClearTool from '@/assets/tools/clear.png';
// import DelTool from '@/assets/tools/del.png';
// import FileTool from '@/assets/tools/file.png';
// import ALbumTool from '@/assets/tools/albme.png';
// import CameraTool from '@/assets/tools/camera.png';
// import PaperTool from '@/assets/tools/paper.png';
// import RedoTool from '@/assets/tools/redo.png';
// import SaveTool from '@/assets/tools/save.png';
// import ToolTool from '@/assets/tools/tool.png';
// import UodoTool from '@/assets/tools/uodo.png';


const emits = defineEmits(['importCAD','finishOrder','handleEvent'])

const upload = ref()
const loading = ref(false)
const fileList = ref()
const tabPosition = ref<TabsInstance['tabPosition']>('left')
const uploadaction = 'https://api.zaowuzhi.cn/api/v1/upload?app_id=287eab5a28&identity=33f01880fa858b6364b0da178e14044f'
const uploadshow = ref(false);
const uploadload = ref(false)


const onFinish = ()=>{
  loading.value = true
  emits('finishOrder',true)
  setTimeout(()=>{
    loading.value = false
  },1000)
}
function changeDemoFile(file: any,demoFileList:any){
  return
}


// 上传文件之前的钩子，对文件大小进行校验
const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 5;
  const str = file.name.split('.');
  if(str[1] !== 'dxf') {
    handleFail(1)
    return str[1];
  }
  // if (!isLt2M) {
  //   ElMessage.error('上传文件大小不能超过 5MB!')
  //   handleFail(2)
  //   return isLt2M;
  // }
}
// 切换上传文件
const handleExceed = (files) => { 
  upload.value.clearFiles()
  const file = files[0];
  file.uid = genFileId()
  upload.value.handleStart(file)
  upload.value.submit(file)
}
// 获取上传进度
const handleProgress = (event) => {
  uploadshow.value = false;
  uploadload.value = true;
  // console.log(`当前进度：${event.percent}%`);
}
// 上传成功时
const success = (res:any) => {
  if(res.data){
    emits('importCAD',res.data.file)
    uploadload.value = false;
  }
}
// 上传失败或者文件不满足要求
const handleFail = (val) => {
  const title = '导入文件不满足以下要求，请检查后重试'
  let content = '上传文件大小不能超过 5MB'
  const oktit = '重新上传'
  const canceltit = '我知道了'
  const typetit = 'error'
  if(val===1){
    content = '请上传CAD文件！'
  }
  
  ElMessageBox.confirm(
    content,
    title,
    {
      confirmButtonText: oktit,
      cancelButtonText: canceltit,
      type: typetit,
    }
  ).then(() => {
    upload.value.$el.querySelector('input').click(); 
  })
  .catch(() => {
    // 取消
  })
}

const handleClick=(type)=> {
  emits('handleEvent',type)
}

</script>

<style scoped lang="less">

.design-head {
  width: 100%;
  height: 50px;
  // background: #EAF3F9;
  padding: 0 10px;
  display: flex;
  .logo {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .center {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .end {
    // width: 200px;
    height: 100%;
    // background: pink;
    display: flex;
    align-items: center;
  }
}
.right-bord {
  border-right: 1px solid rgb(230, 229, 229);
}
.ullist {
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  li {
    // width: 50px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: pink;
    margin-right: 15px;
    
    .icon {
      // width: 25px;
      // height: 25px;
      // line-height: 25px;
      text-align: center;
      // background: plum;
      img {
        // width: 100%;
        height: 20px;
        vertical-align: middle;
      }
    }
    // .text {
      // font-size: 10px;
      // height: 12px;
    // }
  }
}

// 旋转动画
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
// 上传样式
.cadli {
  position: relative;
  .importCAD {
    position: absolute;
    top: 50px;
    left: -100px;
    // right: 0;
    // margin: 0 auto;
    width: 300px;
    // height: 200px;
    background: #fff;
    box-shadow: 1px 1px 10px #ccc;
    padding: 10px;
    z-index: 10;
    &-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-upload {
      width: 100%;
      height: 100px;
      border: 1px dashed #76797E;
      border-radius: 2px;
      margin-bottom: 10px;
      .upload-box {
        width: 278px;
        height: 98px;
        // background: pink;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        &-icon {
          margin:12px 0 5px 0;
          .icon-icon {
            // width: 40px;
            height: 30px;
            // background: plum;
            text-align: center;
            margin-bottom: 5px;
            img {
              // width: 100%;
              height: 100%;
            }
          }
          .icon-txt {
            text-align: center;
            color: #0D73F8;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
        &-text {
          text-align: center;
          color: #76797E;
          letter-spacing: 1px;
        }
      }
    }
    &-description {
      span {
        display: block;
        color: #76797E;
      }
    }
  }
  .importProgre {
    width: 300px;
    height: 40px;
    position: absolute;
    top: 50px;
    left: -100px;
    // right: 0;
    // margin: 0 auto;
    background: #fff;
    box-shadow: 1px 1px 10px #ccc;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .rotateicon {
      animation: rotate 2s linear infinite;
      margin-right: 10px;
    }
  }
}


</style>