import {
  Vector3
} from "three";
import Node  from "./zNode.js";
import { zNodeType,zDir } from "./zDefine.js";
import {  getRotVector,abs } from './common'

class CabNode extends Node {
  nodeType
  size
  cid //唯一标识
  outBox
  markPoints
  
  
  constructor() {
    super()
    this.nodeType = zNodeType.CAB
    this.markPoints = []
  }

  getPosByDir(dir){
    let pos = new Vector3(0,0,0)
    let node = this
    if(!node) return 
    let {rotation ,size,position}= node
    let translateSize = getRotVector(new Vector3(size.x,size.y,size.z), rotation )   
    let nodePos = new Vector3(position.x,position.y,position.z)
    let {x:sx,y:sy,z:sz} = abs(translateSize)
    let {x:px,y:py,z:pz} = nodePos
  let dis = 4000

 
      switch(dir){
        case zDir.TOP:
          pos.set(px,py,pz+sz/2+dis)
          break
        case zDir.BOTTOM:
          pos.set(px,py,pz-sz/2-dis)
          break
        case zDir.LEFT:
          pos.set(px-sx/2-dis,py,pz)
        
          break
        case zDir.RIGHT:
          pos.set(px+sx/2+dis,py,pz)
          break
        case zDir.FRONT:
          pos.set(px,py-sy/2-dis,pz+200)
          break
        case zDir.BACK:
          pos.set(px,py+sy/2+dis,pz+200)
          break
      }

  
    return pos
  }

}
  export default CabNode