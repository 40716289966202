import { BoxBufferGeometry, Euler, Mesh, MeshLambertMaterial, Plane, Vector3 } from 'three'
import { abs, xyz, similar, getWorldPos, getRotVector, isNaN, antiSimilar,getPos } from './common'
import { MeshRuler } from './zMeshRuler'
import { CSS3DSprite,CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import Node  from "./zNode.js";
import PlankNode  from "./zPlankNode.js";
import { zNodeType, zPlankType } from '@/util/zDefine';
import CabNode from './zCabNode.js'
import { zDir } from './zDefine.js';

const limitTypes = [zPlankType.SIDE_L, zPlankType.SIDE_R, zPlankType.VERTICAL_PLANK, zPlankType.LEVEL_PLANK,zPlankType.LEVEL_T,zPlankType.LEVEL_B]

/**
 * 先找线段再找平面的原因：plane是无限延伸的二维平面
 */
class Space {
    node
    z3d
    scene

    constructor(z3d) {
        this.z3d = z3d
        this.scene = z3d.scene

    }

    getCab(node) {
        let parent = node.parent
        if (parent && parent instanceof CabNode) {
            return parent
        } else {
            //@ts-ignore
            if (parent.getCab) parent.getCab()
        }
    }

    /**获取标尺 */
    getRulers(plank) {
        let cab = plank.parent
        if (!cab) return
        let rotation = cab.rotation
        let lines3d = []
        let label
        let {  cab3d } = this.z3d

        let rulerDirs = []
        let sameRuler = []
        const isRuler = true
        let clickPos = getWorldPos(plank)
        let finalPoints = this.getRulerPoints(plank, cab, isRuler, clickPos)

        let rotSize = abs(plank.size.clone().applyEuler(new Euler(...xyz(rotation), 'ZYX')))
        let { x: sx, y: sy, z: sz } = rotSize

        //@ts-ignore
        finalPoints.map(point => {
            let { sp, ep, length } = point
            let normal = ep.clone().sub(sp).normalize()
            let dis = length
            let dir = 0
            let newsp = sp.clone()
            let axisName = ''
            //筛去同方向标尺
            sameRuler = rulerDirs.filter(rulerDir => similar(rulerDir, normal, 0.01))
            if (sameRuler.length > 0) return
            else rulerDirs.push(normal)

            if (similar(normal, new Vector3(0, 0, 1), 0.01)) {
                newsp.add(new Vector3(0, 0, sz * 0.5))
                dis -= sz * 0.5
                dir = 0
                axisName = 'Z-rouler'
            }
            else if (similar(normal, new Vector3(0, 0, -1))) {
                newsp.add(new Vector3(0, 0, -sz * 0.5))
                dis -= sz * 0.5
                dir = 1
                axisName = 'Z-rouler'
            }
            else if (similar(normal, new Vector3(1, 0, 0))) {
                newsp.add(new Vector3(-sx * 0.5, 0, 0))
                dis -= sx * 0.5
                dir = 3
                axisName = 'X-rouler'
            }
            else if (similar(normal, new Vector3(-1, 0, 0))) {
                newsp.add(new Vector3(sx * 0.5, 0, 0))
                dis -= sx * 0.5
                dir = 2
                axisName = 'X-rouler'
            }
            //不显示深度尺寸
            else if (similar(normal, new Vector3(0, 1, 0))) {
                newsp.add(new Vector3(0, sy * 0.5, 0))
                dis -= sy * 0.5
                dir = 5
                axisName = 'Y-rouler'
            }
            else if (similar(normal, new Vector3(0, -1, 0))) {
                newsp.add(new Vector3(0, -sy * 0.5, 0))
                dis -= sy * 0.5
                dir = 4
                axisName = 'Y-rouler'
            }

            dis = Math.round(Math.abs(dis))
            if (dis > 27) {
                let meshRuler = new MeshRuler({
                    offsetDistance: 0
                })


                meshRuler.addRulerPlank(newsp, ep, axisName, 0)

                let labelPos = ep.clone().add(sp).multiplyScalar(0.5)
                label = this.createLabel(dis, labelPos.clone())

                
    
                let that = this
                let old = ''
                label.element.onfocus = function (e) {
                    old = e.target.value
                    e.target.value = ''
                }
                label.element.onblur = function (e) {
                    e.target.value = old
                }
                label.element.onkeyup = function (e, rayPoint) {
                    // @ts-ignore
                    let key = e.keyCode

                    if (key == 13) {

                        let inputDis = e.currentTarget.value
                        let movDis = dis - inputDis

                        plank.moveNodeByLine(dir, movDis)
                        // if (that.z3d?.is3D) updateNode2d(plank, cab2d)
                        // else {
                        //     that.z3d.ctrl3d.get2dRulers()
                        //     updateNode3d(plank, cab3d)

                        // }
                        that.deleteRuler()
                        that.getRulers(plank)

                    }

                }
                meshRuler.add(label)
                lines3d.push(meshRuler)
            }

        })

        lines3d.map(meshRuler => this.z3d.scene.add(meshRuler))

    }

    getSpaceBox(plank, faceNormal, oriClickPoint) {
        let { thickness, cab3d } = this.z3d
        let cab =  cab3d

        if (!cab) return
        let clickPoint = this.getOffClickPos(oriClickPoint.clone(), faceNormal, cab)
        let linePlaneData = this.getRulerPoints(plank, cab, false, clickPoint)
        //@ts-ignore
        let { pos, size } = linePlaneData
        if (isNaN(size) || isNaN(pos)) return
        if (size.x < thickness || size.z < thickness || size.y < thickness) return
        let box = new Box()
        box.createBox(size)
        box.box.material.visible = true
        let boxPos = pos.clone().sub(cab.position)
        box.position.set(boxPos.x, boxPos.y, boxPos.z)
        // box.box.renderOrder=100
        cab.add(box)
        return box
    }

    getSpaceBox1(plank, faceNormal, oriClickPoint,linDir='') {
        let { cab3d,  thickness,ctrl3d } = this.z3d
        if (plank == null) {
            faceNormal = ctrl3d.viewDir ==zDir.BACK?new Vector3(0, 1, 0): new Vector3(0, -1, 0)
            // opy=-300
        }
        if (plank == null && !cab3d.outBox.containsPoint(oriClickPoint)) {
     
            //点击在柜体外，则直接加
            return this.getClickOutBox(oriClickPoint,linDir)

        } else {

            let cab = cab3d
            if (!cab) return
            // let { dy, py } = cab.standardDyPy

            let clickPoint = this.getOffClickPos(oriClickPoint.clone(), faceNormal, cab)
            let linePlaneData = this.getRulerPoints(plank, cab, false, clickPoint)
            //@ts-ignore
            let { pos, size } = linePlaneData
            if (isNaN(size) || isNaN(pos)) return
            if (size.x <= thickness || size.z <= thickness) return
            let box = new Box()
            // size.y = dy
            box.createBox(size)
            box.box.material.visible = true
            let boxPos = pos.clone().sub(cab.position)
            box.position.set(boxPos.x, boxPos.y, boxPos.z)
            // box.box.renderOrder=100
            box.isOutBox = false
            return box
        }

    }

    // 柜体外绘制
    getClickOutBox(oriClickPoint,linDir){
    //点击在柜体外，则直接加
        
        const {x:opx,y:opy,z:opz} = oriClickPoint
        let { thickness, cab3d,ctrl3d } = this.z3d
        const {viewDir} = ctrl3d
        let box = new Box()
        const {size,position} = cab3d
        const {x:cabSX,y:cabSY,z:cabSZ}=size
        const {x:cabPX,y:cabPY,z:cabPZ}=position
        let boxSize = new Vector3()
        let boxPos = new Vector3()
        let preType = 0
        let { left, right,top,bottom,front,back } = getPos(cab3d.position, cab3d.size, null)
        let isSave = true
        const existState = {
            side_L:0,
            side_R:0,
            level_T:0,
            level_B:0,
        }
      cab3d.children.map(n=>{
          if(n instanceof PlankNode ){
            switch(n.plankType){
                case zPlankType.SIDE_R:
                    existState.side_R = 1
                    break
                case zPlankType.SIDE_L:
                    existState.side_L = 1
                    break
                case zPlankType.LEVEL_T:
                    existState.level_T = 1
                    break
                case zPlankType.LEVEL_B:
                    existState.level_B = 1
                    break
                default:
            }

          }
    })

        // 处理穿模 偏移坐标
        let offDis = 2
        let offLen = 0
        let dis1 = 3
        switch(viewDir){
            case zDir.LEFT:
                  
                if(linDir=='V'){
                     offLen = (existState.level_B+existState.level_T)*18
                     offDis = (existState.level_B+existState.level_T*-1)*9
                    boxSize.set(cabSX,thickness,cabSZ-offLen)
                    if(opy > back.y){
                        // 左面左侧板
                        boxPos.set(cabPX,back.y-thickness*0.5+dis1,cabPZ+offDis)
                        preType = zPlankType.SIDE_L
                        
                    }else if(opy < front.y){
                        // 左面右侧板
                        boxPos.set(cabPX,front.y+thickness*0.5-dis1,cabPZ+offDis)
                        preType = zPlankType.SIDE_R
                    }else{
                        isSave = false
                    }
                }else if(linDir=='H'){
                    //横向
                    offLen = (existState.side_L+existState.side_R)*18
                    offDis = (existState.side_L*-1+existState.side_R)*9
                    boxSize.set(cabSX-offLen,cabSY,thickness)
                    if(opz>top.z){
                        // 左面顶板
                        boxPos.set(cabPX,cabPY+offDis,top.z-thickness*0.5+dis1)
                        preType = zPlankType.LEVEL_T
                    }else if(opz<bottom.z){
                        // 左面底板
                        boxPos.set(cabPX,cabPY+offDis,bottom.z+thickness*0.5-dis1)
                        preType = zPlankType.LEVEL_B

                    }else{
                        isSave = false
                    }
                }
                else{
                    // 无效区域
                    isSave = false
                }

                break
            case zDir.RIGHT:
                          //竖向
                          if(linDir=='V'){
                            offLen = (existState.level_B+existState.level_T)*18
                            offDis = (existState.level_B+existState.level_T*-1)*9
                            boxSize.set(cabSX,thickness,cabSZ-offLen)
                            if(opy > back.y){
                                // 右面左侧板
                    
                                boxPos.set(cabPX,back.y-thickness*0.5+dis1,cabPZ+offDis)
                                preType = zPlankType.SIDE_R
                            }else if(opy < front.y){
                                // 右面右侧板
                                boxPos.set(cabPX,front.y+thickness*0.5-dis1,cabPZ+offDis)
                                preType = zPlankType.SIDE_L
                            }else{
                                isSave = false
                            }
                        }else if(linDir=='H'){
                            //横向
                            offLen = (existState.side_L+existState.side_R)*18
                            offDis = (existState.side_L+existState.side_R*-1)*9
                            boxSize.set(cabSX,cabSY-offLen,thickness)
                            if(opz>top.z){
                                // 右面顶板
                                boxPos.set(cabPX,cabPY+offDis,top.z-thickness*0.5+dis1)
                                preType = zPlankType.LEVEL_T
                            }else if(opz<bottom.z){
                                // 右面底板
                                boxPos.set(cabPX,cabPY+offDis,bottom.z+thickness*0.5-dis1)
                                preType = zPlankType.LEVEL_B
                            }else{
                                isSave = false
                            }
                        }
                        else{
                            // 无效区域
                            isSave = false
                        }
                break
            case zDir.TOP:
             
                     //竖向
                if(linDir=='V'){
                    offLen = (existState.level_B+existState.level_T)*18
                    offDis = (existState.level_B+existState.level_T*-1)*9
                    boxSize.set(thickness,cabSY-offLen,cabSZ)
                    if(opx < left.x){
                        // 顶面左侧板
                        boxPos.set(left.x+thickness*0.5-dis1,cabPY+offDis,cabPZ)
                        preType = zPlankType.SIDE_L

                    }else if(opx > right.x){
                        // 顶面右侧板
                        boxPos.set(right.x-thickness*0.5+dis1,cabPY+offDis,cabPZ)
                        preType = zPlankType.SIDE_R
                    }else{
                        isSave = false
                    }
                }else if(linDir=='H'){
                    //横向
                    offLen = (existState.side_L+existState.side_R)*18
                    offDis = (existState.side_L+existState.side_R*-1)*9
                    boxSize.set(cabSX-offLen,thickness,cabSZ)
                    if(opy>back.y){
                        // 顶面顶板
                        boxPos.set(cabPX+offDis,back.y-thickness*0.5+dis1,cabPY)
                        preType = zPlankType.LEVEL_T

                    }else if(opy<front.y){
                        // 顶面底板
                        boxPos.set(cabPX+offDis,front.y+thickness*0.5-dis1,cabPY)
                        preType = zPlankType.LEVEL_B

                    }else{
                        isSave = false
                    }
                }
                else{
                    // 无效区域
                    isSave = false
                }
                break
            case zDir.BOTTOM:
                
                       //竖向
                       if(linDir=='V'){
                        offLen = (existState.level_B+existState.level_T)*18
                        offDis = (existState.level_B*-1+existState.level_T)*9
                        boxSize.set(thickness,cabSY-offLen,cabSZ)
                        if(opx < left.x){
                            // 底面左侧板
                            boxPos.set(left.x+thickness*0.5-dis1,cabPY+offDis,cabPZ)
                            preType = zPlankType.SIDE_L
                        }else if(opx > right.x){
                            // 底面右侧板
                            boxPos.set(right.x-thickness*0.5+dis1,cabPY+offDis,cabPZ)
                            preType = zPlankType.SIDE_R
                        }else{
                            isSave = false
                        }
                    }else if(linDir=='H'){
                        //横向
                        offLen = (existState.side_L+existState.side_R)*18
                        offDis = (existState.side_L+existState.side_R*-1)*9
                        boxSize.set(cabSX-offLen,thickness,cabSZ)
                        if(opy>back.y){
                            // 底面顶板
                            boxPos.set(cabPX+offDis,back.y-thickness*0.5+dis1,cabPY)
                            preType = zPlankType.LEVEL_B
                        
                        }else if(opy<front.y){
                            // 底面底板
                  
                            boxPos.set(cabPX+offDis,front.y+thickness*0.5-dis1,cabPY)
                            preType = zPlankType.LEVEL_T
                        }else{
                            isSave = false
                        }
                    }
                    else{
                        // 无效区域
                        isSave = false
                    }
                
                break
            case zDir.BACK:
                     //竖向
                     if(linDir=='V'){
                        offLen = (existState.level_B+existState.level_T)*18
                        offDis = (existState.level_B+existState.level_T*-1)*9
                       
                        boxSize.set(thickness,cabSY,cabSZ-offLen)
                        if(opx < left.x){
                            // 背面左侧板
                            boxPos.set(left.x+thickness*0.5-dis1,cabPY,cabPZ+offDis)
                            preType = zPlankType.SIDE_R
                        }else if(opx > right.x){
                            // 背面右侧板
                       
                            boxPos.set(right.x-thickness*0.5+dis1,cabPY,cabPZ+offDis)
                            preType = zPlankType.SIDE_L
                        }else{
                            isSave = false
                        }
                    }else if(linDir=='H'){
                        //横向
                        offLen = (existState.side_L+existState.side_R)*18
                        offDis = (existState.side_L*-1+existState.side_R)*9
                        boxSize.set(cabSX-offLen,cabSY,thickness)
                        if(opz>top.z){
                            // 背面顶板
                            boxPos.set(cabPX+offDis,cabPY,top.z-thickness*0.5-dis1)
                            preType = zPlankType.LEVEL_T
    
                        }else if(opz<bottom.z){
                            // 背面底板
                            boxPos.set(cabPX+offDis,cabPY,bottom.z+thickness*0.5+dis1)
                            preType = zPlankType.LEVEL_B
                        }else{
                            isSave = false
                        }
                    }
                    else{
                        // 无效区域
                        isSave = false
                    }
                break
            default:
                //竖向
                
                if(linDir=='V'){
                    offLen = (existState.level_B+existState.level_T)*18
                    offDis = (existState.level_B+existState.level_T*-1)*9
                    boxSize.set(thickness,cabSY,cabSZ-offLen)
        
                    if(opx < left.x){
                        // 正面左侧板
                        boxPos.set(left.x+thickness*0.5-dis1,cabPY,cabPZ+offDis)
                        preType = zPlankType.SIDE_L
                    }else if(opx > right.x){
                        // 正面右侧板
                        boxPos.set(right.x-thickness*0.5+dis1,cabPY,cabPZ+offDis)
                        preType = zPlankType.SIDE_R
                    }else{
                        isSave = false
                    }
                }else if(linDir=='H'){
                    //横向
                    offLen = (existState.side_L+existState.side_R)*18
                    offDis = (existState.side_L+existState.side_R*-1)*9
                    boxSize.set(cabSX-offLen,cabSY,thickness)
                    if(opz>top.z){
                        // 正面顶板
                        boxPos.set(cabPX+offDis,cabPY,top.z-thickness*0.5+dis1)
                        preType = zPlankType.LEVEL_T

                    }else if(opz<bottom.z){
                        // 正面底板
                        boxPos.set(cabPX+offDis,cabPY,bottom.z+thickness*0.5-dis1)
                        preType = zPlankType.LEVEL_T
                    }else{
                        isSave = false
                    }
                }
                else{
                    // 无效区域
                    isSave = false
                }



        }
     
        if(isSave){
            box.createBox(boxSize)
            box.box.material.visible = true
            box.preType = preType
            let boxRPos = boxPos.clone().sub(position)
            box.position.set(boxRPos.x, boxRPos.y, boxRPos.z)
            box.isOutBox = true
            return box
        }else return 

    }

    //获取标尺的点数据
    getRulerPoints(plank, cab, isRuler, clickPos) {
        let plankNodes = cab.children.filter(n => {
            if (limitTypes.includes(n.nodeType) && n.bodyMesh.material.visible) return n
            else if (n.isDrawerNode) return n

        })
        plankNodes.push(cab)
        let otherNodes = isRuler ? plankNodes.filter(n => n != plank) : plankNodes.filter(n => n.plankType != zPlankType.BACK_PLANK)//筛除当前点击的板件
        let lines = this.getlines(cab, otherNodes, clickPos, null)
        let linePlaneData = isRuler ? this.getRulerData(cab, plank, lines, clickPos, isRuler) :   this.getRulerData1(cab, plank, lines, clickPos, isRuler)



        if (isRuler) {
            //@ts-ignore
            let projectPoss = linePlaneData.projectPoss
            let finalPoints = []
            projectPoss.map(pos => {
                let dis = pos.distanceTo(clickPos);
                let length = Math.abs(dis)
                let ep = pos.clone()
                finalPoints.push({ sp: clickPos.clone(), ep: ep.clone(), length: length })
            })
            return finalPoints
        } else {
            return linePlaneData
        }


    }




    /*获取鼠标点可投影的 平行于X,Y,Z轴的线段数据*/
    getlines(cab, planks, clickPos, faceNormal) {

        let lineDatas = []
        planks.map(plank => {
            let wpos = getWorldPos(plank)
            let size = plank.size
            if (plank.nodeType == zNodeType.CAB) {
                let { min, max } = plank.outBox
                wpos = max.clone().add(min).multiplyScalar(0.5)
                size = abs(max.clone().sub(min))
            }
            let xlines = this.getlinesX(cab, plank, wpos, size, clickPos)
            let ylines = this.getlinesY(cab, plank, wpos, size, clickPos)//算深度
            let zlines = this.getlinesZ(cab, plank, wpos, size, clickPos)
            lineDatas.push({ plank: plank, xlines: xlines, ylines: ylines, zlines: zlines })
        })
        return lineDatas
    }

    /*判断点是否能投影到线段上,获取点到线段的距离*/
    pointInLine(sp, ep, clickPos) {
        let dis = 0
        let AB = ep.clone().sub(sp)
        let AO = clickPos.clone().sub(sp)
        let vec_coss1 = AO.clone().cross(AB).round()
        let r1 = AO.clone().dot(AB) / AO.length() * AB.length()

        let BA = sp.clone().sub(ep)
        let BO = clickPos.clone().sub(ep)
        let vec_coss2 = BO.clone().cross(BA).round()
        let r2 = BO.clone().dot(BA) / BA.length() * BO.length()

        if (r1 > 0 && r2 > 0) dis = vec_coss1.length() / AB.length()
        return dis

    }


    /*板件中平行于x的线段用于算高度*/
    getlinesX(cab, plank, pos, size, clickPos) {
        let rotation = cab?.rotation ? cab?.rotation : new Vector3()
        let orisize = plank._rotation ? abs(size.clone().applyEuler(new Euler(...xyz(plank.rotation), 'ZYX'))) : size
        let { top, bottom } = getPos(pos, orisize, rotation)


        let linesX = []
        let { x: sx, y: sy, z: sz } = size
        let spOff = getRotVector(new Vector3(-sx * 0.5, 0, 0), rotation)
        let epOff = getRotVector(new Vector3(sx * 0.5, 0, 0), rotation)
        let opOff = getRotVector(new Vector3(0, sy * 0.5, 0), rotation)
        let sp1 = top.clone().add(spOff)
        let ep1 = top.clone().add(epOff)
        let dis1 = this.pointInLine(sp1, ep1, clickPos)
        if (dis1 > 0) {
            let op1 = top.clone().add(opOff)
            let normal1 = new Vector3(0, 0, 1);
            let plane1 = new PlaneFace(sp1, ep1, op1, normal1)
            let edge1 = new Edge(sp1, ep1)
            edge1.parentFace = plane1
            edge1.parentPlank = plank
            edge1.clickToLineDis = dis1
            linesX.push(edge1)
        }

        let sp2 = bottom.clone().add(spOff)
        let ep2 = bottom.clone().add(epOff)
        //判断clickPos是否在线段范围内
        let dis2 = this.pointInLine(sp2, ep2, clickPos)
        if (dis2 > 0) {
            let op2 = bottom.clone().add(opOff)
            let normal2 = new Vector3(0, 0, -1);
            // 通过三个点定义一个平面
            let plane2 = new PlaneFace(sp2, ep2, op2, normal2)
            let edge2 = new Edge(sp2, ep2)
            edge2.parentFace = plane2
            edge2.parentPlank = plank
            edge2.clickToLineDis = dis2
            linesX.push(edge2)
        }

        return linesX

    }

    /*板件中平行于y的线段用于算深度*/
    getlinesY(cab, plank, pos, size, clickPos) {
        let rotation = cab?.rotation ? cab?.rotation : new Vector3()
        let orisize = plank._rotation ? abs(size.clone().applyEuler(new Euler(...xyz(plank.rotation), 'ZYX'))) : size
        let { front, back } = getPos(pos, orisize, rotation)



        let linesY = []
        let { x: sx, y: sy, z: sz } = size

        //temp延高线段用来检测深度
        let temp = 12
        let spOff = getRotVector(new Vector3(0, 0, sz * 0.5 + temp), rotation)
        let epOff = getRotVector(new Vector3(0, 0, -sz * 0.5 - temp), rotation)
        let opOff = getRotVector(new Vector3(sx * 0.5, 0, 0), rotation)

        let sp1 = front.clone().add(spOff)
        let ep1 = front.clone().add(epOff)

        let dis1 = this.pointInLine(sp1, ep1, clickPos)
        if (dis1 > 0) {
            let op1 = front.clone().add(opOff)
            let normal1 = new Vector3(0, -1, 0);
            let plane1 = new PlaneFace(sp1, ep1, op1, normal1)
            let edge1 = new Edge(sp1, ep1)
            edge1.parentFace = plane1
            edge1.parentPlank = plank
            edge1.clickToLineDis = dis1
            linesY.push(edge1)
        }

        let sp2 = back.clone().add(spOff)
        let ep2 = back.clone().add(epOff)
        let dis2 = this.pointInLine(sp2, ep2, clickPos)
        if (dis2 > 0) {
            let op2 = back.clone().add(opOff)
            let normal2 = new Vector3(0, 1, 0);
            let plane2 = new PlaneFace(sp2, ep2, op2, normal2)
            let edge2 = new Edge(sp2, ep2)
            edge2.parentFace = plane2
            edge2.parentPlank = plank
            edge2.clickToLineDis = dis2
            linesY.push(edge2)

        }

        return linesY
    }

    /*板件中平行于z的线段用于算宽度*/
    getlinesZ(cab, plank, pos, size, clickPos) {
        let rotation = cab?.rotation ? cab?.rotation : new Vector3()
        let orisize = plank._rotation ? abs(size.clone().applyEuler(new Euler(...xyz(plank.rotation), 'ZYX'))) : size
        let { left, right } = getPos(pos, orisize, rotation)


        let linesZ = []
        let { x: sx, y: sy, z: sz } = size

        let spOff = getRotVector(new Vector3(0, 0, sz * 0.5), rotation)
        let epOff = getRotVector(new Vector3(0, 0, -sz * 0.5), rotation)
        let opOff = getRotVector(new Vector3(0, sy * 0.5, -sz * 0.5), rotation)

        let sp1 = left.clone().add(spOff)
        let ep1 = left.clone().add(epOff)

        let dis1 = this.pointInLine(sp1, ep1, clickPos)
        if (dis1 > 0) {
            let op1 = left.clone().add(opOff)
            let normal1 = new Vector3(-1, 0, 0);
            let plane1 = new PlaneFace(sp1, ep1, op1, normal1)
            let edge1 = new Edge(sp1, ep1)
            edge1.parentFace = plane1
            edge1.parentPlank = plank
            edge1.clickToLineDis = dis1
            linesZ.push(edge1)
        }

        let sp2 = right.clone().add(spOff)
        let ep2 = right.clone().add(epOff)
        let dis2 = this.pointInLine(sp2, ep2, clickPos)
        if (dis2 > 0) {
            let op2 = right.clone().add(opOff)
            let normal2 = new Vector3(1, 0, 0);
            let plane2 = new PlaneFace(sp2, ep2, op2, normal2)
            let edge2 = new Edge(sp2, ep2)
            edge2.parentFace = plane2
            edge2.parentPlank = plank
            edge2.clickToLineDis = dis2
            linesZ.push(edge2)
        }

        return linesZ
    }

    /**显示标尺数据的标签 */
    createLabel(text, pos) {
        const div = document.createElement('input');
        div.style.overflow = 'hidden';
        div.value = text
        div.style.position = 'absolute';
        // div.style.padding = '4px 4px';
        div.style.width = '150px';
        div.style.height = '100px';
        div.style.fontSize = '60px';
        div.style.fontWeight = '600';
        div.style.borderRadius = '1px';
        div.style.border = 'none';
        div.style.textAlign = 'center';
        div.style.color = 'rgba(0,0,0,0.5)';
        div.style.position = 'absolute';
        div.style.backgroundColor = 'rgba(0,0,0,0.2)';
        div.style.pointerEvents = 'auto';
        div.className = 'myInput';
        // div元素包装成为css2模型对象CSS2DObject
        const label =  new CSS3DSprite(div);
        // div.style.pointerEvents = 'none';//避免HTML标签遮挡三维场景的鼠标事件
        // 设置HTML元素标签在three.js世界坐标中位置
        label.position.set(pos.x, pos.y, pos.z-200);

        return label;
    }


    createInnerLabel(text, pos) {
        const div = document.createElement('p');
        div.style.overflow = 'hidden';
        div.innerHTML = text
        div.style.position = 'absolute';
        // div.style.padding = '4px 4px';
        div.style.width = '150px';
        div.style.height = '300px';
        div.style.fontSize = '60px';
        div.style.fontWeight = '100';
        div.style.borderRadius = '1px';
        div.style.border = 'none';
        div.style.textAlign = 'center';
        div.style.color = 'rgba(0,0,0,1)';
        div.style.position = 'absolute';
        // div.style.backgroundColor = 'rgba(255,255,255,0.1)';
        div.style.pointerEvents = 'none';
        div.className = 'myInput';
        // div元素包装成为css2模型对象CSS2DObject
        const label = new CSS3DSprite(div);
        // div.style.pointerEvents = 'none';//避免HTML标签遮挡三维场景的鼠标事件
        // 设置HTML元素标签在three.js世界坐标中位置
        label.position.set(pos.x, pos.y, pos.z);
        return label;
    }

    getBoxData(cab, plank, lineDatas, clickPos, isRuler) {
        let { sortDataX, sortDataY, sortDataZ } = this.sortLine(lineDatas)
        let minx1, minx2, miny1, miny2, minz1, minz2
        let h1, h2, w1, w2, d1, d2, px1, px2, py1, py2, pz1, pz2
        let flag1 = false, flag2 = false, flag3 = false, flag4 = false, flag5 = false, flag6 = false
        let { x: psx, y: psy, z: psz } = plank ? abs(getRotVector(plank.size.clone(), cab.rotation)) : new Vector3()
        //保存投影点
        let projectPos1 = new Vector3(), projectPos2 = new Vector3(), projectPos3 = new Vector3()
        let projectPos4 = new Vector3(), projectPos5 = new Vector3(), projectPos6 = new Vector3()
        let normal1 = false, normal2 = false, normal3 = false, normal4 = false, normal5 = false, normal6 = false

        for (let i = 0; i < sortDataX.length; i++) {
            if (flag1 && flag2) break
            let sortX = sortDataX[i]
            if (!flag1) {
                minx1 = sortX
                h1 = minx1.parentFace.plane.distanceToPoint(clickPos);
                h1 = Math.round(h1)
                minx1.parentFace.plane.projectPoint(clickPos.clone(), projectPos1)
                normal1 = true
                pz1 = minx1.parentFace.p1.z
                // if(Math.sign(h1)!==Math.sign(h2)&&Math.abs(Math.abs(h1)-psz*0.5)>0.001)flag1 = true  
                if (Math.abs(Math.abs(h1) - psz * 0.5) > 0.01) {
                    flag1 = true
                } else {
                    continue
                }

            }
            if (flag1 && !flag2) {
                minx2 = sortX
                h2 = minx2.parentFace.plane.distanceToPoint(clickPos);
                h2 = Math.round(h2)
                minx2.parentFace.plane.projectPoint(clickPos.clone(), projectPos2)
                normal2 = true
                pz2 = minx2.parentFace.p1.z
                if (Math.sign(h1) !== Math.sign(h2) && Math.abs(Math.abs(h2) - psz * 0.5) > 0.01) flag2 = true
            }
        }

        for (let i = 0; i < sortDataZ.length; i++) {
            let sortZ = sortDataZ[i]
            if (flag3 && flag4) break
            if (!flag3) {
                minz1 = sortZ
                w1 = minz1.parentFace.plane.distanceToPoint(clickPos);
                w1 = Math.round(w1)
                minz1.parentFace.plane.projectPoint(clickPos.clone(), projectPos3)
                normal3 = true
                px1 = minz1.parentFace.p1.x
                if (Math.abs(Math.abs(w1) - psx * 0.5) > 0.01) flag3 = true
                else continue
            }
            if (flag3 && !flag4) {
                minz2 = sortZ
                w2 = minz2.parentFace.plane.distanceToPoint(clickPos);
                w2 = Math.round(w2)
                minz2.parentFace.plane.projectPoint(clickPos.clone(), projectPos4)
                normal4 = true
                px2 = minz2.parentFace.p1.x
                if (Math.sign(w1) !== Math.sign(w2) && Math.abs(Math.abs(w2) - psx * 0.5) > 0.01) flag4 = true

            }
        }

        let miny = 10000
        for (let i = 0; i < sortDataY.length; i++) {
            let sortY = sortDataY[i]
            if (flag5 && flag6) break
            if (!flag5) {
                miny1 = sortY
                d1 = miny1.parentFace.plane.distanceToPoint(clickPos);
                d1 = Math.round(d1)
                miny1.parentFace.plane.projectPoint(clickPos.clone(), projectPos5)
                normal5 = true
                py1 = miny1.parentFace.p1.y
                if (Math.abs(Math.abs(d1) - psy * 0.5) > 0.01) flag5 = true
                else continue
            }
            if (flag5 && !flag6) {
                miny2 = sortY
                let dy2 = miny2.parentFace.plane.distanceToPoint(clickPos);
                dy2 = Math.round(dy2)
                if (antiSimilar(miny1.parentFace, miny2.parentFace) && Math.abs(dy2) < miny) {
                    miny = Math.abs(dy2)
                    d2 = dy2
                    miny2.parentFace.plane.projectPoint(clickPos.clone(), projectPos6)
                    normal6 = true
                    py2 = miny2.parentFace.p1.y
                    if (Math.sign(d1) !== Math.sign(d2)) flag6 = true
                }

            }
        }


        if (!d2) {
            d2 = sortDataY[1]?.parentFace.plane.distanceToPoint(clickPos);
            py2 = sortDataY[1]?.parentFace.p1.y
        }
        let px = (px1 + px2) * 0.5, py = (py1 + py2) * 0.5, pz = (pz1 + pz2) * 0.5
        let sx = Math.abs(w1) + Math.abs(w2), sy = Math.abs(d1) + Math.abs(d2), sz = Math.abs(h1) + Math.abs(h2)
        let pos = new Vector3(px, py, pz)
        let size = new Vector3(sx, sy, sz)

        let allData = { pos: pos, size: size }
        return allData








    }

    getRulerData(cab, plank, lineDatas, clickPos, isRuler) {
        let { sortDataX, sortDataY, sortDataZ } = this.sortLine(lineDatas)
        let minx1, minx2, miny1, miny2, minz1, minz2
        let h1, h2, w1, w2, d1, d2, px1, px2, py1, py2, pz1, pz2
        let flag1 = false, flag2 = false, flag3 = false, flag4 = false, flag5 = false, flag6 = false
        let { x: psx, y: psy, z: psz } = plank.size.clone() //abs( getRotVector(plank.size.clone(),cab.rotation))
        //保存投影点
        let projectPos1 = new Vector3(), projectPos2 = new Vector3(), projectPos3 = new Vector3()
        let projectPos4 = new Vector3(), projectPos5 = new Vector3(), projectPos6 = new Vector3()
        let normal1 = false, normal2 = false, normal3 = false, normal4 = false, normal5 = false, normal6 = false

        for (let i = 0; i < sortDataX.length; i++) {
            if (flag1 && flag2) break
            let sortX = sortDataX[i]
            if (!flag1) {
                minx1 = sortX
                h1 = minx1.parentFace.plane.distanceToPoint(clickPos);
                h1 = Math.round(h1)
                minx1.parentFace.plane.projectPoint(clickPos.clone(), projectPos1)
                normal1 = true
                pz1 = minx1.parentFace.p1.z
                if (Math.abs(Math.abs(h1) - psz * 0.5) > 1) {
                    flag1 = true
                } else {
                    continue
                }

            }
            if (flag1 && !flag2) {
                minx2 = sortX
                h2 = minx2.parentFace.plane.distanceToPoint(clickPos);
                h2 = Math.round(h2)
                minx2.parentFace.plane.projectPoint(clickPos.clone(), projectPos2)
                normal2 = true
                pz2 = minx2.parentFace.p1.z
                if (Math.sign(h1) !== Math.sign(h2) && Math.abs(Math.abs(h2) - psz * 0.5) > 1) flag2 = true
            }
        }

        for (let i = 0; i < sortDataZ.length; i++) {
            let sortZ = sortDataZ[i]
            if (flag3 && flag4) break
            if (!flag3) {
                minz1 = sortZ
                w1 = minz1.parentFace.plane.distanceToPoint(clickPos);
                w1 = Math.round(w1)
                minz1.parentFace.plane.projectPoint(clickPos.clone(), projectPos3)
                normal3 = true
                px1 = minz1.parentFace.p1.x
                if (Math.abs(Math.abs(w1) - psx * 0.5) > 1) flag3 = true
                else continue
            }
            if (flag3 && !flag4) {
                minz2 = sortZ
                w2 = minz2.parentFace.plane.distanceToPoint(clickPos);
                w2 = Math.round(w2)
                minz2.parentFace.plane.projectPoint(clickPos.clone(), projectPos4)
                normal4 = true
                px2 = minz2.parentFace.p1.x
                if (Math.sign(w1) !== Math.sign(w2) && Math.abs(Math.abs(w2) - psx * 0.5) > 1) flag4 = true

            }
        }

        let miny = 10000
        for (let i = 0; i < sortDataY.length; i++) {
            let sortY = sortDataY[i]
            if (flag5 && flag6) break
            if (!flag5) {
                miny1 = sortY
                d1 = miny1.parentFace.plane.distanceToPoint(clickPos);
                d1 = Math.round(d1)
                miny1.parentFace.plane.projectPoint(clickPos.clone(), projectPos5)
                normal5 = true
                py1 = miny1.parentFace.p1.y
                if (Math.abs(Math.abs(d1) - psy * 0.5) > 0.001) flag5 = true
                else continue

            }
            if (flag5 && !flag6) {
                miny2 = sortY
                let dy2 = miny2.parentFace.plane.distanceToPoint(clickPos);
                dy2 = Math.round(dy2)
                if (Math.sign(d1) !== Math.sign(dy2) && Math.abs(dy2) < miny) {
                    miny = Math.abs(dy2)
                    d2 = dy2
                    miny2.parentFace.plane.projectPoint(clickPos.clone(), projectPos6)
                    normal6 = true
                    py2 = miny2.parentFace.p1.y
                    if (Math.sign(d1) !== Math.sign(d2)) flag6 = true
                }

            }
        }

        let projectPoss = []
        if (normal1) projectPoss.push(projectPos1)
        if (normal2) projectPoss.push(projectPos2)
        if (normal3) projectPoss.push(projectPos3)
        if (normal4) projectPoss.push(projectPos4)
        // if(normal5)projectPoss.push(projectPos5)
        // if(normal6)projectPoss.push(projectPos6)
        let allData = { projectPoss: projectPoss }
        return allData







    }

    getRulerData1(cab, plank, lineDatas, clickPos, isRuler) {
        let { sortDataX, sortDataY, sortDataZ } = this.sortLine(lineDatas)
        let minx1, minx2, miny1, miny2, minz1, minz2
        let h1, h2, w1, w2, d1, d2, px1, px2, py1, py2, pz1, pz2
        let flag1 = false, flag2 = false, flag3 = false, flag4 = false, flag5 = false, flag6 = false
        let { x: psx, y: psy, z: psz } = plank?plank.size.clone():new Vector3(20,20,20) //abs( getRotVector(plank.size.clone(),cab.rotation))
        //保存投影点
        let projectPos1 = new Vector3(), projectPos2 = new Vector3(), projectPos3 = new Vector3()
        let projectPos4 = new Vector3(), projectPos5 = new Vector3(), projectPos6 = new Vector3()
        let normal1 = false, normal2 = false, normal3 = false, normal4 = false, normal5 = false, normal6 = false

        for (let i = 0; i < sortDataX.length; i++) {
            if (flag1 && flag2) break
            let sortX = sortDataX[i]
            if (!flag1) {
                minx1 = sortX
                h1 = minx1.parentFace.plane.distanceToPoint(clickPos);
                h1 = Math.round(h1)
                minx1.parentFace.plane.projectPoint(clickPos.clone(), projectPos1)
                normal1 = true
                pz1 = minx1.parentFace.p1.z
                if (Math.abs(Math.abs(h1) - psz * 0.5) > 1) {
                    flag1 = true
                } else {
                    continue
                }

            }
            if (flag1 && !flag2) {
                minx2 = sortX
                h2 = minx2.parentFace.plane.distanceToPoint(clickPos);
                h2 = Math.round(h2)
                minx2.parentFace.plane.projectPoint(clickPos.clone(), projectPos2)
                normal2 = true
                pz2 = minx2.parentFace.p1.z
                if (Math.sign(h1) !== Math.sign(h2) && Math.abs(Math.abs(h2) - psz * 0.5) > 1) flag2 = true
            }
        }

        for (let i = 0; i < sortDataZ.length; i++) {
            let sortZ = sortDataZ[i]
            if (flag3 && flag4) break
            if (!flag3) {
                minz1 = sortZ
                w1 = minz1.parentFace.plane.distanceToPoint(clickPos);
                w1 = Math.round(w1)
                minz1.parentFace.plane.projectPoint(clickPos.clone(), projectPos3)
                normal3 = true
                px1 = minz1.parentFace.p1.x
                if (Math.abs(Math.abs(w1) - psx * 0.5) > 1) flag3 = true
                else continue
            }
            if (flag3 && !flag4) {
                minz2 = sortZ
                w2 = minz2.parentFace.plane.distanceToPoint(clickPos);
                w2 = Math.round(w2)
                minz2.parentFace.plane.projectPoint(clickPos.clone(), projectPos4)
                normal4 = true
                px2 = minz2.parentFace.p1.x
                if (Math.sign(w1) !== Math.sign(w2) && Math.abs(Math.abs(w2) - psx * 0.5) > 1) flag4 = true

            }
        }

        let miny = 10000
        for (let i = 0; i < sortDataY.length; i++) {
            let sortY = sortDataY[i]
            if (flag5 && flag6) break
            if (!flag5) {
                miny1 = sortY
                d1 = miny1.parentFace.plane.distanceToPoint(clickPos);
                d1 = Math.round(d1)
                miny1.parentFace.plane.projectPoint(clickPos.clone(), projectPos5)
                normal5 = true
                py1 = miny1.parentFace.p1.y
                if (Math.abs(Math.abs(d1) - psy * 0.5) > 0.001) flag5 = true
                else continue

            }
            if (flag5 && !flag6) {
                miny2 = sortY
                let dy2 = miny2.parentFace.plane.distanceToPoint(clickPos);
                dy2 = Math.round(dy2)
                if (Math.sign(d1) !== Math.sign(dy2) && Math.abs(dy2) < miny) {
                    miny = Math.abs(dy2)
                    d2 = dy2
                    miny2.parentFace.plane.projectPoint(clickPos.clone(), projectPos6)
                    normal6 = true
                    py2 = miny2.parentFace.p1.y
                    if (Math.sign(d1) !== Math.sign(d2)) flag6 = true
                }

            }
        }

        let px = (px1 + px2) * 0.5, py = (py1 + py2) * 0.5, pz = (pz1 + pz2) * 0.5
        let sx = Math.abs(w1) + Math.abs(w2), sy = Math.abs(d1) + Math.abs(d2), sz = Math.abs(h1) + Math.abs(h2)
        let pos = new Vector3(px, py, pz)
        let size = new Vector3(sx, sy, sz)

        let allData = { pos: pos, size: size }
        return allData







    }

    //根据点到线的距离排序
    sortLine(lineDatas) {
        let paralToXLines = []
        let paralToZLines = []
        let paralToYLines = []//前后
        lineDatas.map(lineData => {
            let { xlines, ylines, zlines } = lineData
            paralToXLines.push(...xlines)
            paralToZLines.push(...zlines)
            paralToYLines.push(...ylines)

        })
        let sortDataX = this.quickSort(paralToXLines)
        let sortDataZ = this.quickSort(paralToZLines)
        let sortDataY = this.quickSort(paralToYLines)
        return { sortDataX: sortDataX, sortDataY: sortDataY, sortDataZ: sortDataZ }
    }

    //根据距离快速排序
    quickSort(arr) {
        if (arr.length <= 1) { return arr; }
        var pivotIndex = Math.floor(arr.length / 2);
        var pivot = arr.splice(pivotIndex, 1)[0];
        var left = [];
        var right = [];
        for (var i = 0; i < arr.length; i++) {
            let obj = arr[i]
            if (obj.clickToLineDis < pivot.clickToLineDis) {
                left.push(obj);
            } else {
                right.push(obj);
            }
        }
        return this.quickSort(left).concat([pivot], this.quickSort(right));
    };



    /**删除标尺 */
    deleteRuler() {
        let scene = this.z3d.scene
        let meshRulers = scene.children.filter(n => n instanceof MeshRuler)
        meshRulers.map(meshRuler => {
            if (meshRuler.isCabRuler) return
            for (let i = meshRuler.children.length - 1; i >= 0; i--) {
                let meshruler = meshRuler.children[i]
                if (meshruler instanceof CSS3DSprite) {
                    meshRuler.remove(meshruler)
                } else {
                    this.disposMesh(meshruler)
                    scene.remove(meshRuler)
                }

            }
        })

    }

    /**删除内空 */
    deleteSpaceBox() {
        let scene = this.z3d.scene
        let cabs = scene.children.filter(n => n instanceof CabNode)
        cabs.map(cab => {
            cab.children.map(node => {
                if (node instanceof Box) {
                    this.disposMesh(node.children[0])
                    cab.remove(node)
                }
            })
        })

    }

    disposMesh(mesh) {
        mesh.geometry.dispose()
        if (mesh.material.dispose) mesh.material.dispose()
    }

    //获取鼠标点击偏移后的坐标
    getOffClickPos(clickPos, oriFaceNormal, cab) {
        let rotate = cab.rotation
        if (!oriFaceNormal) return clickPos
        let faceNormal = oriFaceNormal.clone()
        const offDis=18
        faceNormal.applyEuler(new Euler(...xyz(rotate), 'ZYX'))
        if (faceNormal && similar(faceNormal, new Vector3(0, 0, 1).applyEuler(new Euler(...xyz(rotate), 'ZYX')))) {
            clickPos.add(new Vector3(0, 0, offDis).applyEuler(new Euler(...xyz(rotate), 'ZYX')))
        }
        else if (faceNormal && similar(faceNormal, new Vector3(0, 0, -1).applyEuler(new Euler(...xyz(rotate), 'ZYX')))) {
            clickPos.add(new Vector3(0, 0, -offDis).applyEuler(new Euler(...xyz(rotate), 'ZYX')))
        }
        else if (faceNormal && similar(faceNormal, new Vector3(1, 0, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))) {
            clickPos.add(new Vector3(offDis, 0, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))
        }
        else if (faceNormal && similar(faceNormal, new Vector3(-1, 0, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))) {
            clickPos.add(new Vector3(-offDis, 0, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))
        }
        else if (faceNormal && similar(faceNormal, new Vector3(0, 1, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))) {
            clickPos.add(new Vector3(0, offDis, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))
        }
        else if (faceNormal && similar(faceNormal, new Vector3(0, -1, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))) {
            clickPos.add(new Vector3(0, -offDis, 0).applyEuler(new Euler(...xyz(rotate), 'ZYX')))
        }
        else {
            clickPos.add(new Vector3(offDis, offDis, offDis))
        }

        return clickPos
    }
}


class Edge {
    sp
    ep
    parentFace
    parentPlank
    clickToLineDis
    constructor(sp, ep) {
        this.sp = sp
        this.ep = ep
    }

}

class PlaneFace {
    p1
    p2
    p3
    normal
    plane
    constructor(p1, p2, p3, normal) {
        this.p1 = p1
        this.p2 = p2
        this.p3 = p3
        this.normal = normal
        this.plane = new Plane()
        this.plane.setFromCoplanarPoints(p1, p2, p3)
        // this.plane.normal.set(normal.x,normal.y,normal.z)
    }

    createPlane() {
        let plane = new Plane()
        plane.setFromCoplanarPoints(this.p1, this.p2, this.p3)
        plane.normal = this.normal
        return plane

    }
}

class Box extends Node {

    box
    isBoxNode
    _parent
    oriEdgeColor
    edgeMesh
    allowDel//延伸后的内空不允许删除
    size
    isOutBox

    preType
    constructor() {
        super()
        this.isBoxNode = true
        this._parent = parent
    }
    createBox(size) {
        let box = new Mesh(
            new BoxBufferGeometry(size.x, size.y, size.z),
            new MeshLambertMaterial({ color: 0x87cefa, transparent: true, opacity: 0.5, visible: false })
        );
        this.box = box
        this.size = size
        this.add(box)
    }

}

export { Space,Box }