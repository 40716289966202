import { Command } from '../Command.js';
import { AnimationClip,LoopOnce, AnimationMixer, Euler, KeyframeTrack } from "three"
/**
 * @param editor Editor
 * @param object THREE.Object3D
 * @param newValue 
 * @param optionalOldValue 
 * @constructor
 */
class MixerRotationCommand extends Command {

	constructor( editor, object,valueName ,newValue, optionalOldValue ) {

		super( editor );

		this.type = 'MixerRotationCommand';
		this.name = 'Set Rotation';
		this.updatable = true;

		this.valueName = valueName
		this.object = object;

		if ( object !== undefined && newValue !== undefined ) {

			this.newValue = newValue

		}

		if ( optionalOldValue !== undefined ) {

			this.oldValue = optionalOldValue

		}

	}

	execute() {
		this.toAction(this.object,this.valueName,this.newValue)
		this.object.updateMatrixWorld( true );
		this.editor.signals.objectChanged.dispatch( this.object );

	}

	undo() {

		this.toAction(this.object,this.valueName,this.oldValue)
		this.object.updateMatrixWorld( true );
		this.editor.signals.objectChanged.dispatch( this.object );

	}

	update( command ) {

		// this.newValue.copy( command.newValue );

	}

	toAction(node,name,value){
		const times = [0, 1]; // 关键帧时间数组

		const rotationTrack = new KeyframeTrack(
		name,
		times,
		value
		); // 关键帧轨道
		const duration = 3; // 持续时间 (单位秒)
		const clip = new AnimationClip('open', duration, [rotationTrack]); // 动画剪辑
	
		
		let mixer = new AnimationMixer(node); // 动画混合器
		const AnimationAction = mixer.clipAction(clip); // 返回所传入的剪辑参数的AnimationAction
		// AnimationAction.timeScale = 1; // 可以调节播放速度，默认是1。为0时动画暂停。值为负数时动画会反向执行。
		AnimationAction.loop = LoopOnce; //不循环播放
		AnimationAction.clampWhenFinished = true; //暂停在最后一帧播放的状态
		// 设置播放区间10~18   关键帧数据总时间是20
	  	AnimationAction.time = 3; //操作对象设置开始播放时间
		AnimationAction.play(); // 开始播放
		mixer.update()
	}

	toJSON() {

		const output = super.toJSON( this );

		output.objectUuid = this.object.uuid;
		output.oldValue = this.oldValue.toArray();
		output.newValue = this.newValue.toArray();

		return output;

	}

	fromJSON( json ) {

		super.fromJSON( json );

		this.object = this.editor.objectByUuid( json.objectUuid );
		this.oldValue = new Euler().fromArray( json.oldValue );
		this.newValue = new Euler().fromArray( json.newValue );

	}

}

export { MixerRotationCommand };
