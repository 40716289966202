import {Box3Helper , Euler,Box3, Group, Matrix4,BoxGeometry,Mesh, MeshBasicMaterial, Raycaster, Scene, Shape, Vector2, Vector3 } from "three"
import { SelectionBox } from "three/examples/jsm/interactive/SelectionBox"
import { OBB } from 'three/examples/jsm/math/OBB';
import { SelectionHelper } from "three/examples/jsm/interactive/SelectionHelper"
import { abs, computeAngle2d, getWorldPos, similar, similarXZ, computeDir, unique, isNaN, quickSort, angleToRadian, xyz, getMinMax, generateId, unique1, getReverseDir,getBox3Size } from "./common"
import { z3dCtrlState, zPlankType, defaultSetting, zDir, zDoorType, zNineDir, defaultSize, ableCircleTypes,rotPlankTypes } from "./zDefine"
import { addPlankPoints, getFrontPos, getPos,getBackPos } from "./common"
import  PlankNode  from "./zPlankNode"
import { ElMessage } from "element-plus"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import mitts from "@/utils/bus"
import { MeshRuler } from "./zMeshRuler"
import { CSS3DSprite } from "three/examples/jsm/renderers/CSS3DRenderer"

import {createPlank,addObb,setPlanePos} from './zParse.js'

import { AddObjectCommand } from '@/utils/commands/AddObjectCommand'
import { MultiCmdsCommand } from '@/utils/commands/MultiCmdsCommand'
import { SetPositionCommand } from '@/utils/commands/SetPositionCommand'
import { SetValueCommand } from '@/utils/commands/SetValueCommand'
import { SetMatrixCommand } from '@/utils/commands/SetMatrixCommand'
import { RemoveObjectCommand } from '@/utils/commands/RemoveObjectCommand'
import { MixerRotationCommand } from '@/utils/commands/MixerRotationCommand'

import {Box} from './zSpace'

import CabNode from './zCabNode.js'


// import {initialize,execiterations,report} from './binPack'


const ustore = {
  state:{
    showMat:false,
    matType:''
  }
}

const limitTypes = [zPlankType.SIDE_L, zPlankType.SIDE_R, zPlankType.VERTICAL_PLANK, zPlankType.LEVEL_PLANK,zPlankType.LEVEL_T,zPlankType.LEVEL_B]
const showSelectStates = [z3dCtrlState.DRAW_RECT, z3dCtrlState.DRAW_DRAWER, z3dCtrlState.DRAW_BAFFLE, z3dCtrlState.DRAW_BACK, z3dCtrlState.MUTIL_DOOR, z3dCtrlState.MUTIL_SELECT, z3dCtrlState.DRAW_BOX]
let setCount = 0
let ableAverage = false
let applyType = ''
let isDrawLine = true
let insectPoints = []


class Ctrl3D {
  z3d
  raycaster
  curSelectedNode
  curMutilSelect

  mouse
  curCamera
  state
  domElement

  _mouseMoveEvent
  _mouseDownEvnet
  _mouseUpEvent
  eventDown
  eventMove
  eventUp

  //鼠标点坐标
  mouseStart
  mouseMove
  firstMove
  mouseEnd
  validClickData
  allStartPos
  curEvent
  enabled

  mouseStartData
  mouseEndData
  mouseMoveData
  lastState // 记录上一次的点击状态

  setting
  ray
  curCab
  flag // true 则恢复上一次操作状态

  markDataArr

  helper
  selectionBox
  addNodes

  finalMarkPoint
  planeImg
  outBox

  collisionPoints
  collisionNode

  touchScale
  scaleAble

  preScale
  point

  preDrawData // 预绘制数据

  viewDir
  index
  vecsArr
  constructor(z3d) {
    this.enabled = true
    this.z3d = z3d
    this.raycaster = z3d.raycaster
    this.mouse = new Vector2()
    this.curCamera = z3d.camera
    this.domElement = this.z3d.renderer.domElement
    this.state =  z3dCtrlState.NONE // z3dCtrlState.MUTIL_SELECT 
    this.ray = new Raycaster()
    this.bindEvents()
    this.initSelection()
    this.touchScale = { dis1: 1, dis2: 1, width: 0, height: 0 }
    this.preDrawData = []
    this.viewDir = zDir.FRONT
this.checkEventListener()

  
  }

  checkEventListener(){
    const {transControl,controls} =  this.z3d
   transControl.addEventListener('dragging-changed', (e) => {

        // 结束拖拽
        if(!e.value){
          if(this.curCab.children.length==1){
          var box = new Box3().setFromObject(this.curCab);
          const newSize = getBox3Size(box)
          this.curCab.outBox = box
          this.curCab.size = newSize
          setPlanePos(this.z3d,this.curCab)
          this.curCab.updateWorldMatrix()
          }else if(this.curSelectedNode){
            var box = new Box3().setFromObject(this.curSelectedNode);
            const newSize = getBox3Size(box)
            this.curSelectedNode.size = newSize
           this.state =  this.lastState 
          }
          controls.enableRotate = true
        }else{
          controls.enableRotate = false
          this.state = z3dCtrlState.NONE
        }
    })

  }

  
  // 框选box
  initSelection() {
    let { scene, camera, renderer } = this.z3d
    this.selectionBox = new SelectionBox(this.z3d.camera, scene);
    this.createSelectionHelper()

  }

  createSelectionHelper() {
    let { renderer } = this.z3d
    this.helper = new SelectionHelper(this.selectionBox, renderer, 'selectBox');
  }
  onMouseClick() {
    //获取点击集合
    let event = this.curEvent
    let curCab = this.curCab
    if (!curCab) return
    this.restoreNode()
    let camera = this.curCamera
    //转换鼠标位置
    this.getMousePointer(event)
    //计算raycaster
    this.raycaster.setFromCamera(this.mouse, camera)

   if(this.z3d.backGroundPlane){
     let nodeData = this.getRaycasterNode(this.z3d.backGroundPlane)
    if (!nodeData) return
   }
    const { insectNode, faceNormal, clickPoint } = nodeData

    this.setNodeSelected(insectNode, false)
    if (insectNode) {


      let spaceRuler = this.z3d.spaceRuler
      spaceRuler.deleteRuler()
      spaceRuler.deleteSpaceBox()
      if (this.state == z3dCtrlState.SELECT || this.state == z3dCtrlState.NONE) {
        // spaceRuler.getRulers(insectNode)
      } else if (this.state == z3dCtrlState.SELECT_SPACE) {
        spaceRuler.getSpaceBox(insectNode, faceNormal, clickPoint)
      }

    }
  }




  /**绘制板件 */
  onDrawLine(drawData) {
    let screenSp = { x: 0, y: 0 }, screenEp = { x: 0, y: 0 }
    let clickPoint, insectNode, faceNormal
    let curCab = this.curCab


    if (!drawData) return
    let { startPos, endPos, angle, dir } = drawData

    let pos = endPos.clone().add(startPos).multiplyScalar(0.5)

    if (startPos && this.mouseStartData) {
      let { clickPoint, insectNode, faceNormal } = this.mouseStartData

      let boxs = []
      //判断起点和终点
      let validClickData = this.dealClickData(this.validClickData)
      validClickData.map(data => {
        let { clickPoint, insectNode, faceNormal } = data
        // 获取射线拾取的内空
        let box = this.z3d.spaceRuler.getSpaceBox1(insectNode, faceNormal, clickPoint)
        if (box) boxs.push(box)

      })
      let unBoxs = unique(boxs, 'size', 'position')
      let that = this
      // 绘制均分板件
      if (ableAverage) {
        this.markDataArr.map(markData => {
          let { startObj, endObj } = markData
          let pos = startObj.clickPoint.clone().add(endObj.clickPoint).multiplyScalar(0.5)
          unBoxs.map(box => {
            if (box.isOutBox) this.outBox = getMinMax(box)
            that.drawLine(angle, pos, box)
          })

        })
      } else {
        let boxs = []
        validClickData.map(data => {
          let { clickPoint, insectNode, faceNormal } = data
          let box = this.z3d.spaceRuler.getSpaceBox1(insectNode, faceNormal, clickPoint)
          if (box) boxs.push(box)


        })
        let unBoxs = unique(boxs, 'size', 'position')
        let that = this

        unBoxs.map(box => {
   
          if (box.isOutBox) this.outBox = getMinMax(box)
          that.drawLine(angle, pos, box)
        })
      }


      if (this.addNodes.length) this.addMutilCommand(this.addNodes)
   

    }
  }

  // 判断点击位置 计算柜体外包围盒
  dealClickData(clickData) {
    if(!this.mouseStartData||!this.mouseEndData)return clickData
    let { cab3d } = this.z3d
    let { min, max } = getMinMax(cab3d)
    let { clickPoint: sp } = this.mouseStartData
    let { clickPoint: ep } = this.mouseEndData
    let removePos = null
    if (sp.x < min.x && ep.x < min.x) {
      //均在左侧
      removePos = sp.x < ep.x ? sp : ep

    } else if (sp.x > max.x && ep.x > max.x) {
      removePos = sp.x > ep.x ? sp : ep
    }
    let res = clickData.filter(function (item) {
      return !similar(item.clickPoint, removePos)
    });
    return res

  }

  // 获取射线拾取到的所有有效数据
  handleAllPoints1(drawData) {
    let screenSp = { x: 0, y: 0 }, screenEp = { x: 0, y: 0 }
    let curCab = this.curCab
    let { thickness } = this.z3d
    let clickPoint, insectNode, faceNormal
    let sBox, eBox
    let flag = false

    if (!drawData) return
    let { startPos, endPos, angle, dir } = drawData

    if (startPos && endPos) {
      let normal = new Vector3()
      switch (dir) {
        case zDir.LEFT:
          normal = new Vector3(-1, 0, 0)
          break
        case zDir.RIGHT:
          normal = new Vector3(1, 0, 0)
          break
        case zDir.TOP:
          normal = new Vector3(0, 0, 1)
          break
        case zDir.BOTTOM:
          normal = new Vector3(0, 0, -1)
          break

      }

      let that = this
      this.markDataArr.map(markData => {
        let { startObj, endObj } = markData
        let oriPos = startObj.clickPoint.clone()
        let oriEnd = endObj.clickPoint.clone()
        oriPos.y = curCab.position.y - thickness * 0.5 //2d中板件深度为2
        this.ray.set(oriPos, normal)
        let intersects = this.ray.intersectObjects([curCab], true)

        intersects.map(data => {
          let insectNode = data.object.parent

          if (insectNode instanceof PlankNode && data.face && this.isOnLine(oriPos, oriEnd, data.point)) {
            let faceNormal = data.face?.normal ?? normal
            let clickPoint = data.point.round()
            this.validClickData.push({ insectNode, faceNormal, clickPoint })

          }
        })

      })


    }
  }

  getFreeRayNodes(oriPos,oriEnd,normal,maxBox){  
    let curCab = this.curCab
    this.ray.set(oriPos, normal)
    let intersects = this.ray.intersectObjects([curCab], true)

 
    let validNodes = []
    intersects.map(data => {
      let insectNode = data.object.parent??null
      let faceNormal = data.face?.normal.round()
      let clickPoint = data.point.round()
      // && this.isOnLine(oriPos, oriEnd, data.point,referLen)
      let flag = maxBox.containsPoint(clickPoint)
      if (insectNode instanceof PlankNode ||insectNode instanceof Scene ) {
        if(flag)validNodes.push({ insectNode, faceNormal, clickPoint  })

      }
    })
    return validNodes
  }

  // 阵列获取框选区域内的有效点位
  getSelectionPoints(){
    const {clickPoint:sp} = this.mouseStartData
    const {clickPoint:ep} = this.mouseEndData
    const temp = ep.clone().sub(sp).round()
    const referLen = temp.length()
    let  dir = temp.clone().normalize()
    const offSet = 60 //间隔60进行射线检测
    const computePoints = [sp,ep]
    const computeData = [this.mouseStartData,this.mouseEndData]
    let count = 0
    let lineDir = new Vector3()
    let computePos = new Vector3()
    const maxBox = this.getBoxArrange()
    // 水平方向
    switch(this.viewDir){
      case zDir.LEFT:
      case zDir.RIGHT:
        lineDir.z =Math.sign(dir.z) // 获取方向符号
      count = Math.abs(temp.y)/offSet

      const srayData = this.getFreeRayNodes(sp,ep,lineDir,maxBox)
      if(srayData)computeData.push(...srayData)
      const erayData1 = this.getFreeRayNodes(new Vector3(ep.x,ep.y,sp.z),ep,lineDir,maxBox)
      if(erayData1)computeData.push(...erayData1)

      for(let i = 1; i<count;i++){
        computePos = sp.clone().add(new Vector3(0,i*offSet*dir.y,0)).round()
        computePoints.push(computePos)
        const rayData = this.getFreeRayNodes(computePos,ep,lineDir,maxBox)
        if(rayData)computeData.push(...rayData)
      }

       
        break

      default:
        count = Math.abs(temp.x)/offSet
        if([zDir.TOP,zDir.BOTTOM].includes(this.viewDir)) lineDir.y = Math.sign(dir.y) 
        else  lineDir.z = Math.sign(dir.z) 

        const rayData = this.getFreeRayNodes(sp,ep,lineDir,maxBox)
        if(rayData)computeData.push(...rayData)
        const rayData1 = this.getFreeRayNodes(new Vector3(ep.x,ep.y,sp.z),ep,lineDir,maxBox)
        if(rayData1)computeData.push(...rayData1)

        for(let i = 1; i<count;i++){
          computePos = sp.clone().add(new Vector3(i*offSet*dir.x,dir.y*10,0)).round()
          const rayData = this.getFreeRayNodes(computePos,ep,lineDir,maxBox)
          computePoints.push(computePos)
          if(rayData)computeData.push(...rayData)
       
        }
  


    }


    return computeData
  }

  getBoxArrange(){
    let { clickPoint:sp, insectNode:snode, faceNormal:snormal } = this.mouseStartData
    let { clickPoint:ep, insectNode:enode, faceNormal:enormal } = this.mouseEndData
    // 起点拾取的内空
    let sbox = this.z3d.spaceRuler.getSpaceBox1(snode, snormal, sp)
    let ebox = this.z3d.spaceRuler.getSpaceBox1(enode, enormal, ep)
    const boxPoints = []
    if(sbox){
      let sbox3 = new Box3().setFromObject(sbox)
      boxPoints.push(sbox3.min,sbox3.max)
    }else{
      boxPoints.push(sp)
    }

    if(ebox){
      let ebox3 = new Box3().setFromObject(ebox)
      boxPoints.push(ebox3.min,ebox3.max)
    }else{
      boxPoints.push(ep)
    }

    let max = new Vector3(), min = new Vector3()
    for (let point of boxPoints) {
      max = max ? max.max(point) : point.clone()
      min = min ? min.min(point) : point.clone()
    }
    
    const box3 = new Box3(min, max)
    return box3


  }

  /**判断点在线上 */
  isOnLine(sp, ep, pos,lineLen) {
    let startPos = sp.clone()
    let endPos = ep.clone()
    let checkPoint = pos.clone()
    startPos.y = endPos.y = checkPoint.y = 0
    if (checkPoint) {
       lineLen = endPos.clone().sub(startPos).length()
      let checkLen = checkPoint.clone().sub(startPos).length()
      return lineLen > checkLen
    } else {
      return false
    }

  }
  drawLine(angle, clickCenter, box) {
    let { cab3d } = this.z3d
    let { thickness } = this.z3d
    let newPos = new Vector3()
    let plankNode, plankNode2d
    let boxWpos = getWorldPos(box).clone()
    const size = new Vector3()

    let typeId = -1
    if (angle > 45) {
      //竖板
      newPos = new Vector3(clickCenter.x, boxWpos.y, boxWpos.z).sub(cab3d.position);
      if (isNaN(newPos)) return
      typeId = zPlankType.VERTICAL_PLANK;
      size.set(thickness, box.size.y, box.size.z)
      plankNode = createPlank(size, newPos, typeId, '竖板','')

    } else {
      //横板
      newPos = new Vector3(boxWpos.x, boxWpos.y, clickCenter.z).sub(cab3d.position);
      typeId = zPlankType.LEVEL_PLANK;
      size.set(box.size.x, box.size.y, thickness)
      plankNode = createPlank(size, newPos, typeId, '层板','')
    }

    let matRot = rotPlankTypes.includes(typeId) ? 0 : Math.PI / 2;
    plankNode.setMatRot(matRot)


    this.z3d.spaceRuler.deleteSpaceBox()
    cab3d.add(plankNode)

    this.addNodes.push(plankNode)


  }

  drawPreLine(angle, clickCenter, box) {
    let { cab3d,scene } = this.z3d
    let { thickness } = this.z3d
    let newPos = new Vector3()
    let preBox
    let boxWpos = getWorldPos(box).clone()
    let size = new Vector3()
    const boxSize = box.size

    let typeId = -1
    let name = ''
    if (angle > 45) {
      //竖板
      // newPos = new Vector3(clickCenter.x, boxWpos.y, boxWpos.z).sub(cab3d.position);
      if (isNaN(newPos)) return
      typeId = zPlankType.VERTICAL_PLANK;
      // size.set(thickness, box.size.y, box.size.z)
      name = '竖板'

    
    } else {
      //横板
      newPos = new Vector3(boxWpos.x, boxWpos.y, clickCenter.z).sub(cab3d.position);
      typeId = zPlankType.LEVEL_PLANK;
      size.set(box.size.x, box.size.y, thickness)
      name = '层版'
      
    }


    ({newPos,size} = this.getInfoByViewDir(typeId,boxWpos,clickCenter,boxSize))
    preBox =   new Box()
    preBox.createBox(size)
    preBox.box.material.visible = true
  preBox.position.copy(newPos)
  this.preDrawData.push({ preBox, size, newPos, typeId,name })


    cab3d.add(preBox)

  }


  drawPreStrip(angle, clickCenter, box) {
    let { cab3d,scene } = this.z3d
    let { thickness } = this.z3d
  
    let preBox
    let boxWpos = getWorldPos(box).clone()

    const boxSize = box.size

    let typeId = -1
    let name = ''
    if(angle < 45)  {

          typeId = zPlankType.STRIP;
          name = '拉条'
          
         let {newPos,size} = this.getInfoByViewDir(typeId,boxWpos,clickCenter,boxSize)
          preBox =   new Box()
          preBox.createBox(size)
          preBox.box.material.visible = true
        preBox.position.copy(newPos)
        this.preDrawData.push({ preBox, size, newPos, typeId,name })
      
      
          cab3d.add(preBox)
        }




  }

  drawPreSeal(angle, clickCenter, box,isOut) {
    let { cab3d,scene } = this.z3d
    let { thickness } = this.z3d
  
    let preBox
    let boxWpos = getWorldPos(box).clone()

    const boxSize = box.size

  
    let typeId = -1
    if (angle > 45) {
      //侧封板
      typeId = zPlankType.SEAL_V;
  

    
    } else {
      //横封板
    
      typeId = zPlankType.typeId = zPlankType.SEAL_H;;
   
      
    }


    let {newPos,size,type,name} = isOut?this.getSealByViewDir(typeId,boxWpos,clickCenter,boxSize):this.getInfoByViewDir(typeId,boxWpos,clickCenter,boxSize)
    preBox =   new Box()
    preBox.createBox(size)
    preBox.box.material.visible = true
  preBox.position.copy(newPos)
  this.preDrawData.push({ preBox, size, newPos, typeId:type,name })


    cab3d.add(preBox)





  }



  drawPreOutLine(box) {
    let { cab3d,scene } = this.z3d
    const {size,position,preType} = box
    let name = ''
    let typeId = preType
    switch(preType){
      case zPlankType.LEVEL_T:
        name = '顶板'
        break
      case zPlankType.LEVEL_B:
        name = '底板'
        break
      case zPlankType.SIDE_L:
        name = '左侧板'
        break
      case zPlankType.SIDE_R:
        name = '右侧板'
        break

    }
   
    // obb
    const halfSize = size.clone().multiplyScalar(0.5)
    box.box.geometry.userData.obb =new OBB(position,halfSize)
    box.box.userData.obb = new OBB();
    
  this.preDrawData.push({ preBox:box, size, newPos:position, typeId,name })


    cab3d.add(box)


  }

  // 不同绘制视角下获取绘制数据
  getInfoByViewDir(plankType,boxWpos,clickCenter,boxSize){
    const {x:sx,y:sy,z:sz} = boxSize
    const {x:px,y:py,z:pz} = boxWpos
    let { cab3d,scene,thickness } = this.z3d
    let pos = new Vector3()
    let size = new Vector3()
    let type = 0
    const sealW = 50
    let name = ''
    let { left, right, front, back,top,bottom } = getPos(boxWpos, boxSize, null)
    switch(plankType){
      case zPlankType.LEVEL_PLANK:
        switch(this.viewDir){
          case zDir.TOP:
            case zDir.BOTTOM:
            size.set(sx,thickness,sz)
            pos.set(boxWpos.x, clickCenter.y, boxWpos.z).sub(cab3d.position);
            break
          default:
            size.set(sx,sy, thickness)
            pos.set(px, py, clickCenter.z).sub(cab3d.position);
        }
        break
      case zPlankType.VERTICAL_PLANK:
        switch(this.viewDir){
          case zDir.LEFT:
            case zDir.RIGHT:
            size.set(sx,thickness,sz)
            pos.set(boxWpos.x, clickCenter.y, boxWpos.z).sub(cab3d.position);
            break
          default:
            size.set(thickness,sy, sz)
            pos.set(clickCenter.x, boxWpos.y, boxWpos.z).sub(cab3d.position);
        }
        break

      case zPlankType.DOOR:
        switch(this.viewDir){
          case zDir.LEFT:
            size.set(thickness,sy,sz)
            pos.set(px-sx*0.5+thickness * 0.5, clickCenter.y, pz).sub(cab3d.position);
            break
          case zDir.RIGHT:
            size.set(thickness,sy,sz)
            pos.set(px+sx*0.5-thickness * 0.5, clickCenter.y, pz).sub(cab3d.position);
            break
          case zDir.TOP:
            size.set(sx,sy,thickness)
            pos.set(px, clickCenter.y, pz+sz*0.5+thickness * 0.5).sub(cab3d.position);
            break
          case zDir.BOTTOM:
            size.set(sx,sy,thickness)
            pos.set(px, clickCenter.y, pz-sz*0.5-thickness * 0.5).sub(cab3d.position);
            break
          case zDir.BACK:
            size.set(sx,thickness, sz)
            pos.set(px, cab3d.size.y * 0.5 + thickness * 0.5, clickCenter.z).sub(cab3d.position);
            break
          default:
            size.set(sx,thickness, sz)
            pos.set(px, -cab3d.size.y * 0.5 - thickness * 0.5, clickCenter.z).sub(cab3d.position);
        }
        break
      case zPlankType.BACK_PLANK:
        thickness = 9
        switch(this.viewDir){
          case zDir.LEFT:
            size.set(thickness,sy,sz)
            pos.set(px+sx*0.5-thickness * 0.5-18, clickCenter.y, pz).sub(cab3d.position);
            break
          case zDir.RIGHT:
            size.set(thickness,sy,sz)
            pos.set(px-sx*0.5+thickness * 0.5+18, clickCenter.y, pz).sub(cab3d.position);
            break
          case zDir.TOP:
            size.set(sx,sy,thickness)
            pos.set(px, clickCenter.y, pz-sz*0.5+thickness * 0.5+18).sub(cab3d.position);
            break
          case zDir.BOTTOM:
            size.set(sx,sy,thickness)
            pos.set(px, clickCenter.y, pz+sz*0.5-thickness * 0.5-18).sub(cab3d.position);
            break
          case zDir.BACK:
            size.set(sx,thickness, sz)
            pos.set(px, -cab3d.size.y * 0.5 + thickness * 0.5+18, clickCenter.z).sub(cab3d.position);
            break
          default:
            size.set(sx,thickness, sz)
            pos.set(px, cab3d.size.y * 0.5 - thickness * 0.5-18, clickCenter.z).sub(cab3d.position);
        }
        break
      case zPlankType.STRIP:
        const stripH = 100
        const offDis = 36
        switch(this.viewDir){
          case zDir.LEFT:
            size.set(thickness,sy,stripH)
            pos.set(px+sx*0.5-offDis, py, clickCenter.z).sub(cab3d.position);
            break
          case zDir.RIGHT:
            size.set(thickness,sy,stripH)
            pos.set(px-sx*0.5+offDis, py, clickCenter.z).sub(cab3d.position);
            break
          case zDir.TOP:
            size.set(sx,stripH,thickness)
            pos.set(px, clickCenter.y, pz-sz*0.5+offDis).sub(cab3d.position);
            break
          case zDir.BOTTOM:
            size.set(sx,stripH,thickness)
            pos.set(px, clickCenter.y, pz+sz*0.5-offDis).sub(cab3d.position);
            break
          case zDir.BACK:
            size.set(sx, thickness,stripH)
            pos.set(px, py-sy*0.5+offDis, clickCenter.z).sub(cab3d.position);
            break
          default:
            size.set(sx, thickness,stripH)
            pos.set(px, py+sy*0.5-offDis, clickCenter.z).sub(cab3d.position);
        }
        break
       
      case zPlankType.SEAL_V:
      
       
        switch(this.viewDir){
          case zDir.LEFT:

            size.set(thickness,sealW,sz)
            // 左封板
            if(clickCenter.y>0)
            {
              pos.set(left.x+thickness * 0.5, left.y+sy*0.5-sealW*0.5, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
            // 右封板
            else {
               pos.set(left.x+thickness * 0.5, left.y-sy*0.5+sealW*0.5, pz).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
              }
               break
          case zDir.RIGHT:
            size.set(thickness,sealW,sz)
            // 左封板
            if(clickCenter.y<0){
              pos.set(right.x-thickness * 0.5, right.y-sy*0.5+sealW*0.5, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
               } 
                 // 右封板
            else {
               pos.set(right.x-thickness * 0.5, right.y+sy*0.5-sealW*0.5, pz).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
              }
               break
          case zDir.TOP:
            size.set(sealW,sy,thickness)
            // 左封板
            if(clickCenter.x<0){
              pos.set(top.x-sx*0.5+sealW * 0.5, py, top.z-thickness*0.5).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
              // 右封板
            else {
              pos.set(top.x+sx*0.5-sealW * 0.5, py, top.z-thickness*0.5).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
              }
               break
          case zDir.BOTTOM:
            size.set(sealW,sy,thickness)
            // 左封板
            if(clickCenter.x<0){
              pos.set(bottom.x-sx*0.5+sealW * 0.5, py, bottom.z-thickness*0.5).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
              // 右封板
            else {
              pos.set(bottom.x+sx*0.5-sealW * 0.5, py, bottom.z-thickness*0.5).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
                }
                    break
          case zDir.BACK:
            size.set(sealW,thickness,sz)
            // 左封板
            if(clickCenter.x<0){
              pos.set(back.x-sx*0.5+sealW * 0.5+thickness*0.5, back.y, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
              // 右封板
            else { pos.set(back.x+sx*0.5-sealW * 0.5, back.y+thickness*0.5, pz).sub(cab3d.position);
              type = zPlankType.SEAL_R
              name = '右封板'
            }
              break
          default:
            size.set(sealW,thickness,sz)
            // 左封板
            if(clickCenter.x<0){
              pos.set(front.x-sx*0.5+sealW * 0.5, front.y, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
               }
                  // 右封板
            else {
               pos.set(front.x+sx*0.5-sealW * 0.5, front.y+thickness*0.5, pz).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
            }
               break
        }
        break

        case zPlankType.SEAL_H:
        

          switch(this.viewDir){
            case zDir.LEFT:
  
              size.set(thickness,sy,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(left.x+thickness * 0.5, py, top.z-sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                 pos.set(left.x+thickness * 0.5, py, bottom.z+sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            case zDir.RIGHT:
              size.set(thickness,sy,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(right.x-thickness * 0.5, py, top.z-sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                 pos.set(right.x-thickness * 0.5, py, bottom.z+sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            case zDir.TOP:
              size.set(sx,sealW,thickness)
              // 顶封板
              if(clickCenter.y>0)
              {
                pos.set(px, top.y+sy*0.5-sealW*0.5, top.z-thickness*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, top.y-sy*0.5+sealW*0.5, top.z-thickness*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            case zDir.BOTTOM:
              size.set(sx,sealW,thickness)
              // 顶封板
              if(clickCenter.y>0)
              {
                pos.set(px, bottom.y+sy*0.5-sealW*0.5, bottom.z+thickness*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, bottom.y-sy*0.5+sealW*0.5, bottom.z+thickness*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
                     
            case zDir.BACK:
              size.set(sx,thickness,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(px, back.y-thickness*0.5, top.z-sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, back.y-thickness*0.5, bottom.z+sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            default:
              size.set(sx,thickness,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(px, front.y+thickness*0.5, top.z-sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, front.y+thickness*0.5, bottom.z+sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
              
                 break
          }
          break
      }

    return {newPos:pos,size,type,name}
   
  }


  getSealByViewDir(plankType,boxWpos,clickCenter,boxSize){
    let { cab3d,scene,thickness } = this.z3d
    const {x:sx,y:sy,z:sz} = cab3d.size
    const {x:px,y:py,z:pz} = cab3d.position

    let pos = new Vector3()
    let size = new Vector3()
    let type = 0
    const sealW = 50
    let name = ''
    let { left, right, front, back,top,bottom } = getPos(cab3d.position, cab3d.size, null)
    switch(plankType){

      case zPlankType.SEAL_V:
      
       
        switch(this.viewDir){
          case zDir.LEFT:

            size.set(thickness,sealW,sz)
            // 左封板
            if(clickCenter.y>0)
            {
              pos.set(left.x+thickness * 0.5, left.y+sy*0.5+sealW*0.5, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
            // 右封板
            else {
               pos.set(left.x+thickness * 0.5, left.y-sy*0.5-sealW*0.5, pz).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
              }
               break
          case zDir.RIGHT:
            size.set(thickness,sealW,sz)
            // 左封板
            if(clickCenter.y<0){
              pos.set(right.x-thickness * 0.5, right.y-sy*0.5-sealW*0.5, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
               } 
                 // 右封板
            else {
               pos.set(right.x-thickness * 0.5, right.y+sy*0.5+sealW*0.5, pz).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
              }
               break
          case zDir.TOP:
            size.set(sealW,sy,thickness)
            // 左封板
            if(clickCenter.x<0){
              pos.set(top.x-sx*0.5-sealW * 0.5, py, top.z-thickness*0.5).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
              // 右封板
            else {
              pos.set(top.x+sx*0.5+sealW * 0.5, py, top.z-thickness*0.5).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
              }
               break
          case zDir.BOTTOM:
            size.set(sealW,sy,thickness)
            // 左封板
            if(clickCenter.x<0){
              pos.set(bottom.x-sx*0.5-sealW * 0.5, py, bottom.z-thickness*0.5).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
              // 右封板
            else {
              pos.set(bottom.x+sx*0.5+sealW * 0.5, py, bottom.z-thickness*0.5).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
                }
                    break
          case zDir.BACK:
            size.set(sealW,thickness,sz)
            // 左封板
            if(clickCenter.x<0){
              pos.set(back.x-sx*0.5-sealW * 0.5+thickness*0.5, back.y, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
            }
              // 右封板
            else { pos.set(back.x+sx*0.5+sealW * 0.5, back.y+thickness*0.5, pz).sub(cab3d.position);
              type = zPlankType.SEAL_R
              name = '右封板'
            }
              break
          default:
            size.set(sealW,thickness,sz)
            // 左封板
            if(clickCenter.x<0){
              pos.set(front.x-sx*0.5-sealW * 0.5, front.y, pz).sub(cab3d.position);
              type = zPlankType.SEAL_L
              name = '左封板'
               }
                  // 右封板
            else {
               pos.set(front.x+sx*0.5+sealW * 0.5, front.y+thickness*0.5, pz).sub(cab3d.position);
               type = zPlankType.SEAL_R
               name = '右封板'
            }
               break
        }
        break

        case zPlankType.SEAL_H:
        

          switch(this.viewDir){
            case zDir.LEFT:
  
              size.set(thickness,sy,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(left.x+thickness * 0.5, py, top.z+sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                 pos.set(left.x+thickness * 0.5, py, bottom.z-sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            case zDir.RIGHT:
              size.set(thickness,sy,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(right.x-thickness * 0.5, py, top.z+sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                 pos.set(right.x-thickness * 0.5, py, bottom.z-sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            case zDir.TOP:
              size.set(sx,sealW,thickness)
              // 顶封板
              if(clickCenter.y>0)
              {
                pos.set(px, top.y+sy*0.5+sealW*0.5, top.z-thickness*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, top.y-sy*0.5-sealW*0.5, top.z-thickness*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            case zDir.BOTTOM:
              size.set(sx,sealW,thickness)
              // 顶封板
              if(clickCenter.y>0)
              {
                pos.set(px, bottom.y+sy*0.5+sealW*0.5, bottom.z+thickness*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, bottom.y-sy*0.5-sealW*0.5, bottom.z+thickness*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
                     
            case zDir.BACK:
              size.set(sx,thickness,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(px, back.y-thickness*0.5, top.z+sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, back.y-thickness*0.5, bottom.z-sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
                 break
            default:
              size.set(sx,thickness,sealW)
              // 顶封板
              if(clickCenter.z>0)
              {
                pos.set(px, front.y+thickness*0.5, top.z+sealW*0.5).sub(cab3d.position);
                type = zPlankType.SEAL_T
                name = '顶封板'
              }
              // 底封板
              else {
                pos.set(px, front.y+thickness*0.5, bottom.z-sealW*0.5).sub(cab3d.position);
                 type = zPlankType.SEAL_B
                 name = '底封板'
                }
              
                 break
          }
          break
      }

    return {newPos:pos,size,type,name}
   
  }
  // 更新柜体包围盒
  updateCabBox() {
    if (!this.outBox) return
    let { cab2d, cab3d } = this.z3d
    let { min: sp, max: ep } = this.outBox
    if (!cab3d.outBox.containsPoint(sp) || !cab3d.outBox.containsPoint(ep)) {

      cab3d.outBox.expandByPoint(sp).getCenter(new Vector3())
      cab3d.outBox.expandByPoint(ep).getCenter(new Vector3())
      this.deleteCabRuler()
      this.displayCabRuler()
    }

  }

  // 更新均分板件标记点
  averageAddPoints() {
    let clickPoint, faceNormal

    let { cab3d, cab2d, thickness } = this.z3d
    let curCab = this.curCab
    if (!this.eventDown) return

    let curMouseEndData = this.mouseMoveData
    let drawData = this.getDrawData(this.mouseStartData, curMouseEndData, this.eventMove)

    if (!drawData || this.state != z3dCtrlState.DRAW_LINE) return
    let { insectNode: clickNode } = this.mouseStartData
    let { startPos, endPos, angle, dir } = drawData

    if (!ableAverage) {
      this.toUpdateMarkData(0, 'line', this.mouseStartData, curMouseEndData)
      return
    }

    let box = null, wpos = new Vector3()
    box = this.z3d.spaceRuler.getSpaceBox1(clickNode, faceNormal, startPos.clone())
    if (!box) return
    box.visible = false
    curCab.add(box)
    wpos = getWorldPos(box)
    let { left, bottom } = getPos(wpos, box.size, cab3d.rotation)


    let { x: width, z: height } = box.size
    let count = 0
    let gapDis = 200

    if (angle > 45) {
      //竖板
      count = Math.round(width / 200) > 1 ? Math.round(width / 200) : 2
      count = setCount > 0 ? setCount : count
      gapDis = (width - (count - 1) * thickness) / (count)
      for (let i = 1; i < count; i++) {
        let ppos = left.clone().add(new Vector3(i * gapDis + (2 * i - 1) * thickness * 0.5, 0, 0))
        let markPos = ppos.clone().add(new Vector3(0, -cab3d.size.y * 0.5, startPos.z))
        markPos.z = startPos.z
        if (ableAverage) {
          let point = this.z3d.markPoint.createMarkPoint('plankMark', markPos)
          this.allStartPos.push(markPos)
          this.z3d.scene.add(point)
        }

        let startObj = { ...this.mouseStartData }
        let endObj = { ...curMouseEndData }
        startObj.clickPoint = markPos.clone()
        endObj.clickPoint = markPos.clone()
        endObj.clickPoint.z = curMouseEndData.clickPoint.z
        this.toUpdateMarkData(i, i, startObj, endObj)
      }
    }
    else {
      height = box.size.z
      count = Math.round(height / 200) > 1 ? Math.round(height / 200) : 2
      count = setCount > 0 ? setCount : count
      gapDis = (height - (count - 1) * thickness) / (count)


      for (let i = 1; i < count; i++) {
        let ppos = bottom.clone().add(new Vector3(0, 0, i * gapDis + (2 * i - 1) * thickness * 0.5))

        let markPos = ppos.clone().add(new Vector3(0, -cab3d.size.y * 0.5, 0))
        markPos.x = startPos.x
        if (ableAverage) {
          let point = this.z3d.markPoint.createMarkPoint('plankMark', markPos)
          this.z3d.scene.add(point)
          this.allStartPos.push(markPos)
        }

        let startObj = { ...this.mouseStartData }
        let endObj = { ...curMouseEndData }
        startObj.clickPoint = markPos.clone()
        endObj.clickPoint = markPos.clone()
        endObj.clickPoint.x = curMouseEndData.clickPoint.x

        this.toUpdateMarkData(i, i, startObj, endObj)

      }

    }

  }

  toUpdateMarkData(referId, lineId, startData, endData) {
    let sameData = this.markDataArr.find((element) => element.id == referId)
    if (!sameData) {
      this.markDataArr.push({ id: referId, startObj: startData, endObj: endData })
      let referLine = this.z3d.markPoint.createLine(lineId, startData.clickPoint.clone(), endData.clickPoint.clone())
      if (referLine) this.z3d.scene.add(referLine)
    }
    else {
      if (!similar(sameData.startObj?.clickPoint, startData.clickPoint)) sameData.startObj = startData
      sameData.endObj = endData
      this.z3d.markPoint.updateLine(lineId, startData?.clickPoint.clone(), endData.clickPoint.clone())
    }

  }


  // 多选
  multilSelect(isMutilDoor) {
    this.restoreSelectNode()

    if (this.mouseEnd) {

      const allSelected = this.selectionBox.select();
      this.getPlankByMesh(allSelected, isMutilDoor)

    }
  }

  getPlankByMesh(meshes, isMutilDoor) {
    let vecsArr = []
    const tempArr = []
    let mutilSelected = []
    let doorTypes = [zPlankType.DOOR, zPlankType.DRAWER_DOOR]
    meshes.map(mesh => {
      let plank = mesh.parent
      let noAble = !(plank instanceof PlankNode)
      if (noAble||tempArr.includes(plank)) return

      tempArr.push(plank)
     
        this.setNodeSelected(plank, true)
        mutilSelected.push(plank)
        vecsArr.push(plank.vec2Arr)

    })
    this.vecsArr =vecsArr
    this.curMutilSelect = mutilSelected
    return mutilSelected
  }

  /*获取最大最小坐标*/
  getMinVector(vectorArr) {
    let min = vectorArr[0].clone()
    let max = vectorArr[0].clone()
    vectorArr.map(v3 => {
      min.x = v3.x < min.x ? v3.x : min.x
      min.y = v3.y < min.y ? v3.y : min.y
      min.z = v3.z < min.z ? v3.z : min.z

      max.x = v3.x > max.x ? v3.x : max.x
      max.y = v3.y > max.y ? v3.y : max.y
      max.z = v3.z > max.z ? v3.z : max.z
    })
    if (min && max) return { min, max }


  }



  customDraw(mouse, outsideAble = false) {

    this.mouse = mouse
    let curCab = this.curCab
    if (!curCab) return
    this.raycaster.setFromCamera(this.mouse, this.curCamera)

    let spaceRuler = this.z3d.spaceRuler
    spaceRuler.deleteRuler()
    spaceRuler.deleteSpaceBox()


    return this.getRaycasterNode(curCab)
  }



  /**获取鼠标坐标 */
  getMousePointer(event) {
    let canvas = this.z3d.renderer.domElement
    let getBoundingClientRect = canvas.getBoundingClientRect()
    // 屏幕坐标转标准设备坐标
    this.mouse.x = ((event.clientX - getBoundingClientRect.left) / canvas.offsetWidth) * 2 - 1;// 标准设备横坐标
    this.mouse.y = -((event.clientY - getBoundingClientRect.top) / canvas.offsetHeight) * 2 + 1;// 标准设备纵坐标
    return this.mouse

  }



  /*还原鼠标选中状态*/
  restoreNode() {
    if (!this.curSelectedNode) return
    const {transControl} = this.z3d
    transControl.detach()
    transControl.reset()
   
    if (this.curSelectedNode.edgeMesh?.material) this.curSelectedNode.edgeMesh.material.color.set(0x808080)
    if (this.curSelectedNode.planeBox) {
      this.curSelectedNode.planeBox.visible = false
    }
    this.curSelectedNode = null

  }
  setNodeSelected(node, isMutilSelect) {
   
    if (!node) return
    const {transControl,cab3d} = this.z3d
    let spaceRuler = this.z3d.spaceRuler
    spaceRuler.deleteRuler()
    if (!isMutilSelect) {
      // if (limitTypes.includes(node.plankType)) spaceRuler.getRulers(node)
      this.curSelectedNode = node
     if(node.nodeType==1){
      transControl.showZ = true
      transControl.showX = true
     }
     else if(zPlankType.LEVEL_PLANK ==node.plankType){
        transControl.showZ = false
        transControl.showX = true
        // transControl.attach(node)
      }
      else if(zPlankType.VERTICAL_PLANK ==node.plankType){
        transControl.showX = false
        transControl.showZ = true
        // transControl.attach(node)
      }
    
    }

    if (node.edgeMesh?.material) {
      node.edgeMesh.material.visible = true
      node.edgeMesh?.material.color.set(0xcccc)
    } else if (node.planeBox) {
      node.planeBox.visible = true
    }

  }

  toEmitNode(node) {
   
    this.curSelectedNode = node
  }
  restoreSelectNode() {
    let { controls } = this.z3d
    controls.enableRotate = false
    // dragControls.deactivate()
    this.curMutilSelect?.map(node => {
      node.edgeMesh.material.color.set(0x808080)
    })
    this.lastState = this.state
  }



  /*射线检测选中mesh*/
  getRaycasterNode(cab) {
    const {backGroundPlane,groundPlane,sidePlane} = this.z3d
    let plane 
    switch(this.viewDir){
      case zDir.TOP:
      case zDir.BOTTOM:
        plane = groundPlane
        break
      case zDir.LEFT:
      case zDir.RIGHT:
        plane = sidePlane
        break
      default:
        plane = backGroundPlane

    
    }

    let intersects = this.raycaster.intersectObjects([cab,plane], true)
    for (let i = 0; i < intersects.length; i++) {
      let intersect = intersects[i]
      let insectNode = intersect.object.parent
      let faceNormal = intersect.face?.normal
      let clickPoint = intersect.point.round()

    
      if (insectNode instanceof PlankNode ) {
        return { insectNode, faceNormal, clickPoint }
      } else if (insectNode instanceof Scene) {
        return { insectNode: null, faceNormal, clickPoint }
      }

    }
  }

  /**删除指定node */
  toDeleteNode(curNode) {
    let { cab2d, cab3d ,transControl} = this.z3d
    transControl.detach()
    switch (this.state) {
      case z3dCtrlState.MUTIL_SELECT:
        let mutilNodes = this.curMutilSelect
        // for(let i= mutilNodes.length-1; i>-1; i--){
        //   this.deleteNode(mutilNodes[i])
        // }
        if (mutilNodes) this.addDeleteCommand(mutilNodes)
        break
      case z3dCtrlState.MUTIL_DOOR:
        let mutilDoors = this.curMutilSelect
        // for(let i= mutilDoors.length-1; i>-1; i--){
        //   this.deleteNode(mutilDoors[i])
        // }
        if (mutilDoors) this.addDeleteCommand(mutilDoors)
        break
      default:
        curNode = curNode ?? this.curSelectedNode
        if (!curNode) {
          ElMessage({
            showClose: false,
            message: '请选择板件',
            duration: 1000

          })
          return
        }
        this.addDeleteCommand([curNode])

 

        break
    }

  }

  deleteNode(curNode) {
    if (!curNode || !curNode.visible) return
    let {  cab3d } = this.z3d
    let is3d = this.z3d.is3D
    let cid = curNode.cid
    let cabNode =  cab3d
    this.removeChildNode(curNode)
    cabNode.remove(curNode)
    let flag = curNode.plankType == zPlankType.DOOR

    if (flag) this.deleteDoorGroup(cid)


    let spaceRuler = this.z3d.spaceRuler
    spaceRuler.deleteRuler()
  }

  deleteDoorGroup(cid) {
    let { cab3d, cab2d } = this.z3d


    let doorGroups3d = cab3d.children.filter(n => n.name == cid)
    for (let i = doorGroups3d.length - 1; i >= 0; i--) {
      cab3d.remove(doorGroups3d[i])
    }

  }
  /**清空 */
  clear() {
    let { scene, cab3d, markPoint,transControl } = this.z3d
    let spaceRuler = this.z3d.spaceRuler
    transControl.detach()
    if ( !cab3d) return
    let cabs = [ cab3d]
    this.addDeleteCommand(cabs)
    this.curSelectedNode = null
    spaceRuler.deleteRuler()
    markPoint.deleteMark()

    this.state = z3dCtrlState.NONE


  }

  removeChildNode(node) {
    let that = this
    node.traverse(item => {
      if (item.type === 'Mesh' || item.type === 'Line' || item.type === 'LineSegments') {
        that.disposMesh(item)
      }
    })

    let len = node.children.length
    for (let i = len - 1; i >= 0; i--) {
      node.remove(node.children[i])
    }
  }

  disposMesh(mesh) {
    mesh.geometry.dispose()
    if (mesh.material.dispose) mesh.material.dispose()
  }

  //获取鼠标点击偏移后的坐标
  getClickPos(clickPos, oriFaceNormal) {
    let faceNormal = oriFaceNormal.clone()
    if (faceNormal && similar(faceNormal, new Vector3(0, 0, 1))) {
      clickPos.add(new Vector3(0, 0, 10))
    }
    else if (faceNormal && similar(faceNormal, new Vector3(0, 0, -1))) {
      clickPos.add(new Vector3(0, 0, -10))
    }
    else if (faceNormal && similar(faceNormal, new Vector3(1, 0, 0))) {
      clickPos.add(new Vector3(10, 0, 0))
    }
    else if (faceNormal && similar(faceNormal, new Vector3(-1, 0, 0))) {
      clickPos.add(new Vector3(-10, 0, 0))
    }
    else if (faceNormal && similar(faceNormal, new Vector3(0, 1, 0))) {
      clickPos.add(new Vector3(0, 10, 0))
    }
    else if (faceNormal && similar(faceNormal, new Vector3(0, -1, 0))) {
      clickPos.add(new Vector3(0, -10, 0))
    }
    else {
      clickPos.add(new Vector3(10, 10, 10))
    }

    return clickPos
  }

  bindEvents() {
    this.validClickData = []
    this._mouseMoveEvent = this._mouseMove.bind(this)
    this.domElement.addEventListener('pointermove', this._mouseMoveEvent, false)
    this._mouseDownEvnet = this._mouseDown.bind(this)
    this.domElement.addEventListener('pointerdown', this._mouseDownEvnet, false)
    this._mouseUpEvent = this._mouseUp.bind(this)
    this.domElement.addEventListener('pointerup', this._mouseUpEvent, false)

    let that = this
    this.domElement.addEventListener('touchstart', function (event) {
      event.preventDefault()
      var touches = event.touches;
      if (touches.length == 1 && that.flag) {
        that.flag = false
        that.state = that.lastState
      }




      if (touches.length >= 2) {

        that.state = z3dCtrlState.NONE

          that.scaleAble = false
          that.z3d.controls.enableZoom = true;
          that.flag = true
        
      }
    }, false);

    this.domElement.addEventListener('touchmove', function (event) {
      var touches = event.touches;

      if (that.scaleAble && touches.length >= 2) {

        that.touchScale.dis2 = that.getDistance(touches)

        let scaleVal = that.touchScale.dis2 / that.touchScale.dis1
        scaleVal = Math.floor(scaleVal * 100) / 100;
        if (scaleVal > 0.01) {
          that.curSelectedNode.plane.scale.set(scaleVal, scaleVal, 1)
          that.curSelectedNode.planeBox.scale.set(scaleVal, scaleVal, 1)

        }

      }
    }, false);

    this.domElement.addEventListener('touchend', function (event) {
      var touches = event.touches;

      if (that.scaleAble) {
    
        let scaleVal = that.touchScale.dis2 / that.touchScale.dis1
        scaleVal = Math.floor(scaleVal * 100) / 100;
        if (scaleVal > 0.01) {
          that.curSelectedNode.plane.scale.set(scaleVal, scaleVal, 1)
          that.curSelectedNode.planeBox.scale.set(scaleVal, scaleVal, 1)
          const { width, height } = that.curSelectedNode
          that.curSelectedNode.width = Number((width * scaleVal).toFixed(2))
          that.curSelectedNode.height = Number((height * scaleVal).toFixed(2))
          that.scaleAble = false
        }

      }
    }, false);


    document.addEventListener('dragend', (e) => {
      e.preventDefault()
      let mouseClick = that.getMousePointer(e)//鼠标点
      let data = that.customDraw(mouseClick)
      if (!ustore.state.showMat) return
      if (data) {
        //@ts-ignore
        let matUrl = e.target.src
        let { cab3d, editor } = that.z3d
        if (!cab3d) return
      
        else {
          let ableRepeat = applyType == 'matPlank' ? true : false
          cab3d.applyMaterial(matUrl, null, ustore.state.matType, ableRepeat)
        }

      }
    });


  }

  getDistance(touches) {
    let px1 = touches[0].pageX;
    let py1 = touches[0].pageY;
    let px2 = touches[1].pageX;
    let py2 = touches[1].pageY;
    let dis = Math.hypot(px2 - px1, py2 - py1);
    return Math.floor(dis * 100) / 100;
  };






  loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        resolve(img)
      };
      img.onerror = (err) => reject(err);
      img.src = src;
    });
  }
  _unbindEvent() {
    this.domElement.removeEventListener('pointermove', this._mouseMoveEvent, false)
    this.domElement.removeEventListener('pointerdown', this._mouseDownEvnet, false)
    this.domElement.removeEventListener('pointerup', this._mouseUpEvent, false)
  }

  _init() {
    this.validClickData = []
    this.addNodes = []
    this.firstMove = null
    this.allStartPos = []
    this.markDataArr = []
    this.mouseStartData = null
    this.mouseMoveData = null
    this.mouseEndData = null
  }

  _mouseDown(event) {
    event.preventDefault()
    if (this.state == z3dCtrlState.DIVIDE) return
    this._init()
   
    event.stopPropagation();
    this.curEvent = event
    this.eventDown = event

    if (this.state != z3dCtrlState.DRAW_LINE) setCount = 0
    this.mouseStart = this.getMousePointer(event)//鼠标点 
    if (showSelectStates.includes(this.state)) {
      // this.helper.activate()
      this.selectionBox.startPoint.set(
        // (this.eventDown.clientX / window.innerWidth) * 2 - 1,
        // - (this.eventDown.clientY / window.innerHeight) * 2 + 1,
        this.mouseStart.x, this.mouseStart .y,
        0.5);
    }




   
    let data = this.customDraw(this.mouseStart)
    if (!data) return
    let { insectNode, faceNormal, clickPoint } = data


    // if(insectNode){
    //   this.mouseStartData = data
    //   this.validClickData.push(data)
    // }
    this.mouseStartData = data
    this.validClickData.push(data)





  }


  _mouseMove(event) {
    event.preventDefault()
    if (this.state == z3dCtrlState.DIVIDE) return
    if (event.buttons != 1) return
    event.stopPropagation();
    this.curEvent = event
    this.eventMove = event
    this.mouseMove = this.getMousePointer(event)//鼠标点

  
   this.z3d.spaceRuler.deleteSpaceBox()
   this.preDrawData = []

   

    this.toFindMark()
    this.averageAddPoints()

    if(this.state ===z3dCtrlState.MUTIL_SELECT){
      this.selectionBox.endPoint.set(
        // (this.eventMove.clientX / window.innerWidth) * 2 - 1,
        // - (this.eventMove.clientY / window.innerHeight) * 2 + 1,
        this.mouseMove.x, this.mouseMove.y,
        0.5);
      var curSelectBox = document.getElementsByClassName('selectBox');
      curSelectBox[0]?.setAttribute('style', 'background-color: rgba(75, 160, 255,0.2);border: 1px solid #55aaff;  position: fixed;')
    }
    else if(this.state!=z3dCtrlState.NONE)this.toPreDraw()
    else{
      var curSelectBox = document.getElementsByClassName('selectBox');
      curSelectBox[0]?.setAttribute('style', 'background-color: rgba(75, 160, 255,0.2);border: 1px solid #55aaff;  position: fixed;display:none')
    
  }

  }


  _mouseUp(event) {
    event.preventDefault()
    event.stopPropagation();
    this.z3d.markPoint.deleteMark()
    this.curEvent = event
    this.eventUp = event
    this.mouseEnd = this.getMousePointer(event)//鼠标点
    let nodeData = this.customDraw(this.mouseEnd)
   


    if(this.state===z3dCtrlState.MUTIL_SELECT){
      this.handleMouseEvent()
      return
    }
    let allData = []
    if (nodeData) {
      this.mouseEndData = nodeData
      let { clickPoint, insectNode } = nodeData
      this.validClickData.push(nodeData)

    }
    if (this.state == z3dCtrlState.DIVIDE&&nodeData) {
      if (this.collisionPoints) {
        this.toDividePlank(nodeData.clickPoint)
        this.state = this.lastState
        return
      }


    }



    if (this.mouseEndData && this.mouseStartData) {
      let { clickPoint: startPos, insectNode, faceNormal } = this.mouseStartData
      let endPos = this.mouseEndData.clickPoint.clone()
      if (similarXZ(startPos, endPos, 10)) {
        if (![z3dCtrlState.MUTIL_SELECT, z3dCtrlState.MUTIL_DOOR].includes(this.state)) {
          this.restoreNode()
          this.setNodeSelected(insectNode, false)
          // let box =this.z3d.spaceRuler.getSpaceBox1(insectNode, faceNormal,startPos)
          // this.z3d.cab3d.add(box)
          return
        } else {
          ElMessage({
            message: '请框选您需要激活的选区',
            type: 'warning',
            duration: 1000
          })
          return
        }

      }

    }

    this.handleMouseEvent()
    this.z3d.spaceRuler.deleteSpaceBox()

  }

  // 鼠标移动 预绘制
  toPreDraw(){
    let nodeData = this.customDraw(this.mouseMove)
  

    if(!nodeData)return
    this.mouseEnd =  this.mouseMove
    this.validClickData.push(nodeData)
    this.mouseEndData = nodeData
    let validClickData = this.dealClickData(this.validClickData)
    let boxs = []
    const scene = this.z3d.scene

    let drawData = this.getDrawData(this.mouseStartData, this.mouseEndData, this.eventMove)

    if(!drawData)return
    let { startPos, endPos, angle, dir } = drawData

    
  
    if(angle>20&&angle<70) {
      isDrawLine = false // 画矩形
      const computeDatas = this.getSelectionPoints()
      computeDatas.map(data => {
        let { clickPoint, insectNode, faceNormal } = data
        // 获取射线拾取的内空
        let box = this.z3d.spaceRuler.getSpaceBox1(insectNode, faceNormal, clickPoint)
        if (box){
           boxs.push(box)
          
        }
  
  
      })
      

    }
    else{

      let lineDir = angle>45?'V':'H'
      isDrawLine = true // 画线
      validClickData.map(data => {
        let { clickPoint, insectNode, faceNormal } = data
        // 获取射线拾取的内空
        let box = this.z3d.spaceRuler.getSpaceBox1(insectNode, faceNormal, clickPoint,lineDir)
        if (box){
           boxs.push(box)
          //  this.z3d.cab3d.add(box)
        }
  
  
      })
    }
  

    if (showSelectStates.includes(this.state)||!isDrawLine) {
      this.selectionBox.endPoint.set(
        (this.eventMove.clientX / window.innerWidth) * 2 - 1,
        - (this.eventMove.clientY / window.innerHeight) * 2 + 1,
        0.5);
      var curSelectBox = document.getElementsByClassName('selectBox');
      curSelectBox[0]?.setAttribute('style', 'background-color: rgba(75, 160, 255,0.2);border: 1px solid #55aaff;  position: fixed;')
    } else {
      var curSelectBox = document.getElementsByClassName('selectBox');
      curSelectBox[0]?.setAttribute('style', 'background-color: rgba(75, 160, 255,0.2);border: 1px solid #55aaff;  position: fixed;display:none')
    }



    let unBoxs = unique(boxs, 'size', 'position')
    const that = this


  
    this.z3d.spaceRuler.deleteSpaceBox()
    switch (this.state) {
      case z3dCtrlState.DRAW_LINE:
        if(isDrawLine){
        unBoxs.map(box => {
      
          if (box.isOutBox){
            that.drawPreOutLine(box)
          }
          else that.drawPreLine(angle, startPos, box)
       
        })
      }else{
        // const allSelected = this.selectionBox.select();
        // console.log('allSelected',allSelected)
        unBoxs.map(box => {
          if(box.isOutBox){
            ElMessage({
              showClose: true,
              message: '请在柜体内绘制',
              type: 'warning',
            })
          }
          else this.drawPreBackPlank(box)
       
        })

        return
        let minMaxData = this.findMinMax(startPos, endPos)
        if (!minMaxData) return
        let { min, max } = minMaxData
        this.drawPreBackPlank(min,max)
      }
        break
      case z3dCtrlState.DRAW_STRIP:
        unBoxs.map(box => {
    
          if (box.isOutBox){
            ElMessage({
              showClose: true,
              message: '请在柜体内横向绘制',
              type: 'warning',
            })
          }
          else that.drawPreStrip(angle, startPos, box)
        
        })
        break
      case z3dCtrlState.DRAW_RECT:
        let minMaxData = this.findMinMax(startPos, endPos)
        if (!minMaxData) return
        let { min, max } = minMaxData
        this.drawPreDoor(min,max)
        break

        // 封板
      case z3dCtrlState.DRAW_SEAL:
        unBoxs.map(box => {
    
          if (box.isOutBox){
            that.drawPreSeal(angle, startPos, box,true)
          }
          else that.drawPreSeal(angle, startPos, box,false)
        
        })
        break


      case z3dCtrlState.DRAW_DRAWER:
        this.drawRect(2, drawData)
        break
      case z3dCtrlState.DRAW_BAFFLE:
        this.drawRect(3, drawData)
        break
      case z3dCtrlState.DRAW_BOX:
        this.drawRect(4, drawData)
        break
   
      // case z3dCtrlState.DRAW_BACK:
      //   // this.drawRect(5, drawData)
      //   this.drawPreBackPlank(min,max)
      //   break

    }


  }

  getSelectNode(selected){
    const targetPlanks = []
    selected.map(select=>{
      if(select.parent instanceof PlankNode)targetPlanks.push(select.parent)
    })
  return targetPlanks

  }
  // 最近标记点
  toFindMark() {
    let point = this.mouseMove.clone()
    let { cab3d, markPoint } = this.z3d
    let markPoints = cab3d?.markPoints ?? []
    let nodeData = this.customDraw(point, true)
    if (nodeData && this.mouseStartData) {
      let { clickPoint } = nodeData
      let clickPos = clickPoint.clone()
      clickPos.y = -600
      let startPos = this.mouseStartData.clickPoint.clone()
      startPos.y = -600
      let a = startPos.clone()
      let b = clickPos.clone()
      a.y = b.y
      // if(!this.firstMove&&b.clone().sub(a).length()>20){
      //   this.firstMove =  this.mouseMove
      //   this.mouseMoveData = nodeData
      // }

      this.firstMove = this.mouseMove
      this.mouseMoveData = nodeData

      switch (this.state) {
        case z3dCtrlState.DRAW_LINE:
          this.z3d.markPoint.updateLine(this.mouseStartData?.clickPoint.clone(), clickPos)
          break
        case z3dCtrlState.DRAW_RECT:
        case z3dCtrlState.DRAW_DRAWER:
        case z3dCtrlState.DRAW_BAFFLE:
          addPlankPoints(cab3d)
          let minData = { minDis: -Infinity, point: new Vector3() }
          markPoints.map(markPoint => {
            let dis = markPoint.distanceTo(clickPos)
            if (minData.minDis == -Infinity) {
              minData.minDis = dis
              minData.point = markPoint
            }
            if (dis < minData.minDis) {
              minData.minDis = dis
              minData.point = markPoint
            }
          })
          if (minData.minDis) {
            let point = this.z3d.scene.getObjectByName('rectMark');
            if (point) {
              this.z3d.markPoint.updatePoint(point, minData.point)
              this.finalMarkPoint = minData.point.clone()
            } else {
              point = this.z3d.markPoint.createMarkPoint('rectMark', minData.point)
              this.z3d.scene.add(point)
            }
          }
          break
        default:
      }




    }
  }


  handleMouseEvent() {
    let {cab3d } = this.z3d
    let spaceRuler = this.z3d.spaceRuler
    if (spaceRuler) {
      spaceRuler.deleteRuler()
      spaceRuler.deleteSpaceBox()
    }

    let drawData = this.getDrawData(this.mouseStartData, this.mouseEndData, this.eventUp)

    // dragControls.deactivate()
    if (!this.flag) this.lastState = this.state

    let unPreData = unique( this.preDrawData, 'size', 'newPos')
    switch (this.state) {
      case z3dCtrlState.SELECT:
        this.onMouseClick()
        break
      case z3dCtrlState.DRAW_LINE:
      case z3dCtrlState.DRAW_SEAL:
        case z3dCtrlState.DRAW_STRIP:
   
        unPreData.map(preData=>{
          const {preBox,size, newPos, typeId,name} = preData
          let allPkanks = []
          switch (typeId) {
            case zPlankType.SIDE_L:
            case zPlankType.SIDE_R:
              allPkanks = cab3d.children.filter(n=>n instanceof PlankNode && [zPlankType.SIDE_R,zPlankType.SIDE_L].includes(n.plankType))
              break
            case zPlankType.LEVEL_T:
            case zPlankType.LEVEL_B:
              allPkanks = cab3d.children.filter(n=>n instanceof PlankNode && [zPlankType.LEVEL_T,zPlankType.LEVEL_B].includes(n.plankType))
              break
            case zPlankType.BACK_PLANK:
              allPkanks = cab3d.children.filter(n=>n instanceof PlankNode && [zPlankType.BACK_PLANK].includes(n.plankType))
          }

          let isCollosion = false
          allPkanks.map(bplank=>{
            const halfSize = bplank.size.clone().multiplyScalar(0.5)
            const obb = bplank.bodyMesh.geometry.userData?.obb??new OBB(bplank.position,halfSize);

            // if(bplank.bodyMesh.geometry.userData?.obb&&preBox.box.geometry.userData.obb.intersectsOBB(bplank.bodyMesh.geometry.userData.obb))
            if(obb&&preBox.box.geometry.userData.obb.intersectsOBB(obb)){
              isCollosion=true
              return
            }
          })

          if(!isCollosion){

              let plankNode = createPlank(size, newPos, typeId, name,'')
              let matRot = rotPlankTypes.includes(typeId) ? 0 : Math.PI / 2;
              plankNode.setMatRot(matRot)
              cab3d.add(plankNode)

            this.addNodes.push(plankNode)
            }else{
              ElMessage({
                showClose: true,
                message: '绘制失败，该位置已存在板件',
                type: 'warning',
              })
            }
        })
      

        break
      case z3dCtrlState.DRAW_RECT:
        const allDoors = cab3d.children.filter(n=>n instanceof PlankNode && [zPlankType.DOOR].includes(n.plankType))
        if(allDoors.length){
          unPreData.map(preData=>{
          const {preBox,size, newPos, typeId,name} = preData

          let isCollosion = false
          allDoors.map(bplank=>{
            if(preBox.box.geometry.userData.obb.intersectsOBB(bplank.bodyMesh.geometry.userData.obb)){
              isCollosion=true
              return
            }
          })
          if(!isCollosion){
          let plankNode = createPlank(size, newPos, typeId, name,'')
          let matRot = rotPlankTypes.includes(typeId) ? 0 : Math.PI / 2;
          plankNode.setMatRot(matRot)
          cab3d.add(plankNode)

          this.addNodes.push(plankNode)
          }else{
            ElMessage({
              showClose: true,
              message: '绘制失败，该位置已存在板件',
              type: 'warning',
            })
          }
        })
      }else{
        unPreData.map(preData=>{
          const {preBox,size, newPos, typeId,name} = preData
          let plankNode = createPlank(size, newPos, typeId, name,'')
          let matRot = rotPlankTypes.includes(typeId) ? 0 : Math.PI / 2;
          plankNode.setMatRot(matRot)
          cab3d.add(plankNode)

        this.addNodes.push(plankNode)
        })
      }

        break

      case z3dCtrlState.DRAW_BACK:
        const allBackPlanks1 = cab3d.children.filter(n=>n instanceof PlankNode && [zPlankType.BACK_PLANK].includes(n.plankType))
        if(allBackPlanks1.length){
          unPreData.map(preData=>{
          const {preBox,size, newPos, typeId,name} = preData

          let isCollosion = false
          allBackPlanks1.map(bplank=>{
            if(preBox.box.geometry.userData.obb.intersectsOBB(bplank.bodyMesh.geometry.userData.obb)){
              isCollosion=true
              return
            }
          })
          if(!isCollosion){
          let plankNode = createPlank(size, newPos, typeId, name,'')
          let matRot = rotPlankTypes.includes(typeId) ? 0 : Math.PI / 2;
          plankNode.setMatRot(matRot)
          cab3d.add(plankNode)

          this.addNodes.push(plankNode)
          }else{
            ElMessage({
              showClose: true,
              message: '绘制失败，该位置已存在板件',
              type: 'warning',
            })
          }
        })
      }else{
        unPreData.map(preData=>{
          const {preBox,size, newPos, typeId,name} = preData
          let plankNode = createPlank(size, newPos, typeId, name,'')
          let matRot = rotPlankTypes.includes(typeId) ? 0 : Math.PI / 2;
          plankNode.setMatRot(matRot)
          cab3d.add(plankNode)

        this.addNodes.push(plankNode)
        })
      }

        break

      case z3dCtrlState.DRAW_DRAWER:
        this.drawRect(2, drawData)
        break
      case z3dCtrlState.DRAW_BAFFLE:
        this.drawRect(3, drawData)
        break
      case z3dCtrlState.DRAW_BOX:
        this.drawRect(4, drawData)
        break
      case z3dCtrlState.MUTIL_SELECT:
        this.multilSelect(false)
        break
      case z3dCtrlState.MUTIL_DOOR:
        this.multilSelect(true)
        break
      case z3dCtrlState.DRAG_NODE:
        break
      case z3dCtrlState.SELECT_CAB:
        break
        break
      default:
    }

    if (this.addNodes.length) this.addMutilCommand(this.addNodes)
  }


  /**显示隐藏 */
  toDisplay(isDisplay) {
    switch (this.state) {
      case z3dCtrlState.MUTIL_SELECT:
        let mutilNodes = this.curMutilSelect
        if (mutilNodes) mutilNodes.map(node => { node.visible = isDisplay })
        break
      case z3dCtrlState.MUTIL_DOOR:
        let mutilDoors = this.curMutilSelect
        if (mutilDoors) mutilDoors.map(node => { node.visible = isDisplay })
        break
      default:
        let curNode = this.curSelectedNode
        if (curNode) curNode.visible = isDisplay
        break
    }
  }


  /**绘制矩形 */
  drawRect(flag, drawData) {

    if (!drawData) return
    let { startPos, endPos, angle, dir } = drawData

    let minMaxData = this.findMinMax(startPos, endPos)
    if (!minMaxData) return
    let { min, max } = minMaxData

    switch (flag) {
      case 1:
        this.drawDoor(min, max)
        break
      case 2:
        this.drawDrawer(min, max)
        break
      case 3:
        this.drawBaffle(min, max)
        break
      case 4:
        this.drawBox(startPos, endPos)
        break
      case 5:
        this.drawBackPlank(min, max)
    }
  }

  /**据板件正面点位 */
  findMinMax(wsp, wep) {
    let { cab2d, cab3d } = this.z3d
    let rotation = cab3d.rotation
    let allFrontPos = []
    const isFront =[zDir.FRONT,zDir.BACK].includes( this.viewDir)
    if (wsp && this.mouseStartData) {
      let { clickPoint, insectNode, faceNormal } = this.mouseStartData
      let sBox = this.z3d.spaceRuler.getSpaceBox(insectNode, faceNormal, wsp)
      if (sBox) {
        let sBoxWpos = getWorldPos(sBox)
        let frontPosArr = getFrontPos(sBoxWpos, sBox.size, rotation)
        let backPosArr = !isFront?getBackPos(sBoxWpos, sBox.size, rotation):[]
        sBox.box.visible = false
        allFrontPos.push(wsp)
        allFrontPos.push(...frontPosArr,...backPosArr)
      }

    }
    if (this.finalMarkPoint) allFrontPos.push(this.finalMarkPoint)
    else {
      if (wep && this.mouseEndData) {
        let { clickPoint, insectNode, faceNormal } = this.mouseEndData
        let eBox = this.z3d.spaceRuler.getSpaceBox(insectNode, faceNormal, wep)
        if (eBox) {
          let eBoxWpos = getWorldPos(eBox)
          eBox.box.visible = false
          let frontPosArr = getFrontPos(eBoxWpos, eBox.size, rotation)
          let backPosArr = !isFront?getBackPos(eBoxWpos, eBox.size, rotation):[]
          allFrontPos.push(wep, ...frontPosArr,...backPosArr)
        }

      }
    }



    if (!allFrontPos.length || !cab3d) return


    let minmaxData = this.getMinVector(allFrontPos)
    return minmaxData

  }

  /**获取鼠标起点到终点的角度和方向 */
  getDrawData(startData, endData, endEvent) {
    let screenSp = null, screenEp = null
    let startPos, endPos
    if (startData) {
      let hitData = startData
      startPos = hitData ? hitData.clickPoint.clone() : null
      screenSp = this.eventDown
    }
    if (endData) {
      let hitData = endData
      endPos = hitData ? hitData.clickPoint.clone() : null
      screenEp = endEvent
    }

    if (startPos && endPos) {
      let angle = computeAngle2d(screenSp, screenEp)
      let dir = computeDir(angle, startPos, endPos)
      return { startPos, endPos, angle, dir }
    }
  }

  /**绘制门板 */
  drawDoor(min, max) {
    let { cab2d, cab3d, thickness: doorThick, doorType } = this.z3d
    let { x: doorSx, y: doorSy, z: doorSz } = abs(max.clone().sub(min))

    //门板参数
    let { doorWidth } = defaultSetting
    doorWidth = doorWidth > 0 ? doorWidth : 500

    let doorCount = 1 // Math.round(doorSx / doorWidth)//四舍五入
    let realDoorSx = doorCount > 0 ? Math.round(doorSx / doorCount) : doorSx
   
    let bigDoorWpos = max.clone().add(min).multiplyScalar(0.5)
    for (let i = 0; i < doorCount; i++) {
      let offPos = new Vector3(i * realDoorSx + realDoorSx * 0.5, 0, 0)
      let doorWpos = min.clone().add(offPos)
      doorWpos.z = bigDoorWpos.z
      let relativePos = doorWpos.clone().sub(cab3d.position)
      relativePos.y = -cab3d.size.y * 0.5 - doorThick * 0.5

      const size = new Vector3(realDoorSx - 2, doorThick, doorSz - 2 )
       let door = createPlank( size,relativePos, zPlankType.DOOR, '门板','')

      let doorDir = i % 2 == 0 ? zDir.LEFT : zDir.RIGHT

      let matRot = rotPlankTypes.includes(zPlankType.DOOR) ? 0 : Math.PI / 2;
      door.setMatRot(matRot)


      cab3d.add(door)
 

      door.doorGroup = new Group()
      //纠正门板
      // this.fixDoor(door, door2d, doorDir)
      // this.addHandle(door, door2d)
      this.addNodes.push(door)
    }

    if (this.addNodes.length) this.addMutilCommand(this.addNodes)
  }

  drawPreDoor(min, max) {
    let { cab2d, cab3d, thickness: doorThick, doorType } = this.z3d
    const maxsize  = abs(max.clone().sub(min))
    let { x: doorSx, y: doorSy, z: doorSz } = maxsize

    //门板参数
    let { doorWidth } = defaultSetting
    doorWidth = doorWidth > 0 ? doorWidth : 500

    let doorCount = 1 // Math.round(doorSx / doorWidth)//四舍五入
    let realDoorSx = doorCount > 0 ? Math.round(doorSx / doorCount) : doorSx
   
    let bigDoorWpos = max.clone().add(min).multiplyScalar(0.5)
    
      let offPos = new Vector3( realDoorSx * 0.5, 0, 0)
      let doorWpos = min.clone().add(offPos)
      doorWpos.z = bigDoorWpos.z
      let relativePos = doorWpos.clone().sub(cab3d.position)
      relativePos.y = -cab3d.size.y * 0.5 - doorThick * 0.5


      const {newPos,size} =  this.getInfoByViewDir(zPlankType.DOOR,doorWpos,bigDoorWpos,maxsize)
      // const size = new Vector3(realDoorSx - 2, doorThick, doorSz - 2 )
       let preBox =  new Box() // createPlank( size,relativePos, zPlankType.DOOR, '门板','')
       preBox.createBox(size)
       preBox.box.material.visible = true

       preBox.position.copy(newPos)
       cab3d.add(preBox)

           // obb
    const halfSize = size.clone().multiplyScalar(0.5).sub(new Vector3(2,2,2))
    preBox.box.geometry.userData.obb =new OBB(doorWpos,halfSize)
    preBox.box.userData.obb = new OBB();

       this.preDrawData.push({ preBox, size, newPos, typeId:zPlankType.DOOR,name:'门板' })
    

   
  }

  fixDoor(door, door2d, doorDir) {
    let { doorType, thickness } = this.z3d
    door.doorDir = door2d.doorDir = doorDir
    switch (doorType) {
      case zDoorType.SLID_DOOR:
        //移门
        switch (doorDir) {
          case zDir.LEFT:
            break
          case zDir.RIGHT:
            let offPos = new Vector3(0, thickness - 2, 0)
            door.position.add(offPos)
            door2d.position.add(offPos)
            break
        }
        break
      case zDoorType.SWING_DOOR:

        break
    }

    door.doorType = door2d.doorType = doorType
    door.oldPosition = door2d.oldPosition = door.position.clone()

  }
  openDoor(node, isOpen) {
    switch (node.doorType) {
      case zDoorType.SLID_DOOR:
        this.openSlidDoor(node, isOpen)
        break
      case zDoorType.SWING_DOOR:
        this.openSwingDoor(node, isOpen)
        break
    }
    node.isOpen = isOpen
  }

  openSwingDoor(node, isOpen) {
    let { editor, is3D, cab3d, cab2d } = this.z3d
    let { x: sx, y: sy, z: sz } = node.size
    let LB = new Vector3(-sx * 0.5, -sy * 0.5, 0)
    let RB = new Vector3(sx * 0.5, -sy * 0.5, 0)
    let nodePos = null
    let rotationValues = [0, -Math.PI / 2];
    let oldValues = [-Math.PI / 2, 0];
    let referPos = new Vector3()

    switch (node.doorDir) {
      case zDir.LEFT:
        referPos = LB
        nodePos = RB
        if (isOpen) {
          rotationValues = [0, -Math.PI / 2];
          oldValues = [-Math.PI / 2, 0];
        } else {
          rotationValues = [-Math.PI / 2, 0];
          oldValues = [0, -Math.PI / 2];
        }
        break
      case zDir.RIGHT:
        referPos = RB
        nodePos = LB
        if (isOpen) {
          rotationValues = [0, Math.PI / 2];
          oldValues = [-Math.PI / 2, 0];
        } else {
          rotationValues = [Math.PI / 2, 0];
          oldValues = [0, -Math.PI / 2];
        }
        break


    }

    if (!rotationValues) return
    let realaPos = referPos.clone().add(node.oldPosition)

    let group = null, otherGroup = null
    group = node.doorGroup ? node.doorGroup : node.doorGroup = new Group();

    group.position.set(realaPos.x, realaPos.y, realaPos.z); // 设置外层对象的中心为原本想要旋转的位置
    group.add(node);
    group.name = node.cid;


    node.position.set(nodePos.x, nodePos.y, nodePos.z);
    this.curCab.add(group)

  

    let moveDatas = [
      { node: node, group: group, valueName: 'door.rotation[z]', newValue: rotationValues, oldValue: oldValues, attr: 'isOpen', newAtt: isOpen, oldAtt: !isOpen },
   
    ]
    this.addRotCommand(editor, moveDatas)
  }



  openSlidDoor(node, isOpen) {
    let { editor, is3D, cab3d, cab2d } = this.z3d
    let group = null, otherGroup = null;
    let { x: osx, y: sy, z: sz } = node.size
    let sx = osx - 20 //移门偏移20mm




    const times = [0, 1]; // 关键帧时间数组
    let rotationValues = [0, 0];
    let oldValues = [0, 0];
    switch (node.doorDir) {
      case zDir.LEFT:
        if (isOpen) {
          rotationValues = [0, sx];
          oldValues = [sx, 0];
        } else {
          rotationValues = [sx, 0];
          oldValues = [0, sx];
        }
        break
      case zDir.RIGHT:
        if (isOpen) {
          rotationValues = [0, -sx];
          oldValues = [-sx, 0];
        } else {
          rotationValues = [-sx, 0];
          oldValues = [0, -sx];
        }
        break


    }

    group = node.doorGroup ? node.doorGroup : node.doorGroup = new Group();

    group.add(node);
    group.name = node.cid;
    this.curCab.add(group)





    let moveDatas = [
      { node: node, group: group, valueName: 'door.position[x]', newValue: rotationValues, oldValue: oldValues, attr: 'isOpen', newAtt: isOpen, oldAtt: !isOpen },
   
    ]
    this.addRotCommand(editor, moveDatas)

  }

  /**绘制抽屉门板 */
  drawDrawer(min, max) {
    let { cab2d, cab3d } = this.z3d
    let { x: doorSx, y: doorSy, z: doorSz } = abs(max.clone().sub(min))
    let drawerThick = this.z3d.thickness
    let { drawerWidth, drawerHeight } = defaultSetting
    drawerWidth = drawerWidth > 0 ? drawerWidth : 500
    drawerHeight = drawerHeight > 0 ? drawerHeight : 200

    let doorWCount = 1 // Math.round(doorSx / drawerWidth)//四舍五入
    let doorHCount = Math.round(doorSz / drawerHeight)//四舍五入

    let realDoorSx = doorSx // doorWCount > 0 ? Math.round(doorSx / doorWCount) : doorSx
    let realDoorSz = doorHCount > 0 ? Math.round(doorSz / doorHCount) : doorSz


    let bigDoorWpos = max.clone().add(min).multiplyScalar(0.5)
    for (let i = 0; i < doorWCount; i++) {
      for (let j = 0; j < doorHCount; j++) {

        let offPos = new Vector3(i * realDoorSx + realDoorSx * 0.5, 0, j * realDoorSz + realDoorSz * 0.5)
        let doorWpos = min.clone().add(offPos)
        let relativePos = doorWpos.clone().sub(cab3d.position)
        relativePos.y = -cab3d.size.y * 0.5 - drawerThick * 0.5

        const size = new Vector3(realDoorSx, drawerThick, realDoorSz)
        let door = createPlank(size, relativePos, zPlankType.DRAWER_DOOR, '抽屉门','')

        let matRot = rotPlankTypes.includes(zPlankType.DRAWER_DOOR) ? 0 : Math.PI / 2;
        door.setMatRot(matRot)

        cab3d.add(door)
    
        // this.addHandle(door)
        this.addNodes.push(door)

      }

    }

    if (this.addNodes.length) this.addMutilCommand(this.addNodes)
  }

  addHandle(door, door2d) {
    let { handleColor } = defaultSetting
    let handle = this.z3d.handle.clone()
    let material = handle.material.clone()
    let { x: sx, y: sy, z: sz } = door.size
    let handlePos = new Vector3()
    let angle = new Vector3()
    let location = zNineDir.C_C
    switch (door.plankType) {

      case zPlankType.DOOR:
        let isSilidDoor = door.doorType == zDoorType.SLID_DOOR
        if (isSilidDoor) {
          handlePos = new Vector3(sx * 0.5 - 80, -12, 0)
          angle = new Vector3(90, 0, -90)
          location = zNineDir.R_C
        }
        else {
          angle = new Vector3(90, 0, 0)
          switch (door.doorDir) {
            case zDir.LEFT:
              //80=偏移20+拉手尺寸120
              handlePos = new Vector3(sx * 0.5 - 20, -12, 0)
              location = zNineDir.R_C
              //居右
              break
            case zDir.RIGHT:
              handlePos = new Vector3(-sx * 0.5 + 20, -12, 0)
              location = zNineDir.L_C
              //居左
              break
          }
        }
        break
      case zPlankType.DRAWER_DOOR:
        //水平居中
        handlePos = new Vector3(0, -12, 0)
        angle = new Vector3(90, 0, -90)
        break
    }
    let radian = angleToRadian(angle)
    let euler = new Euler(...xyz(radian))
    handle.setRotationFromEuler(euler, 'ZYX')
    handle.position.set(handlePos.x, handlePos.y, handlePos.z)
    material.color.set(handleColor)
    handle.material = material

    door.handle = handle
    let handle2d = handle.clone()
    door2d.handle = handle2d
    this.setHandleData(door, door2d, location, angle, handlePos, handleColor)
    door.add(handle)
    door2d.add(handle2d)
  }

  setHandleData(node, node2d, location, angle, handlePos, handleColor) {
    node.handleData = { location: location, angle: angle, handlePos: handlePos, handleColor }
   
  }
  //拉手设置
  handleSetting(node, handleData) {
    let { dir, offx, offz, newSize } = handleData
    if (!node) return
    let { x: sx, y: sy, z: sz } = node.size
    let handlePos = node.handle.position.clone()
    let isHoriz = node.plankType == zPlankType.DRAWER_DOOR || node.doorType == zDoorType.SLID_DOOR

    let { x, y, z } = defaultSize

    // let {x,y,z} = newSize?newSize:defaultSize
    // if(newSize)this.setNodeScale(node.handle,newSize)

    let { x: hsx, y: hsy, z: hsz } = isHoriz ? new Vector3(y * 0.5, 0, z * 0.5) : new Vector3(z * 0.5, 0, y * 0.5)
    switch (dir) {
      case zNineDir.L_T:
        handlePos = new Vector3(-sx * 0.5 + offx + hsx, -12, sz * 0.5 - offz - hsz)
        break
      case zNineDir.T_C:
        handlePos = new Vector3(0, -12, sz * 0.5 - offz - hsz)
        break
      case zNineDir.R_T:
        handlePos = new Vector3(sx * 0.5 - offx - hsx, -12, sz * 0.5 - offz - hsz)
        break
      case zNineDir.L_C:
        handlePos = new Vector3(-sx * 0.5 + offx + hsx, -12, 0)
        break
      case zNineDir.C_C:
        handlePos = new Vector3(0, -12, 0)
        break
      case zNineDir.R_C:
        handlePos = new Vector3(sx * 0.5 - offx - hsx, -12, 0)
        break
      case zNineDir.L_B:
        handlePos = new Vector3(-sx * 0.5 + offx + hsx, -12, -sz * 0.5 + offz + hsz)
        break
      case zNineDir.B_C:
        handlePos = new Vector3(0, -12, -sz * 0.5 + offz + hsz)
        break
      case zNineDir.R_B:
        handlePos = new Vector3(sx * 0.5 - offx - hsx, -12, -sz * 0.5 + offz + hsz)
        break
      default:
        if (node.doorDir == zDir.LEFT) handlePos = new Vector3(sx * 0.5 - offx - hsx, -12, 0)
        else if (node.doorDir == zDir.RIGHT) handlePos = new Vector3(-sx * 0.5 + offx + hsx, -12, 0)

    }
    node.handle.position.set(handlePos.x, handlePos.y, handlePos.z)
    let location = dir
    // this.updateHandle(node, handlePos, null, location)
  }

  /**更新把手位置 */
  updateHandle(node, pos, oldMatrix, location) {
    let { cab2d, cab3d, is3D: is3d, editor } = this.z3d
    let { x, y, z } = pos
    let { doorDir: dir, cid } = node


    let moveDatas = [
      { node: node.handle, newPos: pos, oldPos, attNode: node, att: 'doorDir', oldValue, newValue: dir },
     
    ]

    let { angle } = node.handleData
    this.setHandleData(node, otherNode, location, angle, pos, '')
    this.addMoveCommand(editor, moveDatas, oldMatrix)
  }

  /**挡板 */
  drawBaffle(min, max) {
    let { cab2d, cab3d, thickness } = this.z3d
    let { x: doorSx, y: doorSy, z: doorSz } = abs(max.clone().sub(min))
    let doorSize = abs(max.clone().sub(min))
    doorSize.y = thickness
    let doorWpos = max.clone().add(min).multiplyScalar(0.5).sub(cab3d.position)
    doorWpos.y = -cab3d.size.y * 0.5 - thickness * 0.5



    let door  = createPlank(doorSize, doorWpos, zPlankType.BAFFLE, '挡板', '')

    let matRot = rotPlankTypes.includes(zPlankType.BAFFLE) ? 0 : Math.PI / 2;
    door.setMatRot(matRot)


    cab3d.add(door)
 
    this.addMutilCommand([door])

  }

  /**背板 */
  drawBackPlank(min, max) {
    let { cab2d, cab3d, thickness } = this.z3d
    let { x: doorSx, y: doorSy, z: doorSz } = abs(max.clone().sub(min))
    let doorSize = abs(max.clone().sub(min))
    doorSize.y = 18
    let doorWpos = max.clone().add(min).multiplyScalar(0.5).sub(cab3d.position)
    doorWpos.y = cab3d.size.y * 0.5 - thickness * 0.5+18

    let door = createPlank(doorSize, doorWpos, zPlankType.BACK_PLANK, '背板','')
  

    let matRot = rotPlankTypes.includes(zPlankType.BACK_PLANK) ? 0 : Math.PI / 2;
    door.setMatRot(matRot)

    cab3d.add(door)

    this.addMutilCommand([door])

  }

  drawPreBackPlank(box) {
    let { cab2d, cab3d, thickness } = this.z3d
    let doorSize = box.size
    // doorSize.y = 9
    const wPos = getWorldPos(box).clone()
    // let doorWpos =wPos.sub(cab3d.position)
    // doorWpos.y = cab3d.size.y * 0.5 - thickness * 0.5 -18


    const {newPos,size} = this.getInfoByViewDir(zPlankType.BACK_PLANK,wPos,wPos,doorSize)
    let preBox =  new Box() 
    preBox.createBox(size)
    preBox.box.material.visible = true

    preBox.position.copy(newPos)
    cab3d.add(preBox)


    // obb
    const halfSize = size.clone().multiplyScalar(0.5).sub(new Vector3(2,2,2))
    preBox.box.geometry.userData.obb =new OBB(newPos,halfSize)
    preBox.box.userData.obb = new OBB();


    this.preDrawData.push({ preBox, size, newPos:newPos, typeId:zPlankType.BACK_PLANK,name:'背板' })
  

  }
  drawPreBackPlank1(min, max) {
    let { cab2d, cab3d, thickness } = this.z3d
    let doorSize = abs(max.clone().sub(min))
    // doorSize.y = 9
    const wPos =  max.clone().add(min).multiplyScalar(0.5)
    // let doorWpos =wPos.sub(cab3d.position)
    // doorWpos.y = cab3d.size.y * 0.5 - thickness * 0.5 -18


    const {newPos,size} = this.getInfoByViewDir(zPlankType.BACK_PLANK,wPos,wPos,doorSize)
    let preBox =  new Box() 
    preBox.createBox(size)
    preBox.box.material.visible = true

    preBox.position.copy(newPos)
    cab3d.add(preBox)


    // obb
    const halfSize = size.clone().multiplyScalar(0.5).sub(new Vector3(2,2,2))
    preBox.box.geometry.userData.obb =new OBB(newPos,halfSize)
    preBox.box.userData.obb = new OBB();


    this.preDrawData.push({ preBox, size, newPos:newPos, typeId:zPlankType.BACK_PLANK,name:'背板' })
  

  }
  /**盒子 */
  drawBox(wsp, wep) {
    let { cab2d, cab3d, thickness } = this.z3d
    let { boxWidth, boxHeight } = defaultSetting
    let { x: sx, y: sy, z: sz } = abs(wep.clone().sub(wsp))
    sx = boxWidth > 0 ? boxWidth : sx
    sz = boxHeight > 0 ? boxHeight : sz

    let standardDy = 600
    let center = wsp.clone().add(wep).multiplyScalar(0.5)
    let leftPos = center.clone().add(new Vector3(-sx * 0.5, 0, 0))
    let rightPos = center.clone().add(new Vector3(sx * 0.5, 0, 0))
    let bottomPos = center.clone().add(new Vector3(0, 0, -sz * 0.5 + thickness * 0.5))
    let topPos = center.clone().add(new Vector3(0, 0, sz * 0.5 - thickness * 0.5))

    let boxArr = [{ posDir: zDir.LEFT, pos: leftPos }, { posDir: zDir.RIGHT, pos: rightPos }, { posDir: zDir.BOTTOM, pos: bottomPos }, { posDir: zDir.TOP, pos: topPos }]
    let plankNode, plankNode2d
    let typeId = -1
    const size = new Vector3()
    for (let i = 0; i < boxArr.length; i++) {
      let { posDir, pos } = boxArr[i]
      let plankPos = pos.clone().sub(cab3d.position)
   
      switch (posDir) {
        case zDir.LEFT:
        case zDir.RIGHT:
          typeId = zPlankType.VERTICAL_PLANK;
          size.set(thickness, standardDy, sz)
          plankNode  = createPlank(size, plankPos, typeId, '')
          break
        case zDir.TOP:
        case zDir.BOTTOM:
          typeId = zPlankType.LEVEL_PLANK;
          size.set(sx - thickness, standardDy, thickness)
         plankNode = createPlank(size, plankPos, typeId, '')
          break
      }
      let matRot = rotPlankTypes.includes(typeId) ? 0 : Math.PI / 2;
      plankNode.setMatRot(matRot)
      cab3d.add(plankNode)

      this.addNodes.push(plankNode)

    }

    if (this.addNodes.length) this.addMutilCommand(this.addNodes)

  }


  getAllPlank() {
    let { cab3d } = this.z3d
    if (!cab3d) return []
    let planks = []
    cab3d.traverse(n => {
      if (n instanceof PlankNode) planks.push(n)
    })
    return planks
  }

  toOffsetDepth(node, offDir, offDepth) {
    if (this.state == z3dCtrlState.MUTIL_DOOR) {
      let mutilDoors = this.curMutilSelect.filter(n => n.is3dNode)
      let that = this
      mutilDoors.map(door => {
        door.moveNodeByLine(zDir.BACK, offDepth)
        door.oldPosition = door.position.clone()
      })
    } else {
      node.moveNodeByLine(zDir.BACK, offDepth)
      node.oldPosition = node.position.clone()
    }
  }
  toMirror(node) {
    if (this.state == z3dCtrlState.MUTIL_DOOR) {
      let mutilDoors = this.curMutilSelect.filter(n => n.is3dNode)
      let that = this
      mutilDoors.map(door => {
        that.mirror(door)
      })
    } else {
      this.mirror(node)
    }

  }
  mirror(node) {
    let { editor } = this.z3d
    let oldMatrix = node.bodyMesh.matrix.clone()
    let matrix = new Matrix4().makeScale(-1, 1, 1)
    // node.parent.applyMatrix4(matrix)
    node.bodyMesh.applyMatrix4(matrix)

    if (node.handle) {
      let pos = node.handle.position.clone()
      node.handle.position.set(-pos.x, pos.y, pos.z)
      if (node.doorDir == zDir.LEFT) node.doorDir = zDir.RIGHT
      else if (node.doorDir == zDir.RIGHT) node.doorDir = zDir.LEFT
      let localDir = getReverseDir(node.handleData.location)
      this.updateHandle(node, new Vector3(-pos.x, pos.y, pos.z), oldMatrix, localDir)
    }

  }



  /*统计清单*/
  toStatistic() {
    let nodes = this.getAllPlank()
    if (!nodes.length) return []
    let data = []
    let handleCount = 0
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i]
      let { x: sx, y: sy, z: sz } = node.size
      let sortData = quickSort([sx, sy, sz])
      let str = sortData[2] + '*' + sortData[1] + '*' + sortData[0]
      if (node.plankType == zPlankType.DOOR || node.plankType == zPlankType.DRAWER_DOOR) handleCount += 1
      let dataObj = {
        ID: i,
        model: node.plankName,
        specifications: str,
        unitprice: '0.0',
        number: 0,
        price: '0.0',
      }
      data.push(dataObj)
    }
    //门把手
    if (handleCount) data.push({
      ID: 0,
      model: '把手',
      specifications: '/',
      unitprice: '0.0',
      number: 0,
      price: '0.0',
      isHandle: handleCount
    })
    //统计相同尺寸和相同材质个数及去重
    let newData = this.statisticList(data)
    return newData

  }

  statisticList(nodes) {
    let result = Object.values(nodes.reduce((acc, cur) => {
      // 以goodsName和size组合成的字符串作为键名，统计重复个数
      const key = `${cur.model}-${cur.specifications}-${cur.model}`;
      acc[key] = acc[key] || { ...cur, number: 0 };
      acc[key].number++
      if (cur.isHandle) acc[key].number = cur.isHandle
      let { area, number, price, standardNum, extraPrice } = acc[key]
      let total = eval(area) * number * price * standardNum + extraPrice
      if (total && !Number.isNaN(total)) total = parseInt(total)
      else total = 0
      acc[key].total = total
      return acc;
    }, {}));
    result.map((resul, index) => {
      //@ts-ignore
      resul.ID = index
    })
    return result
  }

  //设置缩放
  setNodeScale(node, newSize) {
    let geo
    let size = defaultSize.clone()
    let scalex = newSize.x / size.x
    let scaley = newSize.y / size.y
    let scalez = newSize.z / size.z
    node.traverse(item => {
      if (item.type == 'Mesh') {
        geo =item.geometry
        geo.scale(scalex, scaley, scalez)
      }
    })

    node.matrixAutoUpdate = true
    node.updateMatrix();
  }

  displayCabRuler() {
  
  }

  deleteCabRuler() {


  }


  addMutilCommand(nodes) {
    let { editor, cab3d, cab2d } = this.z3d
    let commandArr = []
    nodes.map(n => {
      let parent =cab3d 
      commandArr.push(new AddObjectCommand(editor, n, parent))
    })
    editor.execute(new MultiCmdsCommand(editor, commandArr), 'Flatten Geometry');
  }

  addMoveCommand(editor, moveDatas, oldMatrix) {
    let commandArr = []
    moveDatas.map(moveData => {
      let { attNode, node, newPos, oldPos, att, oldValue, newValue } = moveData
      commandArr.push(new SetPositionCommand(editor, node, newPos, oldPos),
        new SetValueCommand(editor, attNode, att, oldValue, newValue))
      if (oldMatrix && attNode.is3dNode) commandArr.push(new SetMatrixCommand(editor, attNode, attNode.bodyMesh.matrix, oldMatrix))
    })
    editor.execute(new MultiCmdsCommand(editor, commandArr), 'Flatten Geometry');
  }


  addRotCommand(editor, moveDatas) {
    let commandArr = []
    moveDatas.map(moveData => {
      let { node, group, valueName, newValue, oldValue, attr, oldAtt, newAtt } = moveData
      commandArr.push(
        new MixerRotationCommand(editor, group, valueName, newValue, oldValue),
        new SetValueCommand(editor, node, attr, oldAtt, newAtt)
      )

    })
    editor.execute(new MultiCmdsCommand(editor, commandArr), 'Flatten Geometry');
  }

  addDeleteCommand(nodes) {
    let { editor, cab3d, cab2d, scene } = this.z3d
    let commandArr = []
    nodes.map(n => {
      let parent = n.parent//n.is3dNode?cab3d:cab2d
      commandArr.push(new RemoveObjectCommand(editor, n, parent))
    })
    editor.execute(new MultiCmdsCommand(editor, commandArr), 'Flatten Geometry');
  }

  

  getRayData(argues) {
    let { oPos, dir, node, cab, offPos } = argues
    let oriPos = oPos.clone().add(offPos)
    this.ray.set(oriPos, dir)
    let intersects = this.ray.intersectObjects([cab], true)
    for (let i = 0; i < intersects.length; i++) {
      let intersect = intersects[i]
      let insectNode = intersect.object.parent
      let dis = Math.round(intersect.distance)
      if (insectNode instanceof PlankNode && insectNode != node && dis > 0) {
        let clickPoint = intersect.point.round()
        let center = oriPos.clone().add(clickPoint).multiplyScalar(0.5)
        let relCenter = center.clone().sub(cab.position)
        relCenter.y = -300
        return { center: relCenter, dis: dis, node: node }
      }
    }

  }


  deleteInnerRuler() {


  }

  toCollision(curnode) {
    let { is3D, cab2d, cab3d } = this.z3d
    let collResults = []
    let node = curnode 
    this.collisionNode = node
    this.collisionPoints = []
    let nodes = []

    this.z3d.cab3d.traverse(n => {
      if ((n instanceof PlankNode) && n.cid != node.cid && ableCircleTypes.includes(n.plankType)) nodes.push(n)
    })

    this.update(node)
    let wpos = getWorldPos(node)
    nodes.map(n => {
      let result = new Vector3()
      this.update(n)
      if (n.bodyMesh.userData.obb && n.bodyMesh.userData.obb.intersectsOBB(node.bodyMesh.userData.obb)) {

        n.bodyMesh.userData.obb.clampPoint(wpos, result)

        collResults.push(result)
      }

    })

    let { left, right, top, bottom } = getPos(wpos, node.size, null)

    let off = 2
    switch (node.plankType) {
      case zPlankType.VERTICAL_PLANK:
      case zPlankType.SIDE_L:
      case zPlankType.SIDE_R:
        let minz = bottom.z + off
        let maxz = top.z - off
        collResults.map(pos => {
          if (pos.z > minz && pos.z < maxz) {
            this.collisionPoints.push(pos)
            let point = this.z3d.markPoint.createMarkPoint('plankMark', pos)
            this.z3d.scene.add(point)
          }
        })
        break
      case zPlankType.LEVEL_PLANK:
        let minx = left.x + off
        let maxx = right.x - off
        collResults.map(pos => {
          if (pos.x > minx && pos.x < maxx) {
            this.collisionPoints.push(pos)
            let point = this.z3d.markPoint.createMarkPoint('plankMark', pos)
            this.z3d.scene.add(point)
          }
        })
        break
    }
    if (!this.collisionPoints.length) {
      ElMessage({
        message: '没有可打断的位置',
        type: 'warning',
        duration: 1000
      })
      this.state = this.lastState
    }

  }


  toDividePlank(clikPos) {
    let node = this.collisionNode
    let { plankType, size, position, matUrl, matType, cid, parent, matRot } = node
    let { cab3d, cab2d, editor, is3D } = this.z3d
    let wpos = getWorldPos(node)

    let size1 = size.clone()
    let size2 = size.clone()
    let pos1 = position.clone()
    let pos2 = position.clone()
    let arr = [], node3ds = [], node2ds = []
    let mindata = null
    let { left, right, top, bottom } = getPos(wpos, size, null)
    let validPos = this.collisionPoints
    if (!validPos) return
    switch (plankType) {
      case zPlankType.VERTICAL_PLANK:
      case zPlankType.SIDE_L:
      case zPlankType.SIDE_R:

        mindata = this.getMinPos(clikPos, validPos)
        size1.z = Math.abs(top.z - mindata.point.z)
        pos1.z = (top.z + mindata.point.z) * 0.5 - cab3d.position.z
        size2.z = Math.abs(bottom.z - mindata.point.z)
        pos2.z = (bottom.z + mindata.point.z) * 0.5 - cab3d.position.z
        break
      case zPlankType.LEVEL_PLANK:

        mindata = this.getMinPos(clikPos, validPos)
        size1.x = Math.abs(left.x - mindata.point.x)
        pos1.x = (left.x + mindata.point.x) * 0.5 - cab3d.position.x
        size2.x = Math.abs(right.x - mindata.point.x)
        pos2.x = (right.x + mindata.point.x) * 0.5 - cab3d.position.x
        break
    }

    arr.push({ size: size1, pos: pos1 }, { size: size2, pos: pos2 })
    arr.forEach(data => {
      let   plankNode  = createPlank(data.size, data.pos, plankType,'', matUrl)

      plankNode.setMatRot(matRot)

      node3ds.push(plankNode)
    })



    cab3d.add(...node3ds)
    let undoArr = [
      new RemoveObjectCommand(editor, node, parent),
      new RemoveObjectCommand(editor, otherNode, otherParent),
      new AddObjectCommand(editor, node3ds[0], cab3d),
      new AddObjectCommand(editor, node3ds[1], cab3d),

    ]
    editor.execute(new MultiCmdsCommand(editor, undoArr), 'Flatten Geometry');
  }

  getMinPos(clickPos, collisionPoints) {
    let minData = { minDis: -Infinity, point: new Vector3() }
    collisionPoints.map(markPoint => {
      let dis = markPoint.distanceTo(clickPos)
      if (minData.minDis == -Infinity) {
        minData.minDis = dis
        minData.point = markPoint
      }
      if (dis < minData.minDis) {
        minData.minDis = dis
        minData.point = markPoint
      }
    })
    return minData
  }

  update(n) {
    n.bodyMesh.updateMatrix();
    n.bodyMesh.updateMatrixWorld();

    // update OBB

    n.bodyMesh.userData.obb.copy(n.bodyMesh.geometry.userData.obb);
    n.bodyMesh.userData.obb.applyMatrix4(n.bodyMesh.matrixWorld);

  }


  createNullCab(){
    const {z3d} = this
    const defaultSize = new Vector3(1200,600,2400)
    const min = new Vector3(-600,-300,-1200)
    const max = new Vector3(600,300,1200)
    const box = new Box3(min,max);

	const cabBox = new Box3Helper( box,0x000 );
  cabBox.material.depthTest = false;
	// cabBox.material.transparent = true;

  const cabGroup = new CabNode()
  cabGroup.size = defaultSize
  cabGroup.add(cabBox)
  z3d.cab3d = cabGroup
  this.curCab = cabGroup
  cabGroup.position.set(0,0,0)
  
  z3d.scene.add(cabGroup)

  cabGroup.outBox =  box

const { editor } = z3d
  setPlanePos(z3d,cabGroup)
  let arr = [
    new AddObjectCommand(editor, cabGroup, null)
  ]
  editor.execute(new MultiCmdsCommand(editor, arr), 'Flatten Geometry');

  // this.z3d.transControl.attach(cabGroup)

  }

  

}





export default Ctrl3D 